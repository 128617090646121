import Footer from "./components/Footer";
import React from "react";
import Providers from "./components/AccountSettings/providers";
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import "./App.css";
import NotFound from "./components/NotFound";
import NavBar from "./components/NavBar";

const App = () => {
	return (
		<div>
			<Router>
				<Switch>
					<Route exact path="/" component={() => {
						window.location.href = "https://www.pollie.co";
						return null;
					}}/>
					<Route path="/providers">
						<Providers/>
					</Route>
					<Route>
						<NavBar user=" " userLogo={""} unreadMessages={0}/>
						<NotFound homeUrl=""/>
						<Footer/>
					</Route>
				</Switch>
			</Router>
		</div>
	);
};

export default App;
