import React, {useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import { Dropdown } from "react-bootstrap";
import { additionalLabs, INewLab } from "./Constants";

interface ILabNameDropdownProps extends RouteComponentProps {
    updateLabName: any;
    labResult: INewLab;
}

const LabNameDropdown = ({updateLabName, labResult}: ILabNameDropdownProps) => {
    const [searchTerms, setSearchTerms] = useState(additionalLabs);

    const updateSearchTerms = (newValue: string) => {
        const tempSearchTerms = additionalLabs.filter((lab) => {
            return lab.startsWith(newValue);
        });
        setSearchTerms(tempSearchTerms);
    };

    return (
        <Dropdown className="lab-name-dropdown">
            <Dropdown.Toggle className="lab-name-dropdown-button">
                <input value={labResult.name}
                    className="new-lab-name w-100"
                    onKeyUp={() => updateSearchTerms(labResult.name)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateLabName(labResult.id, e.target.value);
                        updateSearchTerms(e.target.value);
                    }}/>
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight={true}>
                { searchTerms.map((value) => {
                    return (
                        <Dropdown.Item key={value} eventKey={value}
                            onClick={() => {
                                updateLabName(labResult.id, value);
                            }}>
                            {value}
                        </Dropdown.Item>
                    );
                })}       
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(LabNameDropdown);
