import { Dispatch, SetStateAction, ChangeEvent } from "react";

export const handleChange = (k: any, v: any, setter: Dispatch<SetStateAction<any>>) => {
    setter((prevState: any) => ({ ...prevState, [k]: v }));
};

export const handleInputChange = (e: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, setter: Dispatch<SetStateAction<any>>, type?: string) => {
    const k = e.target.id;
    const v = e.target.value === "" && type === "number" ? undefined : e.target.value;
    handleChange(k, v, setter);
};

export const handleMultiSelectChange = (e: ChangeEvent<HTMLSelectElement>, setter: Dispatch<SetStateAction<any>>) => {
    const k = e.target.id;
    const v = Array.from(e.target.selectedOptions, option => option.value);
    handleChange(k, v, setter);
};

export const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>, setter: Dispatch<SetStateAction<any>>) => {
    const k = e.target.id;
    const v = Array.from(e.target.selectedOptions, option => option.value);
    handleChange(k, v[0], setter);
};

export const handleCheckChange = (e: any, setter: Dispatch<SetStateAction<any>>, arr: string[]) => {
    const k = e.target.name;
    const v = e.target.id;
    const arrCopy = [...arr];
    if (e.target.checked === false) {
        const index = arr.indexOf(v);
        if (index > -1) {
            arrCopy.splice(index, 1);
        }
    }
    if (e.target.checked === true) {
        const index = arr.indexOf(v);
        if (index === -1) {
            arrCopy.push(v);
        }
    }
    handleChange(k, arrCopy, setter);
};