import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createRef, RefObject, useState, useEffect } from "react";
import "./MessageSender.css";
import _ from "lodash";
import { IMemberInfo } from "../../../types/interfaces";

interface IMessageSenderProps extends RouteComponentProps {
    sendMessage: (message: string) => void;
    chatboxElement: RefObject<HTMLDivElement>;
    updateScroll: any;
    memberSelected: IMemberInfo;
}

const MessageSender = ({ sendMessage, chatboxElement, updateScroll, memberSelected }: IMessageSenderProps) => {
    const [chatMessage, setChatMessage] = useState("");
    const [draftMessages, setDraftMessages] = useState<{[memberId: string]: string}>({});
    const textAreaInput = createRef<HTMLTextAreaElement>();
    const formElement = createRef<HTMLFormElement>();

    const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setChatMessage(e.currentTarget.value);
        setDraftMessages({...draftMessages,
            [memberSelected.id]: e.currentTarget.value});
    };

    const cleanMessageInput = (): void => {
        setChatMessage("");
        const tempDrafts = {...draftMessages};
        tempDrafts[memberSelected.id] = "";
        setDraftMessages(tempDrafts);
    };

    const sendChatMessage = (): void => {
        if (chatMessage !== "") {
            sendMessage(chatMessage);
        }
    };

    const onPressSend = (e: any) => {
        e.preventDefault();
        sendChatMessage();
        cleanMessageInput();
        resizeTextArea("");
        updateScroll();
    };

    useEffect(() => {
        if (!_.isNil(memberSelected)) {
            setChatMessage(draftMessages[memberSelected.id] ? draftMessages[memberSelected.id] : "");
        }
    }, [memberSelected, draftMessages]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (_.isEqual(e.key, "Enter") && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            sendChatMessage();
            cleanMessageInput();
            resizeTextArea("");
            updateScroll();
        } else {
            resizeTextArea(chatMessage);
        }
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        resizeTextArea(chatMessage);
    };

    function resizeTextArea(messageContent: string) {
        const textArea = textAreaInput.current!;
        const maxHeight = 90;
        const maxRows = 5; 

        const taLineHeight = 24; // This should match the line-height in the CSS
        const taHeight = textArea.scrollHeight;
        const rows = Math.floor(taHeight/taLineHeight);

        let newRows = _.min([rows, maxRows]);
        if (_.isEmpty(messageContent)) {
            newRows = 1;
            textArea.style.overflowY = "hidden";
        }

        if (!_.isNil(newRows)) {
            textArea.rows = newRows;
            chatboxElement.current!.style.height = `${maxHeight - ((newRows - 1) * 4)}%`;
            formElement.current!.style.height = `${40 + (newRows - 1) * 24}px`;

            if (newRows >= maxRows) {
                textArea.style.overflowY = "auto";
            }
        }
    }

    return (
        <form className="input-container mt-2" ref={formElement}>
            <textarea id="send-message-input" className="input-box pl-2" 
                placeholder={"Send a new message..."} value={chatMessage} rows={1.5}
                onChange={handleOnChange} onKeyUp={onKeyUp} onKeyDown={onKeyDown} ref={textAreaInput}/>
            { !_.isEmpty(chatMessage) &&
                <button className="send-message-btn" onClick={onPressSend}>
                    Send
                </button>
            }
        </form>
    );
};

export default withRouter(MessageSender);