import React, {Fragment, useState} from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import {useParams} from "react-router-dom";

import {IAppointment, IMemberInfo, IProvider} from "../../../types/interfaces";
import AppointmentSummary from "../Appointments/AppointmentSummary";
import MemberInitialsBubble from "./MemberInitialsBubble";
import MemberSubscriptionLabel from "./MemberSubscriptionLabel";
import _ from "lodash";
import {Conditions, ethnicityMap, goalsMap, lifeStageMap} from "../../utils/constants";
import {dobFormat} from "../Availability/constants";
import dayjs from "dayjs";
import {calculateMemberWeek, displayMembersName, displayMembersInitials} from "./Utils";

interface IMemberProfileSummaryProps extends RouteComponentProps {
	provider: IProvider,
	member: IMemberInfo,
	appointments: IAppointment[]
}

const MemberProfileSummary = ({provider, member, appointments}: IMemberProfileSummaryProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const {memberId} = useParams<{ memberId: string }>();

	return (
		<Fragment>
			<div className="row">
				<MemberInitialsBubble
					cName={"col-md-2"}
					initials={displayMembersInitials(member)}
				/>
				<h1 className="col-md-10 member-name-header pl-0">
					{displayMembersName(member)}
				</h1>
			</div>
			<MemberSubscriptionLabel className="col-md-3 mb-2" member={member}/>
			{member.end_time &&
        <span className={"text-muted"} style={{fontSize: "1.1rem"}}>
	        {member.will_renew ? "Renews " : "Expires "}
					{dayjs(member.end_time).format("M/D/YYYY")}
				</span>
			}

			<div className="row mt-2">
				<div className="col">
					<div className="member-profile-field">
						Pronouns
					</div>
					<div className="member-profile-value">
						{!_.isNil(member.pronouns) ? member.pronouns : ""}
					</div>
					<div className="member-profile-field">
						DOB
					</div>
					<div className="member-profile-value">
						{(!_.isNil(member.dob) && !_.isEmpty(member.dob)) ? dayjs(member.dob).format(dobFormat) : ""}
					</div>
				</div>
				<div className="col">
					<div className="member-profile-field">
						Age
					</div>
					<div className="member-profile-value">
						{(member.dob) ? dayjs(dayjs()).diff(member.dob, "year") : ""}
					</div>
					<div className="member-profile-field">
						Location
					</div>
					<div className="member-profile-value">
						{(!_.isNil(member.city) && !_.isNil(member.state) &&
							!_.isEmpty(member.city) && !_.isEmpty(member.state)) ? `${member.city}, ${member.state}` : ""}
					</div>
				</div>
				<div className="col">
					<div className="member-profile-field">
						Lifestage
					</div>
					<div className="member-profile-value">
						{!_.isNil(member.life_stage) ? lifeStageMap[member.life_stage] : ""}
					</div>
					<div className="member-profile-field">
						Ethnicity
					</div>
					<div className="member-profile-value">
						{/* Is ethnicity "other" in which case show the string for it */}
						{member.ethnicity ? member.ethnicity == 6 ? member.ethnicity_other : ethnicityMap[member.ethnicity] : ""}
					</div>
				</div>
			</div>

			<div className="member-profile-field">
				Goals
			</div>
			<div className="member-profile-value">
				{(member.goals && member.goals.length > 0) ?
					member.goals.map((goalKey) => {
						return (
							<div key={goalKey}>
								{goalsMap[goalKey]}
							</div>
						);
					}) : ""}
				{(!_.isNil(member.goals_other) && !_.isEmpty(member.goals_other)) &&
					member.goals_other
				}
			</div>

			<div className="member-profile-field">
				Conditions
			</div>
			<div className="member-profile-value">
				{(member.conditions && member.conditions?.length > 0) ?
					member.conditions.map((conditionId) => {
						if (conditionId == "6") {
							return "";
						} else return (
							<div key={conditionId}>
								{Conditions[parseInt(conditionId)]}
							</div>
						);}
					) : ""}
				{member.conditions_other &&
					member.conditions_other
				}
			</div>

			<h4 className="mt-2 bold">
				Upcoming Visits
			</h4>
			<div className="col-11">
				{appointments.map((appointment) => {
					return (
						<AppointmentSummary key={appointment.id} appointment={appointment}
						                    provider={provider} member={member}/>
					);
				})}
			</div>
			<div className="mt-4">
				<Link className="pollie-link" to={process.env.PUBLIC_URL + "/providers/account/calendar"}>
					View my calendar
				</Link>
			</div>
		</Fragment>
	);
};

export default withRouter(MemberProfileSummary);
