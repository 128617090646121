import React, { Fragment } from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import _ from "lodash";
import deleteIcon from "../../../img/delete-icon.svg";

interface IDisplayTagsProps extends RouteComponentProps {
    tags: number[] | undefined;
    tagMap: Record<number, string>;
    isEdit?: boolean;
    onDeleteClick?: any;
}

const DisplayTags = ({tags, tagMap, isEdit=false, onDeleteClick}: IDisplayTagsProps) => {

    return (
        <div className="row tags-container">
            { !_.isNil(tags) &&
                tags.map((tag) => {
                    return (
                        <Fragment key={tag}>
                            <div className="tag-container">
                                {tagMap[tag]}
                            </div>
                            { isEdit &&
                                <img alt="delete" src={deleteIcon} className="delete-tag-icon" onClick={() => onDeleteClick(tag)}/>
                            }
                        </Fragment>
                    );
                })
            }
        </div>
    );
};

export default withRouter(DisplayTags);
