import React, { Dispatch, SetStateAction, useState } from "react";
import Nav from "./sub-components/Nav";
import MobileNav from "./sub-components/MobileNav";
import PersonalInfo from "./sub-components/PersonalInfo";
import EmailPassword from "./sub-components/EmailPassword";
import Availability from "../Availability/Availability";
import {
    Switch,
    Route,
    useRouteMatch,
    withRouter,
    Redirect,
    RouteComponentProps
} from "react-router-dom";
import NotFound from "../NotFound";
import _ from "lodash";
import { subNavMap } from "../../utils/maps";
import Calendar from "./sub-components/Calendar";

interface IProviderAccountProps extends RouteComponentProps {
    providerId: string;
    setUserLogo: Dispatch<SetStateAction<string>>;
}

const ProvidersAccount = ({ providerId, setUserLogo }: IProviderAccountProps) => {
    const { path } = useRouteMatch();
    const [currentNav, setCurrentNav] = useState("");
    const [mobileNavActive, setMobileNavActive] = useState(true);
    const [currentSubNav, setCurrentSubNav] = useState("");
    const [currentNavTitle, setCurrentNavTitle] = useState("");

    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-md-center">
                    <div className="col-md-10">
                        { (_.isEmpty(currentSubNav) && mobileNavActive) &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="w-100 mt-md-5">
                                    <h1 className="provider-header-text">
                                        My Profile
                                    </h1>
                                </div>
                                <div className="mt-4 mb-4">
                                    Manage your profile information, set your availability, and view your calendar from the menu below.
                                </div>
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-4 d-none d-md-block profile-spacing">
                                <Nav currentNav={currentNav} 
                                />
                            </div>
                            <div className="col d-md-none">
                                { (!_.isEmpty(currentSubNav) || !mobileNavActive) &&
                                <span className="row pl-3"
                                    onClick={() => {
                                        if (!mobileNavActive) {
                                            setMobileNavActive(true);
                                        } else if (_.isEqual(currentSubNav, subNavMap.appointments) ||
                                            _.isEqual(currentSubNav, subNavMap.practiceInfo)) {
                                            setCurrentSubNav("");
                                        } 
                                    }}>
                                    &lt;&nbsp;&nbsp;&nbsp;<u>Go back</u>
                                </span>
                                
                                }
                                { !mobileNavActive &&
                                <p className={"row profile-nav-link bold mt-5 pl-3"}>
                                    {currentNavTitle}
                                </p>
                                }
                                { mobileNavActive &&
                                <MobileNav 
                                    currentSubNav={currentSubNav}
                                    setCurrentSubNav={setCurrentSubNav}
                                    setMobileNavActive={setMobileNavActive}
                                    setCurrentNavTitle={setCurrentNavTitle}/>
                                }
                            </div>
                            <div className={_.isEqual(mobileNavActive, true) ? "d-none d-md-block col-md-8 profile-spacing" : "d-block col-md-8 profile-spacing"}>
                                <Switch>
                                    <Route exact path={`${path}`}>
                                        <Redirect to={`${path}/personalInfo`} />
                                    </Route>
                                    <Route path={`${path}/personalInfo`}>
                                        <PersonalInfo setCurrentNav={setCurrentNav} providerId={providerId} setUserLogo={setUserLogo} />
                                    </Route>
                                    <Route path={`${path}/availability`}>
                                        <Availability setCurrentNav={setCurrentNav} providerId={providerId} />
                                    </Route>
                                    <Route path={`${path}/calendar`}>
                                        <Calendar setCurrentNav={setCurrentNav} providerId={providerId} />
                                    </Route>
                                    <Route path={`${path}/email`}>
                                        <EmailPassword setCurrentNav={setCurrentNav} providerId={providerId} />
                                    </Route>
                                    <Route>
                                        <NotFound homeUrl="/providers"/>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ProvidersAccount);