const colors = {
	primary:      "#75C8CC",
	secondary:    "#2EB7BD",
	opacity:      "#E3F4F5",
	greyOne:      "#F7FAFB",
	greyTwo:      "#EEF5F6",
	greyThree:    "#E7EEEF",
	greyFour:     "#E0E8E9",
	greyFive:     "#D6DFE0",
	greySix:      "#B0C1C3",
	greySeven:    "#545A77",
	greyNine:     "#131635",
	white:        "#FFFFFF",
	black:        "#090909",
	error:        "#FF647C",
	warning:      "#FFA26B",
	alert:        "#F1B422",
	opacityAlert: "#FFF8E7",
	info:         "#5487F5"
};

export default colors;