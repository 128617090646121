import dayjs from "dayjs";
import { IMessage } from "./Message";

// MessageStore
// JS object to hold messages for a provider (internally in the form of a mapping based on the associated memberID)
// and expose convenience methods for frequently used calculated properties.

export class MessageStore {
	// Messages for a given provider stored by the ID of the member that sent / received them
	private _messages: Map<string, IMessage[]>
	private _providerId: string
	
	constructor(messages: IMessage[], providerId: string) {
		this._providerId = providerId;
		this._getMemberId = (msg: IMessage) => (msg.senderId === providerId) ? msg.receiverId : msg.senderId;

		this._messages = messages.reduce((tempMessages, msg) => {
			const memberId = this._getMemberId(msg);
			if (!tempMessages.has(memberId)) {
				tempMessages.set(memberId, [msg]);
			} else {
				tempMessages.get(memberId)?.push(msg);
			}
			return tempMessages;
		}, new Map<string, IMessage[]>());

		this._messages.forEach((msg) => {
			msg.sort((msgA, msgB) => dayjs(msgB.createdAt).diff(dayjs(msgA.createdAt)));
		});
	}

	private _getMemberId = (msg: IMessage) => (msg.senderId === this._providerId) ? msg.receiverId : msg.senderId;

	latestWithMember(memberId: string): IMessage | null {
		const messages = this._messages.get(memberId);
		return (messages !== undefined) ? messages[0] : null;
	}

	messagesWithMember(memberId: string): IMessage[] {
		return (this._messages.get(memberId) ?? []);
	}

	messagesFromMember(memberId: string): IMessage[] {
		return this.messagesWithMember(memberId).filter(m => m.receiverId === this._providerId);
	}

	// Returns number of unread messages from a given memberID. If no memberId specified, returns
	// the total number of unread messages across all members.
	unreadMessages(memberId = ""): number {
		if (memberId !== "") {
			const reducer = (prevValue: number, message: IMessage) => {
				return prevValue + (message.readAt ? 0 : 1);
			};
			return this.messagesFromMember(memberId).reduce(reducer, 0);
		} else {
			const memberIds = [...this._messages.keys()];
			const reducer = (prevValue: number, memberId: string) => prevValue + this.unreadMessages(memberId);
			return memberIds.reduce(reducer, 0);
		}
	}
}