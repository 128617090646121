import React, { Fragment, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./Canvas.css";
import videoOffIcon from "../../img/video-disabled.svg";
import videoOnIcon from "../../img/video-enabled.svg";

interface VideoControlProps extends RouteComponentProps {
    mute: any;
}

const VideoControlButton = ({ mute }: VideoControlProps) => {
    const [muted, setMuted] = useState(true);

    return (
        <div className="col">
            { !muted &&
                <Fragment>
                    <div onClick={() => {
                        mute("video");
                        setMuted(!muted);
                    }}
                    className="ag-btn"
                    title="Disable Video">
                        <img src={videoOnIcon}/>
                    </div>
                    <div className="control-button-text">
                        Stop video
                    </div>
                </Fragment>
            }
            { muted &&
                <Fragment>
                    <div onClick={() => {
                        mute("video");
                        setMuted(!muted);
                    }}
                    className="ag-btn"
                    title="Enable Video"
                    >
                        <img src={videoOffIcon}/>
                    </div>
                    <div className="control-button-text">
                        Start video
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default withRouter(VideoControlButton);
