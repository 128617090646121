import React, {useState, useEffect} from "react";
import {
	Link,
	RouteComponentProps,
	withRouter
} from "react-router-dom";
import {http} from "../../utils/axios-config";
import Loading from "../Loading";
import {IAppointment, IMemberInfo, IProvider, ISchedule} from "../../../types/interfaces";
import "../../index.css";
import "./style.css";
import dayjs from "dayjs";
import _ from "lodash";
import UpdateAvailabilityModal from "../AvailabilityModal/Modal";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MemberSubscriptionLabel from "../Members/MemberSubscriptionLabel";
import MemberInitialsBubble from "../Members/MemberInitialsBubble";
import AppointmentSummary from "../Appointments/AppointmentSummary";
import {defaultProvider} from "../../utils/constants";
import {calculateMemberWeek, displayMembersInitials, displayMembersName} from "../Members/Utils";

dayjs.extend(timezone);
dayjs.extend(utc);

interface IDashboardProps extends RouteComponentProps {
	providerId: string,
	members: IMemberInfo[]
}

const Main = ({providerId, members}: IDashboardProps) => {
	const [provider, setProvider] = useState<IProvider>(defaultProvider);
	const [isLoading, setIsLoading] = useState(true);
	const [availabilityModalShow, setAvailabilityModalShow] = useState(false);
	const [appointments, setAppointments] = useState<IAppointment[]>([]);

	useEffect(() => {
		const getData = async (): Promise<void> => {
			setIsLoading(true);

			try {
				const providerInfo: IProvider = await http("get", `/providers/${providerId}/personal-info`, "");
				setProvider({...providerInfo});

				// If the provider has not added their availability,
				// we display a modal asking if they would like to set it up
				const updateAvailExpires = localStorage.getItem("update_availability_expires") || "";
				if (_.isEmpty(updateAvailExpires) || !dayjs(updateAvailExpires).isSame(dayjs.utc().local(), "day")) {
					const schedules: ISchedule[] = await http("get", `/providers/${providerId}/schedules`, "");
					if (_.isEmpty(schedules)) {
						setAvailabilityModalShow(true);
						// Only show it once per login
						localStorage.setItem("update_availability_expires", dayjs.utc().local().toString());
					}
				}

				// Get the provider's upcoming appointments
				const appointments: IAppointment[] = await http("get", `/providers/${providerId}/appointments`, "");
				setAppointments(appointments);
			} catch (err: any) {
				console.log(err);
			}

			setIsLoading(false);
		};
		void getData();
	}, []);

	if (isLoading) {
		<Loading style={{height: 300}}/>;
	}

	return (
		<div className="container mb-5">
			<div className="row justify-content-md-center">
				<div className="col-md-10">
					<div className="row">
						<div className="col">
							<div className="w-100 member-header">
								<h1 className="member-header-text">Hi, {provider.first_name}!</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row justify-content-md-end">
				<div className="col-md-6 dashboard-module pt-2 pr-4 pl-4 pb-3">
					<div className="row mb-4">
						<h1>
							My Members
						</h1>
					</div>
					{members.map((member) => {
						const memberInitials = displayMembersInitials(member);
						let memberName = displayMembersName(member);
						if (memberName.length > 20) {
							memberName = `${memberName.slice(0, 20)}...`;
						}

						return (
							<div className="row dashboard-module-row p-2 mb-2" key={member.id}>
								<MemberInitialsBubble cName={"p-0"} initials={memberInitials}/>
								<div className="col-md-5 pl-1 pr-0">
									{memberName}
								</div>
								<MemberSubscriptionLabel className="col-md-2" member={member}/>
								<div className="col-md-1"/>
								<Link className="col-md-3 dashboard-btn-dark p-1"
								      to={process.env.PUBLIC_URL + `/providers/members/${member.id}`}>
									View Profile
								</Link>
							</div>
						);
					}).slice(0, 10)}
					<Link className="row pollie-link mt-4" to={process.env.PUBLIC_URL + "/providers/members/"}>
						View all members
					</Link>
				</div>
				<div className="col-md-5 dashboard-module ml-2 pt-2 pl-4 pr-4">
					<div className="row">
						<h1>
							{"Today's visits"}
						</h1>
					</div>
					{appointments.map((appointment: IAppointment) => {
						const currentTime = dayjs().tz(provider.time_zone);
						const startTime = dayjs(appointment.start_time).tz(provider.time_zone);
						const endTime = dayjs(appointment.end_time).tz(provider.time_zone);
						if (!startTime.isSame(currentTime, "day") || endTime.isBefore(currentTime, "minutes") || appointment.canceled) {
							return;
						}
						const member = members.find((member) => {
							return _.isEqual(member.id, appointment.member_id);
						});
						if (_.isNil(member)) {
							return;
						}
						return (
							<AppointmentSummary key={appointment.id} appointment={appointment} provider={provider} member={member}/>
						);
					})}
				</div>
			</div>
			<UpdateAvailabilityModal show={availabilityModalShow}
			                         setAvailabilityModalShow={setAvailabilityModalShow}/>
		</div>
	);
};

export default withRouter(Main);