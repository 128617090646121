import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import dayjs, { Dayjs } from "dayjs";
import { dateFormat, timeFormat, modalTimeFormat, handleSpanChange, DEFAULT_END_TIME, DEFAULT_START_TIME } from "./constants";
import _ from "lodash";
import { FaTrash } from "react-icons/fa";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import { createScheduleException, updateScheduleException } from "./ScheduleExceptions";
import { updateSchedule, deleteSchedule, createSchedule } from "./Schedule";
import { convertSchedulesToEvents } from "./Calendar";
import TimeSelector from "../Utils/TimeSelector";
import { ISchedule } from "../../../types/interfaces";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

function AvailabilityModal(props: any) {
    const { show, setAvailabilityModalShow, setIsLoading, newSchedule, newEvent, setNewEvent,
        schedules, setSchedules, scheduleExceptions, setScheduleExceptions, setCalendarEvents, providerId,
        setAddScheduleModalEvent, setAddScheduleModalShow } = props;

    const [startValidationError, setStartValidationError] = useState("");
    const [endValidationError, setEndValidationError] = useState("");
    const [overlapValidationError, setOverlapValidationError] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);

    const onHide = () => {
        setAvailabilityModalShow(false);
        setStartValidationError("");
        setEndValidationError("");
        setOverlapValidationError("");
        setIsDeleting(false);
    };

    // true = schedule, false = schedule exception
    const isSchedule = (schedule: any): boolean => {
        return (_.isNil(schedule.is_canceled));
    };

    // Event to schedule conversion
    const updateScheduleWithEvent = async (newSchedule: any) => {
        if (isSchedule(newSchedule)) {
            if (newSchedule.is_recurring) {
                // Adding a new Schedule Exception w/ rescheduled
                const newScheduleException = await createScheduleException(newSchedule);
                setScheduleExceptions([...scheduleExceptions, newScheduleException]);
                setCalendarEvents(convertSchedulesToEvents(schedules, [...scheduleExceptions, newScheduleException]));
            } else {
                await updateSchedule(newSchedule);
                const newArr = schedules.filter((event: any) => {
                    return event.id !== newSchedule.id;
                });
                setSchedules([...newArr, newSchedule]);
                setCalendarEvents(convertSchedulesToEvents([...newArr, newSchedule], scheduleExceptions));
            }

        } else {
            const newScheduleException = await updateScheduleException(newSchedule);
            const newArr = scheduleExceptions.filter((event: any) => {
                return event.id !== newScheduleException.id;
            });
            setScheduleExceptions([...newArr, newScheduleException]);
            setCalendarEvents(convertSchedulesToEvents(schedules, [...newArr, newScheduleException]));
        }
    };

    // Single schedule instances and schedule exceptions get converted to recurring
    const updateRecurringScheduleWithEvent = async (newSchedule: any) => {
        if (newSchedule.is_recurring) {
            await updateSchedule(newSchedule);
            const newArr = schedules.filter((event: any) => {
                return event.id !== newSchedule.id;
            });
            setSchedules([...newArr, newSchedule]);
            setCalendarEvents(convertSchedulesToEvents([...newArr, newSchedule], scheduleExceptions));
        } else {

            if (isSchedule(newSchedule)) {
                const startDateTime: Dayjs = dayjs.utc(newSchedule.start_date);
                const updatedSchedule = {
                    ...newSchedule,
                    is_recurring: true,
                    day_of_week: parseInt(startDateTime.format("d")),
                    start_date: undefined,
                    end_date: undefined
                };
                await updateSchedule(updatedSchedule);
                const newArr = schedules.filter((event: any) => {
                    return event.id !== updatedSchedule.id;
                });
                setSchedules([...newArr, updatedSchedule]);
                setCalendarEvents(convertSchedulesToEvents([...newArr, updatedSchedule], scheduleExceptions));
            } else {
                // Convert schedule exception to recurring schedule
                // And remove the old schedule exception
                const startDateTime: Dayjs = dayjs.utc(newSchedule.start_date);
                const newRecurringSchedule = {
                    ...newSchedule,
                    is_recurring: true,
                    day_of_week: parseInt(startDateTime.format("d")),
                    start_date: undefined,
                    end_date: undefined
                };
                // Create new recurring schedule and delete schedule exception
                // await updateSchedule(newRecurringSchedule);
                await createSchedule(newRecurringSchedule);
                void updateScheduleException(newSchedule, true);
                const newScheduleExceptions = scheduleExceptions.filter((event: any) => {
                    return event.id !== newRecurringSchedule.id;
                });
                setSchedules([...schedules, newRecurringSchedule]);
                setScheduleExceptions([...newScheduleExceptions]);
                setCalendarEvents(convertSchedulesToEvents([...schedules, newRecurringSchedule], [...newScheduleExceptions]));
            }
        }
    };

    // Event to schedule conversion
    const deleteScheduleWithEvent = async (newSchedule: any) => {
        if (isSchedule(newSchedule)) {
            if (newSchedule.is_recurring) {
                // Adding a new Schedule Exception w/ canceled
                const newScheduleException = await createScheduleException(newSchedule, true);
                setScheduleExceptions([...scheduleExceptions, newScheduleException]);
                setCalendarEvents(convertSchedulesToEvents(schedules, [...scheduleExceptions, newScheduleException]));
            } else {
                // Delete non-recurring schedule
                await deleteSchedule(newSchedule);
                const newArr = schedules.filter((event: any) => {
                    return event.id !== newSchedule.id;
                });
                setSchedules(newArr);
                setCalendarEvents(convertSchedulesToEvents(newArr, scheduleExceptions));
            }
            // deleteSchedule(newSchedule);
        } else {
            // Updating the existing schedule exception with canceled
            const newScheduleException = await updateScheduleException(newSchedule, true);
            const newArr = scheduleExceptions.filter((event: any) => {
                return event.id !== newScheduleException.id;
            });
            setScheduleExceptions([...newArr, newScheduleException]);
            setCalendarEvents(convertSchedulesToEvents(schedules, [...newArr, newScheduleException]));
        }
    };

    const deleteRecurringScheduleWithEvent = async (newSchedule: ISchedule) => {
        await deleteSchedule(newSchedule);
        const newArr = schedules.filter((event: any) => {
            return event.id !== newSchedule.id;
        });
        setSchedules(newArr);
        setCalendarEvents(convertSchedulesToEvents(newArr, scheduleExceptions));
    };

    const newIntervalClick = (currentSchedule: any) => {
        onHide();
        const dateSelected = dayjs.utc(currentSchedule.start_date);
        const newSchedule = {
            providerId: providerId,
            startDateLocal: dateSelected,
            endDateLocal: dateSelected,
            start: DEFAULT_START_TIME,
            end: DEFAULT_END_TIME
        };
        setAddScheduleModalEvent(newSchedule);
        setAddScheduleModalShow(true);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            className="edit-availability-modal-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <h2>Edit Availability</h2>
                <div className="row mb-4">
                    <div className="col">
                        <label>From</label>
                        <TimeSelector className="col form-control pollie-input placeholder-opacity"
                            spanKey={"start"}
                            defaultValue={newEvent.start}
                            disabled={isDeleting}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSpanChange(e, "start",
                                newEvent,
                                setNewEvent,
                                setStartValidationError,
                                setEndValidationError,
                                setOverlapValidationError);
                            }}/>
                    </div>
                    <div className="col">
                        <label>
                            To
                        </label>
                        <TimeSelector className="col form-control pollie-input placeholder-opacity"
                            spanKey={"end"}
                            defaultValue={newEvent.end}
                            disabled={isDeleting}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSpanChange(e, "end",
                                newEvent,
                                setNewEvent,
                                setStartValidationError,
                                setEndValidationError,
                                setOverlapValidationError);
                            }}/>
                    </div>
                    <div className="col" style={{ marginTop: "50px" }}>
                        <div className={isDeleting ? "" : "pollie-link cursor-pointer"}
                            onClick={(e: React.MouseEvent) => { 
                                e.preventDefault();
                                setIsDeleting(true);
                            }}>
                            <FaTrash className="mr-2"/>Delete
                        </div>
                    </div> 
                </div>
                <div className="pollie-link cursor-pointer" onClick={newIntervalClick}>
                    +New Interval
                </div>
                { startValidationError !== "" && 
                        <p className="form-error">{startValidationError}</p>
                }
                { endValidationError !== "" && 
                        <p className="form-error">{endValidationError}</p>
                }
                { overlapValidationError !== "" &&
                        <p className="form-error">{overlapValidationError}</p>
                }
                { !(!isDeleting && newSchedule.is_recurring) &&
                    <Button 
                        disabled={!_.isEqual(startValidationError, "") || 
                                    !_.isEqual(endValidationError, "") ||
                                    !_.isEqual(overlapValidationError, "")}
                        onClick={async () => {
                            onHide();
                            setIsLoading(true);

                            if (isDeleting) {
                                await deleteScheduleWithEvent(newSchedule);
                            } else {
                                // TODO: Probably a cleaner way to find what the selected day is
                                const dateSelected = dayjs.utc(newSchedule.start_date, dateFormat).add(dayjs.utc(newSchedule.start_time, timeFormat).hour(), "hour").local().format(dateFormat);
                                const startDateTime = dayjs(dateSelected, dateFormat).add(dayjs(newEvent.start, modalTimeFormat).hour(), "hour").utc();
                                const endDateTime = dayjs(dateSelected, dateFormat).add(dayjs(newEvent.end, modalTimeFormat).hour(), "hour").utc();
                                const start_time = dayjs(newEvent.start, modalTimeFormat).utc().format(timeFormat);
                                const end_time = dayjs(newEvent.end, modalTimeFormat).utc().format(timeFormat);

                                await updateScheduleWithEvent({...newSchedule, 
                                    start_time: start_time, 
                                    end_time: end_time,
                                    start_date: startDateTime.format(dateFormat),
                                    end_date: endDateTime.format(dateFormat)});
                            }
                            setIsLoading(false);
                        }} className="form-submit-button btn-pollie-dark">
                        { !isDeleting &&
                            `Apply to ${dayjs.utc(newSchedule.start_date, dateFormat).add(dayjs.utc(newSchedule.start_time, timeFormat).hour(), "hour").local().format("MMMM D, YYYY")}`
                        }
                        { isDeleting &&
                            `Delete for ${dayjs.utc(newSchedule.start_date, dateFormat).add(dayjs.utc(newSchedule.start_time, timeFormat).hour(), "hour").local().format("MMMM D, YYYY")}`
                        }
                    </Button>
                }
                { (newSchedule.is_recurring || !isDeleting) &&
                    <Button 
                        disabled={!_.isEqual(startValidationError, "") || 
                                    !_.isEqual(endValidationError, "") ||
                                    !_.isEqual(overlapValidationError, "")}
                        onClick={async () => {
                            onHide();
                            setIsLoading(true);
                            if (isDeleting) {
                                await deleteRecurringScheduleWithEvent(newSchedule);
                            } else {
                                // TODO: Probably a cleaner way to find what the selected day is
                                const dateSelected = dayjs.utc(newSchedule.start_date, dateFormat).add(dayjs.utc(newSchedule.start_time, timeFormat).hour(), "hour").local().format(dateFormat);
                                const startDateTime = dayjs(dateSelected, dateFormat).add(dayjs(newEvent.start, modalTimeFormat).hour(), "hour").utc();
                                const endDateTime = dayjs(dateSelected, dateFormat).add(dayjs(newEvent.end, modalTimeFormat).hour(), "hour").utc();
                                const start_time = dayjs(newEvent.start, modalTimeFormat).utc().format(timeFormat);
                                const end_time = dayjs(newEvent.end, modalTimeFormat).utc().format(timeFormat);

                                await updateRecurringScheduleWithEvent({
                                    ...newSchedule, 
                                    start_time: start_time, 
                                    end_time: end_time,
                                    start_date: startDateTime.format(dateFormat),
                                    end_date: endDateTime.format(dateFormat)});
                            }
                            setIsLoading(false);
                        }} className="form-submit-button btn-pollie-dark">
                        { !isDeleting &&
                            `Apply to ${dayjs.utc(newSchedule.start_date, dateFormat).add(dayjs.utc(newSchedule.start_time, timeFormat).hour(), "hour").local().format("dddd")}s`
                        }
                        { isDeleting &&
                            `Delete for ${dayjs.utc(newSchedule.start_date, dateFormat).add(dayjs.utc(newSchedule.start_time, timeFormat).hour(), "hour").local().format("dddd")}s`
                        }
                    </Button>
                }
                <p className="center-text auth-link-spacing pollie-link" 
                    onClick={() => {
                        onHide();
                        setStartValidationError("");
                        setEndValidationError("");
                        setOverlapValidationError("");
                    }}>
                       Cancel
                </p>
            </Modal.Body>
        </Modal>
    );
}
// make event actually delete and make isLoading trigger
export default AvailabilityModal;