import AgoraRTM, { RtmClient } from "agora-rtm-sdk";
import EventEmitter from "events";
import { http } from "./axios-config";

enum ConnectionState {
	DISCONNECTED = "DISCONNECTED",
	CONNECTING = "CONNECTING",
	CONNECTED = "CONNECTED",
	RECONNECTING = "RECONNECTING",
	ABORTED = "ABORTED"
}

type PeerMessageSendResult = any;

interface IProviderAgoraInfo {
	token: string;
	uid: string;
}

export default class RTMClient extends EventEmitter {
		private _client: RtmClient;
		private _providerId: string; 
		private _connectionState: string;

		constructor (providerId: string) {
			super();
			this._providerId = providerId;
			this._connectionState = ConnectionState.DISCONNECTED;
			this._client = AgoraRTM.createInstance(process.env.REACT_APP_AGORA_APP_ID as string);
			this.subscribeClientEvents();

			void this.login();
		}

		async login(): Promise<void> {
			const providerAgoraInfo: IProviderAgoraInfo = await http("get", `/providers/${this._providerId}/rtm-token`, "");
			if(this._connectionState !== ConnectionState.DISCONNECTED) {
				await this._client.logout();
				this._connectionState = ConnectionState.DISCONNECTED;
			};
			return this._client.login(providerAgoraInfo);
		}

		// subscribe client events
		subscribeClientEvents = (): void => {
			const clientEvents = [
				"ConnectionStateChanged",
				"MessageFromPeer"
			];
			clientEvents.forEach((eventName) => {
				this._client.on(eventName as any, (...args) => {
					console.log("emit ", eventName, ...args);
					// log event message
					this.emit(eventName, ...args);
				});
			});

			this._client.on("ConnectionStateChanged", (newState: string) => {
				this._connectionState = newState;
			});
		}

		logout = async (): Promise<void> => {
			return await this._client.logout();
		}

		sendPeerMessage = async (text: string, peerId: string): Promise<PeerMessageSendResult> => {
			console.log("sendPeerMessage", text, peerId);
			if (this._connectionState === ConnectionState.ABORTED || this._connectionState === ConnectionState.DISCONNECTED) {
				await this.login();
			}
			const result = await this._client.sendMessageToPeer({ text }, peerId.toString());
			return result;
		}

		//send image
		uploadImage = async (blob: Blob, peerId: string): Promise<PeerMessageSendResult> => {
			const mediaMessage = await this._client.createMediaMessageByUploading(blob, {
				messageType: "IMAGE",
				fileName: "agora.jpg",
				description: "send image",
				thumbnail: blob, 
				// width: 100,
				// height: 200,
				// thumbnailWidth: 50,
				// thumbnailHeight: 200, 
			}); 
			return this._client.sendMessageToPeer(mediaMessage, peerId);
		}

		cancelImage = async (mediaId: string): Promise<void> => {
			const controller = new AbortController();
			setTimeout(() => controller.abort(), 1000);
			await this._client.downloadMedia(mediaId, {
				cancelSignal: controller.signal,
				onOperationProgress: ({currentSize, totalSize}) => {
					console.log(currentSize, totalSize);
				},
			});
		}

}