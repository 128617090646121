import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import colors from "../../utils/colors";
import {IMemberInfo} from "../../../types/interfaces";

interface IMemberSubscriptionLabelProps extends RouteComponentProps {
	member: IMemberInfo,
	className: string
}

const MemberSubscriptionLabel = ({member, className}: IMemberSubscriptionLabelProps) => {

	const badgeColors: { [id: string]: string } = {
		"Starter": colors.alert,
		"Flex": colors.info,
		"Program": colors.secondary
	};

	return (
		<>
			<div className={`${className} p-0 dashboard-btn-light subscription-label`}
			     style={{backgroundColor: badgeColors[member.subscription_type_label]}}>
				{`${member.subscription_type_label}`}
			</div>
		</>

	);
};

export default withRouter(MemberSubscriptionLabel);
