import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import {
    RouteComponentProps,
    useRouteMatch,
    withRouter
} from "react-router-dom";
import Loading from "../../Loading";
import { http } from "../../../utils/axios-config";
import _ from "lodash";
import { providerAuth0Client } from "../../Auth0/Auth";

interface IEmailPasswordProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>;
    providerId: string;
}

const EmailPassword = ({ setCurrentNav, providerId }: IEmailPasswordProps) => {
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const { url } = useRouteMatch();

    useEffect(() => {
        setCurrentNav(url);
        const getData = async () => {
            setIsLoading(true);
            if (_.isNil(providerId)) {
                providerId = (await http("get", "/providers", "")).id;
            }
            const userInfo = await http("get", `/providers/${providerId}/personal-info`, "");
            setData(userInfo);
            setIsLoading(false);
        };
        void getData();
    }, []);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            setIsPasswordReset(true);
            await providerAuth0Client.resetPassword(data.email);
        } catch(err: any) {
            setError(err.description);    
        }
    };

    if (isLoading) {
        return (
          <Loading style={{height: 300}}/>
        );
    }

    return (
        <div className="col-6">
            <div className="profile-section">
                <div>
                    <div className="profile-section-header">
                        Email
                    </div>
                    <div>
                        {!data.email ? <div className="profile-incomplete">Incomplete</div> : data.email}
                    </div>
                </div>
            </div>
            <div className="profile-section">
                <div>
                    <div className="profile-section-header">
                        Password
                    </div>
                    <form className="" onSubmit={handleSubmit}>
                        <button className={isPasswordReset ? "form-submit-button btn-pollie-outline mt-0" : "form-submit-button btn-pollie-dark mt-0"} type="submit" disabled={isPasswordReset}>
                            Send password reset email
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withRouter(EmailPassword);