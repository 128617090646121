import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import "./style.css";

interface IDocumentsProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>
}

const Documents = ({ setCurrentNav }: IDocumentsProps) => {

    useEffect(() => {
        setCurrentNav("documents");
    }, []);
    
    return (
        <div className="col-md-6">
            <div className="container col">
            </div>
        </div>
    );
};

export default withRouter(Documents);
