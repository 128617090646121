import _ from "lodash";
import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import { providerAuth0Client } from "../../Auth0/Auth";

const ProfileNav = (props: any) => {
    const { currentNav } = props;

    return (
        <div className="profile-links">
            <div>
                <Link to={process.env.PUBLIC_URL + "/providers/account/personalInfo"} 
                    className={_.isEqual(currentNav, "/providers/account/personalInfo") ? "profile-nav-link bold" : "profile-nav-link"} >
                    Personal info
                </Link>
                <hr />
            </div>
            {/* <div>
                <span>Appointments</span>
                <hr />
            </div> */}
            {/* <div className="profile-nav-sub-links"> */}
            {/* <div>
                    <Link to={process.env.PUBLIC_URL + "/providers/account/bookings"} 
                        className={_.isEqual(currentNav, "/providers/account/bookings") ? "profile-nav-link bold" : "profile-nav-link"}>
                        Confirmed Bookings
                    </Link>
                    <hr />
                </div> */}
            <div>
                <Link to={process.env.PUBLIC_URL + "/providers/account/availability"} 
                    className={_.isEqual(currentNav, "/providers/account/availability") ? "profile-nav-link bold" : "profile-nav-link"}>
                        Availability
                </Link>
                <hr />
            </div>
            <div>
                <Link to={process.env.PUBLIC_URL + "/providers/account/calendar"} 
                    className={_.isEqual(currentNav, "/providers/account/calendar") ? "profile-nav-link bold" : "profile-nav-link"}>
                        Calendar
                </Link>
                <hr />
            </div>
            
            <div>
                <Link to={process.env.PUBLIC_URL + "/providers/account/email"} 
                    className={_.isEqual(currentNav, "/providers/account/email") ? "profile-nav-link bold" : "profile-nav-link"} >
                    Email &amp; password
                </Link>
                <hr />
            </div>
            <div>
                <a href="!#" className="profile-nav-link sign-out-link" 
                    onClick={(e: React.MouseEvent) => { 
                        e.preventDefault(); 
                        providerAuth0Client.signOut();
                    }}>
                    Log out
                </a>
                <hr />
            </div>
        </div>
    );
};

export default withRouter(ProfileNav);

