import React, { useState, useEffect, SetStateAction, Dispatch, Fragment } from "react";
import { RouteComponentProps, useRouteMatch, withRouter } from "react-router-dom";
import "./availability.css";
import { http } from "../../utils/axios-config";
import Loading from "../Utils/Loading";
import { ISchedule, IScheduleException } from "../../../types/interfaces";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import AvailabilityModal from "./AvailabilityModal";
import _ from "lodash";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { dateFormat, timeFormat, modalTimeFormat, DEFAULT_START_TIME, DEFAULT_END_TIME } from "./constants";
import { createSchedule } from "./Schedule";
import { convertSchedulesToEvents } from "./Calendar";
import AddScheduleModal from "./AddScheduleModal";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface IAvailabilityProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>,
    providerId: string
}

const Availability = ({ setCurrentNav, providerId }: IAvailabilityProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [calendarEvents, setCalendarEvents] = useState<ISchedule[]>([]);
	const [availabilityModalShow, setAvailabilityModalShow] = useState(false);
	const [availabilityModalEvent, setAvailabilityModalEvent] = useState({
		id: "",
		provider_id: "",
		is_recurring: false,
		start_time: "00:00:00+00",
		end_time: "00:00:00+00",
		start_date: "2021-02-12",
		end_date: "2021-02-12",
		day_of_week: null
	});
	const [addScheduleModalShow, setAddScheduleModalShow] = useState(false);
	const [addScheduleModalEvent, setAddScheduleModalEvent] = useState({
		startDateLocal: dayjs(),
		endDateLocal: dayjs(),
		start: DEFAULT_START_TIME,
		end: DEFAULT_END_TIME
	});
	const [newAvailabilityModalEvent, setNewAvailabilityModalEvent] = useState({
		start: "",
		end: ""
	});
	const [schedules, setSchedules] = useState<ISchedule[]>([]);
	const [scheduleExceptions, setScheduleExceptions] = useState<IScheduleException[]>([]);
	const { url } = useRouteMatch();

	useEffect(() => {
		setCurrentNav(url);
		const getData = async () => {
			setIsLoading(true);
			if (_.isNil(providerId)) {
				providerId = (await http("get", "/providers", "")).id;
			}
			const providerSchedules = await http("get", `/providers/${providerId}/schedules`, "");
			const providerScheduleExceptions = await http("get", `/providers/${providerId}/schedule-exceptions`, "");

			setSchedules(providerSchedules);
			setScheduleExceptions(providerScheduleExceptions);
			setCalendarEvents(convertSchedulesToEvents(providerSchedules, providerScheduleExceptions));
			setIsLoading(false);
		};
		void getData();
	}, []);

	// const addToCalendar = async () => {
	//     // TODO: Need users location to get timezone, then we can use that to convert to utc
	//     const startDateTime: Dayjs = dayjs(newEvent.start).utc();
	//     const endDateTime: Dayjs = dayjs(newEvent.end).utc();
	//     const newSchedule: ISchedule = {
	//         id: uuidv4(),
	//         provider_id: providerId,
	//         start_date: startDateTime.format(dateFormat),
	//         end_date: endDateTime.format(dateFormat),
	//         start_time: startDateTime.format(timeFormat),
	//         end_time: endDateTime.format(timeFormat),
	//         is_recurring: false,
	//         day_of_week: undefined
	//     };
	//     try {
	//         await createSchedule(newSchedule);
	//         setSchedules([...schedules, newSchedule]);
	//         setCalendarEvents(convertSchedulesToEvents([...schedules, newSchedule], scheduleExceptions));
	//     } catch (error) {
	//         console.log(error);
	//     }

	// };

	// For deleting the entire recurring schedule and not one instance
	const deleteRecurringSchedule = async (id: string) => {
		try {
			await http("delete", `/providers/${providerId}/schedules/${id}`, "");

			const newArr = schedules.filter((event) => {
				return event.id !== id;
			});
			setSchedules(newArr);
			setCalendarEvents(convertSchedulesToEvents(newArr, scheduleExceptions));
		} catch (error) {
			console.log(error);
		}
	};

	const renderEventContent = (eventInfo: any) => {
		return (
			<div className="pollie-link cursor-pointer mb-1">
				{eventInfo.timeText}
			</div>
		);
	};

	// Date Header
	const renderDayCellContent = (dayCellInfo: any) => {
		return (
			<Fragment>
				{ dayCellInfo.isToday &&
                    <div className="calendar-date">
                        Today
                    </div>
				}
				{ !dayCellInfo.isToday &&
                    <div className="calendar-date">
                    	{dayCellInfo.dayNumberText}
                    </div>
				}
			</Fragment>
		);
	};

	const noEventsText = () => {
		return (
			<div>
                + Add availability
			</div>
		);
	};

	const renderMoreLink = () => {
		return (
			<div>
                see all availability
			</div>
		);
	};

	if (_.isEqual(isLoading, true)) {
		return (
			<Loading style={{height: 300}}/>
		);
	}
	return (
		<div>
			<div className="profile-section">
                Set your available hours when clients can book appointments with you.
			</div>
			<div className="profile-section">
				<FullCalendar
					eventColor="#1e6a76"
					displayEventEnd={true}
					eventDisplay="list-item"
					plugins={[dayGridPlugin, interactionPlugin]}
					dayCellContent={renderDayCellContent}
					eventContent={renderEventContent}
					height="auto"
					showNonCurrentDates={true}
					fixedWeekCount={false}
					monthMode={true}
					initialView="dayGridMonth"
					selectable={false}
					unselectAuto={true}
					dayPopoverFormat={{ weekday: "long", day: "numeric"}}
					dateClick={(selectionInfo) => {
						const dateSelected = dayjs(selectionInfo.date);
						if (dayjs().isAfter(dateSelected, "day")) {
							return;
						}
						const newSchedule = {
							providerId: providerId,
							startDateLocal: dateSelected,
							endDateLocal: dateSelected,
							start: DEFAULT_START_TIME,
							end: DEFAULT_END_TIME
						};
						setAddScheduleModalEvent(newSchedule);
						setAddScheduleModalShow(true);
					}}
					events={calendarEvents}
					// moreLinkText={"see all availability"}
					noEventsText={"+ Add availability"}
					dayMaxEvents={2}
					eventTimeFormat={{
						hour: "numeric",
						minute: "2-digit",
						omitZeroMinute: false,
						meridiem: true
					}}
					moreLinkContent={renderMoreLink}
					moreLinkClassNames="pollie-link"
					// eventClassNames="pollie-link"
					// contentHeight="50rem"
					eventClick={(info) => {
						let updatedSchedule: any = scheduleExceptions.filter((event) => {
							return event.id === info.event.id;
						})[0];
						if (_.isNil(updatedSchedule)) {
							updatedSchedule = schedules.filter((event) => {
								return event.id === info.event.id;
							})[0];
							updatedSchedule.start_date = dayjs(info.event.start as Date).utc().format(dateFormat);
							updatedSchedule.end_date = dayjs(info.event.end as Date).utc().format(dateFormat);
						}
						if (dayjs().isAfter(dayjs(info.event.start as Date), "day")) {
							return;
						}

						setAvailabilityModalEvent(updatedSchedule);
						setNewAvailabilityModalEvent({
							"start": dayjs(updatedSchedule.start_time, timeFormat).format(modalTimeFormat).toString(),
							"end": dayjs(updatedSchedule.end_time, timeFormat).format(modalTimeFormat).toString()
						});
						setAvailabilityModalShow(true);
					}}
				/>
			</div>
			<AvailabilityModal show={availabilityModalShow} 
				newSchedule={availabilityModalEvent} 
				isLoading={isLoading} 
				setIsLoading={setIsLoading} 
				setAvailabilityModalShow={setAvailabilityModalShow} 
				newEvent={newAvailabilityModalEvent}
				setNewEvent={setNewAvailabilityModalEvent}
				schedules={schedules} 
				setSchedules={setSchedules}
				scheduleExceptions={scheduleExceptions}
				setScheduleExceptions={setScheduleExceptions}
				setCalendarEvents={setCalendarEvents} 
				deleteRecurringSchedule={deleteRecurringSchedule}
				providerId={providerId}
				setAddScheduleModalEvent={setAddScheduleModalEvent}
				setAddScheduleModalShow={setAddScheduleModalShow}/>
			<AddScheduleModal show={addScheduleModalShow} 
				setAddScheduleModalShow={setAddScheduleModalShow}
				newEvent={addScheduleModalEvent} 
				setNewEvent={setAddScheduleModalEvent}
				setIsLoading={setIsLoading} 
				setCalendarEvents={setCalendarEvents}
				createSchedule={createSchedule}
				schedules={schedules}
				setSchedules={setSchedules}
				scheduleExceptions={scheduleExceptions} />
		</div >
	);
};

export default withRouter(Availability);