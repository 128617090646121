import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {IAppointment, IMemberInfo, INote, IProvider} from "../../../../types/interfaces";
import {RouteComponentProps, withRouter} from "react-router-dom";
import _ from "lodash";
import pencilIcon from "../../../img/pencil.svg";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import {fullDateFormat, shownTimeFormat} from "../../Appointments/constants";
import "./NoteModal.css";
import {http} from "../../../utils/axios-config";
import {defaultNote} from "../../../utils/constants";
import {displayMembersName} from "../../Members/Utils";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface INoteModalProps extends RouteComponentProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	note: INote;
	appointment: IAppointment;
	member: IMemberInfo;
	updateNotes: any;
	setNote: Dispatch<SetStateAction<INote>>;
	isProvidersAppointment: boolean;
	providers: IProvider[];
}

function NoteModal({
	                   show,
	                   setShow,
	                   note,
	                   appointment,
	                   member,
	                   updateNotes,
	                   setNote,
	                   isProvidersAppointment,
	                   providers
                   }: INoteModalProps) {
	const [isEditNote, setIsEditNote] = useState(false);
	const [newNote, setNewNote] = useState("");
	const memberName = displayMembersName(member);
	const appointmentStartDateTime = dayjs.utc(appointment.start_time).local();
	const appointmentDate = appointmentStartDateTime.format(fullDateFormat);
	const appointmentStartTime = appointmentStartDateTime.format(shownTimeFormat);

	const noteCreatedDateTime = dayjs.utc(note.date_created).local();
	const noteDate = noteCreatedDateTime.format(fullDateFormat);
	const noteCreatedTime = noteCreatedDateTime.format(shownTimeFormat);
	let providerName = "";

	if (!_.isEmpty(note.provider_id)) {
		const provider = providers.find((provider) => {
			return _.isEqual(provider.id, note.provider_id);
		});
		if (!_.isNil(provider)) {
			providerName = `${provider.first_name} ${provider.last_name}`;
		}
	}

	useEffect(() => {
		if (show) {
			if (_.isEmpty(note.id)) {
				setIsEditNote(true);
			}
		}
	}, [show]);

	const onHide = () => {
		setShow(false);
	};

	const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
		setNewNote(e.currentTarget.value);
	};

	const saveNote = async () => {
		let noteResponse = defaultNote;
		const providerId = !_.isEmpty(note.appointment_id) ? appointment.provider_id : note.provider_id;
		if (_.isEmpty(note.id)) {
			noteResponse = await http("post", `/providers/${providerId}/members/${member.id}/notes`, {
				...note,
				text: newNote
			});
		} else {
			noteResponse = await http("put", `/providers/${providerId}/members/${member.id}/notes/${note.id}`, {
				...note,
				text: newNote
			});
		}
		setIsEditNote(false);
		setNote(noteResponse);
		updateNotes(noteResponse);
	};

	const cancelEdit = () => {
		setNewNote(note.text);
		setIsEditNote(false);
		if (_.isEmpty(note.id)) {
			onHide();
		}
	};

	const editNote = () => {
		setNewNote(note.text);
		setIsEditNote(true);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			contentClassName="note-modal-width"
		>
			<Modal.Header closeButton className="modal-header"/>
			<Modal.Body className="modal-body">
				<div className="container note-container">
					<div className="row justify-content-between">
						<div className="bold col pb-3">
							{!_.isEmpty(note.appointment_id) &&
								(`${appointment.type} note` || "Visit")
							}
							{_.isEmpty(note.appointment_id) &&
								"Progress notes"
							}
						</div>
						{(!isEditNote && isProvidersAppointment) &&
              <div className="pollie-link edit-note-link" onClick={() => editNote()}>
                <img alt="pencil icon" className="mb-1" src={pencilIcon}/>
                Edit note
              </div>
						}
					</div>
					<div>
						{memberName}
					</div>
					<div className="mb-3 note-timestamp">
						{!_.isEmpty(note.appointment_id) &&
							`${appointmentDate} at ${appointmentStartTime}`
						}
						{_.isEmpty(note.appointment_id) &&
							`${noteDate} at ${noteCreatedTime}`
						}
					</div>
					{isEditNote &&
            <textarea placeholder="Enter notes here..."
                      value={newNote} className="w-100 note-text-area"
                      onChange={handleOnChange} rows={10}/>
					}
					{!isEditNote &&
            <div style={{marginBottom: "10%"}} className="note-text">
							{note.text}
            </div>
					}
					{(!isEditNote && !_.isEmpty(providerName)) &&
            <div className="note-footer w-100">
              <div className="row justify-content-end mr-0">
                Completed by: {providerName}
              </div>
              <div className="row justify-content-end mr-0">
								{noteDate} at {noteCreatedTime}
              </div>
            </div>
					}
					{isEditNote &&
            <div className="row justify-content-lg-end">
              <Button className="btn btn-pollie-outline mt-4 mr-4" onClick={cancelEdit}>
                Cancel
              </Button>
              <Button className="btn btn-pollie-dark mt-4"
                      onClick={async () => {
								        await saveNote();
								        onHide();
							        }}>Save notes</Button>
            </div>
					}
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default withRouter(NoteModal);