

import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import { IMemberAppointment, IMemberInfo, INote, IProvider } from "../../../types/interfaces";
import { http } from "../../utils/axios-config";
import Appointment from "../Appointments/Appointment";
import AppointmentModal from "../Appointments/AppointmentModal";
import PastAppointment from "../Appointments/PastAppointment";
import "./style.css";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import { defaultMember } from "../../utils/constants";
import Loading from "../Loading";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

interface IVisitsProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>;
    providerId: string;
    memberId: string;
    notes: INote[];
    updateNotes: any;
    connectedProviders: IProvider[];
}

const Visits = ({ setCurrentNav, providerId, memberId, notes, updateNotes, connectedProviders }: IVisitsProps) => {
    const [currentAppointmentNav, setCurrentAppointmentNav] = useState<string>("Upcoming");
    const [appointmentModalShow, setAppointmentModalShow] = useState(false);
    const [upcomingAppointments, setUpcomingAppointments] = useState<IMemberAppointment[]>([]);
    const [pastAppointments,setPastAppointments] = useState<IMemberAppointment[]>([]);
    const [appointmentCancellation, setAppointmentCancellation] = useState({});
    const [member, setMember] = useState(defaultMember);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getMemberAppointments = async () => {
            setIsLoading(true);
            setCurrentNav("visits");
            const tempMember: IMemberInfo = await http("get", `/providers/${providerId}/members/${memberId }`, "");
            setMember(tempMember);

            const nowTime = dayjs.utc();

            // Get all of member's appointments
            const tempMemberAppointments: IMemberAppointment[] = await http("get", `/providers/${providerId}/members/${memberId}/appointments`, "");

            const tempUpcomingAppointments = tempMemberAppointments.filter((appointment: IMemberAppointment) => {
                return (dayjs.utc(appointment.end_time).isSameOrAfter(nowTime) && !appointment.canceled);
            });
            tempUpcomingAppointments.sort((appointment) => {
                return dayjs.utc(appointment.start_time).diff(nowTime, "minute");
            });
            const tempPastAppointments = tempMemberAppointments.filter((appointment: IMemberAppointment) => {
                return (dayjs.utc(appointment.end_time).isBefore(nowTime) && !appointment.canceled);
            });
            tempPastAppointments.sort((appointment) => {
                return dayjs.utc(appointment.start_time).diff(nowTime, "minute");
            });

            setUpcomingAppointments(tempUpcomingAppointments);
            setPastAppointments(tempPastAppointments);
            setIsLoading(false);
        };
        void getMemberAppointments();
    }, []);

    const cancelAppointment = async (canceledAppointment: IMemberAppointment) => {
        try {
            await http("put", `/appointments/${canceledAppointment.id}`, canceledAppointment);
            const newAppointments = upcomingAppointments.filter((appointment: IMemberAppointment) => {
                return canceledAppointment.id !== appointment.id;
            });
            setUpcomingAppointments(newAppointments);
        } catch (error) {
            console.log(error);
        }
    };

    if (isLoading) {
        return (
          <Loading style={{height: 300}}/>
        );
    }
    
    return (
        <div className="col-md-6">
            <div className="col-md-10">
                <a href="!#" className={currentAppointmentNav === "Upcoming" ? "appointment-nav-selected" : "appointment-nav"} 
                    onClick={(e: React.MouseEvent) => { 
                        e.preventDefault();
                        setCurrentAppointmentNav("Upcoming");
                    }}>{" Upcoming   "}</a>
                <a href="!#" className={currentAppointmentNav === "Past" ? "appointment-nav-selected" : "appointment-nav"} 
                    onClick={(e: React.MouseEvent) => { 
                        e.preventDefault(); 
                        setCurrentAppointmentNav("Past");
                    }}>{"   Past   "}</a>
                <hr className="appointment-hr"/>
                <br />
                {_.isEqual(currentAppointmentNav, "Upcoming") && !_.isEmpty(upcomingAppointments) &&
                    <div className="profile-section">
                        { upcomingAppointments.map((appointment) => {
                            const isProvidersAppointment = _.isEqual(appointment.provider_id, providerId);

                            return (
                                <Appointment key={appointment.id}
                                    appointment={appointment}
                                    setAppointmentModalShow={setAppointmentModalShow}
                                    setAppointmentCancellation={setAppointmentCancellation}
                                    notes={notes} 
                                    isProvidersAppointment={isProvidersAppointment}
                                    member={member}
                                    updateNotes={updateNotes}
                                    providers={connectedProviders}/>
                            );
                        })}
                        <br />
                    </div>
                }
                {_.isEqual(currentAppointmentNav, "Upcoming") && _.isEmpty(upcomingAppointments) &&
                    <div>
                        <div>
                            Member does not have any upcoming appointments.
                        </div>
                        <br />
                    </div>
                }
                {_.isEqual(currentAppointmentNav, "Past") && !_.isEmpty(pastAppointments) &&
                    <div className="profile-section">
                        { pastAppointments.map((appointment) => {
                            const isProvidersAppointment = _.isEqual(appointment.provider_id, providerId);

                            return (
                                <PastAppointment key={appointment.id} 
                                    appointment={appointment} 
                                    notes={notes} 
                                    isProvidersAppointment={isProvidersAppointment}
                                    member={member}
                                    updateNotes={updateNotes}
                                    providers={connectedProviders}/>
                            );
                        })}
                    </div>
                }
                {_.isEqual(currentAppointmentNav, "Past") && _.isEmpty(pastAppointments) &&
                    <div>
                        <div>
                            Member does not have any past appointments.
                        </div>
                        <br />
                    </div>
                }
                <AppointmentModal show={appointmentModalShow} 
                    setAppointmentModalShow={setAppointmentModalShow}
                    appointment={appointmentCancellation} 
                    cancelAppointment={cancelAppointment}
                    onHide={() => {
                        setAppointmentModalShow(false);
                    }} />
            </div>
        </div>
    );
};

export default withRouter(Visits);
