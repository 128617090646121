import React, {FormEvent, ChangeEvent, useState, useEffect} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import axios from "axios";
import EmailValidator from "email-validator";
import PasswordValidator from "password-validator";
import "../../App.css";
import queryString from "query-string";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { providerAuth0Client } from "../Auth0/Auth";
import _ from "lodash";

dayjs.extend(utc);
dayjs.extend(timezone);

const POLLIE_EMAIL = "@pollie.co";

interface SignUpProps extends RouteComponentProps {
    location: any;
}

const SignUp = ({ location }: SignUpProps) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [formError, setFormError] = useState(false);

    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_BASE_URL as string}`,
        headers: {
            "Content-Type": "application/json"
        }
    });

    useEffect(() => {
        const params = queryString.parse(location.search);
        if("email" in params) {
            setEmail(params.email as string);
        }    
    }, []);

    // const validateForm = async () => {
    //     return EmailValidator.validate(email) && password.length > 0;
    // }

    const handleSubmit = async(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError("");
        setFormError(false);
        if(!EmailValidator.validate(email) || !_.endsWith(email, POLLIE_EMAIL)) {
            setError("Please enter a valid Pollie email.");
            return;
        }
        // Create a schema
        const schema = new PasswordValidator();

        // Add properties to it
        schema
            .is().min(8)                                    // Minimum length 8
            .is().max(100)                                  // Maximum length 100
            .has().uppercase()                              // Must have uppercase letters
            .has().lowercase()                              // Must have lowercase letters
            .has().digits()                                 // Must have digits
            .has().not().spaces();                           // Should not have spaces

        if(!schema.validate(password)) {
            setError("Please enter a valid password. Greater than 8 characters, uppercase, lowercase, and at least 1 number.");
            return;
        }

        const timeZone = dayjs.tz.guess();

        try {
            await instance.post("/providers/signup", {
                email: email,
                password: password,
                timeZone: timeZone
            });
            await providerAuth0Client.signIn(email, password, location.state?.referrer);
        } catch(err: any) {
            console.log(err);
            const serverError = err.response?.data;
            if (serverError === "email already exists") {
                setFormError(true);
                setError("An account already exists with the provided email.");
            } else if (serverError === "password") {
                setError("Please enter a valid password. Greater than 8 characters, uppercase, lowercase, and at least 1 number.");
            } else if (serverError === "email") {
                setError("Please enter a valid Pollie email.");
            } else if (serverError === "unverified") {
                setError("Please reach out to hello@pollie.co to get approved as a provider.");
            } else {
                setError("Error. Please contact hello@pollie.co");
                setFormError(false);
            }
        }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setEmail(value);
    };
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setPassword(value);
    };

    return (
        <div className="container">
            <div className="row">
                {(!_.isEqual(error, "") && !formError) && 
                    <p className="form-error">
                        {error}
                    </p>
                }
                {(!_.isEqual(error, "") && formError) && 
                    <p className="form-error">
                        {"Please contact "}
                        <a href={"mailto:hello@pollie.co"}>
                            {"hello@pollie.co"}
                        </a>
                        {" with your information before signing up."}
                    </p>
                }
                <form className="w-100" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" 
                            className="form-control" 
                            id="email" 
                            aria-describedby="emailHelp" 
                            placeholder="Enter email" 
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" 
                            className="form-control" 
                            id="password" 
                            aria-describedby="passwordHelp" 
                            placeholder="Enter password" 
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <button className="form-submit-button btn-pollie-dark" type="submit">
                        {"Create my account"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default withRouter(SignUp);
