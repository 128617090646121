import _ from "lodash";
import React from "react";
import {
    Link,
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import { IMemberInfo } from "../../../types/interfaces";


interface IMemberNavProps extends RouteComponentProps {
    member: IMemberInfo,
    currentNav: string
}

const MemberNav = ({ currentNav, member }: IMemberNavProps) => {

    return (
        <div className="profile-links">
            <div>
                <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}/overview`} 
                    className={_.isEqual(currentNav, "overview") ? "profile-nav-link bold" : "profile-nav-link"}>
                        Overview
                </Link>
                <hr />
            </div>
            <div>
                <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}/visits`} 
                    className={_.isEqual(currentNav, "visits") ? "profile-nav-link bold" : "profile-nav-link"} >
                    Visits
                </Link>
                <hr />
            </div>
            <div>
                <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}/symptoms`} 
                    className={_.isEqual(currentNav, "symptoms") ? "profile-nav-link bold" : "profile-nav-link"} >
                    Symptoms
                </Link>
                <hr />
            </div>
            <div>
                <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}/notes`} 
                    className={_.isEqual(currentNav, "notes") ? "profile-nav-link bold" : "profile-nav-link"} >
                    Notes
                </Link>
                <hr />
            </div>
            <div>
                <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}/pcos-plan`} 
                    className={_.isEqual(currentNav, "pcos-plan") ? "profile-nav-link bold" : "profile-nav-link"}>
                    Care Plan
                </Link>
                <hr />
            </div>
            <div>
                <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}/documents`} 
                    className={_.isEqual(currentNav, "documents") ? "profile-nav-link bold" : "profile-nav-link"} >
                    Forms & Documents
                </Link>
                <hr />
            </div>
        </div>
    );
};

export default withRouter(MemberNav);

