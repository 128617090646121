import { UID } from "agora-rtc-sdk-ng";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { v4 } from "uuid";
import { IMemberInfo, IProvider } from "../../../types/interfaces";
import { http } from "../../utils/axios-config";
import RTMClient from "../../utils/rtm-client";
import { ProviderTypes } from "../Utils/ProviderTypes";
import { IMessage } from "./Message";
import { MessageStore } from "./MessageStore";

// Create a broadcast channel to keep multiple tabs messaging data in sync since only
// one agora client can be connected at any given time
const messagingChannel = new BroadcastChannel("CHANNEL_MESSAGING");

// An id unique to the tab, needed to differentiate whether the message originates from this tab
const broadcasterId: UID = v4();	

enum MessagingChannelAction {
	NEW = "NEW_MESSAGE",
	UPDATE = "UPDATE_MESSAGE"
}

const broadcastMessage = (message: IMessage, action: MessagingChannelAction) => messagingChannel.postMessage({
	broadcasterId,
	action,
	message
});

type MessagingAPI = {
	messageStore: MessageStore,
	sendMessage: (member: IMemberInfo, content: string) => Promise<void>,
	markRead: (memberId: string) => void
}

// Use messaging hook
// constructs a messageStore object to wrap the state and expose computed properties, 
// defines the agora client used for immediate transmition and receipt of messages
// defines a broadcast channel for sharing messaging state changes between tabs
// sets up listeners to receive messages from agora / broadcast, and defines methods that 
//   can are exposed to send messages / mutate the state
const useMessaging = (provider: IProvider, connectedMembers: IMemberInfo[]): MessagingAPI => {
	const [messages, setMessages] = useState<IMessage[]>([]);
	// messageStore: a wrapper that stores messages by memberID and adds access interface
	const messageStore = new MessageStore(messages, provider.id);	
	const [agoraClient, setAgoraClient] = useState<RTMClient>();

	// Methods for handling insert / update of message list
	const appendMessage = (newMessage: IMessage) => setMessages(messages => [...messages, newMessage]);
	const updateMessage = (message: IMessage) => setMessages(messages => messages.map(m => (m.id === message.id) ? message : m));

	const processBroadcastMessage = (ev: MessageEvent<{broadcasterId: UID, action: MessagingChannelAction, message: IMessage}>) => {
		if(ev.data.broadcasterId !== broadcasterId) {
			switch (ev.data.action) {
			case MessagingChannelAction.NEW:
				appendMessage(ev.data.message);
				break;
			case MessagingChannelAction.UPDATE:
				updateMessage(ev.data.message);
				break;
			default:
				throw new Error("Unknown action");
				break;
			}
		}
	};

	// one time set up listener
	useEffect(() => {
		messagingChannel.addEventListener("message", processBroadcastMessage);
		return(() => {
			messagingChannel.removeEventListener("message", processBroadcastMessage);
		});
	}, []);

	// Set up RTMClient
	useEffect(() => {
		if (provider.id !== "") {
			const tempAgoraClient = new RTMClient(provider.id);
			setAgoraClient(tempAgoraClient);
		}
	}, [provider.id]);

	// Populate initial messages
	useEffect(() => {
		(async () => {
			const tempMessages: IMessage[] = (await http("get", "/messages", "")).messages;
			const messages = tempMessages.map(message => {
				message.sentSuccessfully = true;
				return message;
			}).filter(message => { // remove any erroneous messages from non-connected members
				return message.senderId == provider.id 
				|| !!(connectedMembers.find(member => member.id === message.senderId));
			});
			setMessages(messages);
		})()
			.catch(reason => console.error(reason));
	}, [provider.id, connectedMembers]);

	// listen to receive messages
	useEffect(() => {
		const processReceivedMessage = (message: { text: string, messageType: string }, peerId: string): void => {
			// console.log(`message ${message.text} peerId ${peerId}`);
		
			const member: IMemberInfo | undefined = connectedMembers.find((member: IMemberInfo) => member.agora_rtm_uid === peerId);
			if (member === undefined) {
				console.error("Received a message from an un-connected member");
				return;
			}
		
			const createdAt = dayjs().toString();
			const newMessage: IMessage = {
				id: v4(),
				senderId: member.id,
				receiverId: provider.id,
				content: message.text,
				createdAt: createdAt,
				updatedAt: createdAt,
				readAt: null,
				isLoading: false,
				sentSuccessfully: true
			};
			appendMessage(newMessage);
			broadcastMessage(newMessage, MessagingChannelAction.NEW);
		};
	
		agoraClient?.on("MessageFromPeer", processReceivedMessage);

		// clean up
		return(() => {
			agoraClient?.removeListener("MessageFromPeer", processReceivedMessage);
		});
	}, [agoraClient, connectedMembers]);


	// define API to send messages, notice not in a useEffect, so this is redefined on every pass
	const sendMessage = async (member: IMemberInfo, content: string) => {
		if (agoraClient === undefined) {
			throw new Error("Agora client not defined, cannot send message");
		}

		const createdAt = dayjs().toString();
		const newMessage: IMessage = {
			id: v4(),
			senderId: provider.id,
			receiverId: member.id,
			content: content,
			createdAt: createdAt,
			updatedAt: createdAt,
			readAt: null,
			isLoading: true,
			sentSuccessfully: false
		};
		appendMessage(newMessage);
		broadcastMessage(newMessage, MessagingChannelAction.NEW);

		// Send RTM via Agora then append to "all messages"
		try {
			await agoraClient.sendPeerMessage(content, member.agora_rtm_uid);
			const apiResponse = await http("put", "/messages", {content, receiverId: member.id});

			if (typeof apiResponse === "object") {
				const updatedMessage = {...newMessage, sentSuccessfully: true, isLoading: false};
				updateMessage(updatedMessage);
				broadcastMessage(updatedMessage, MessagingChannelAction.UPDATE);
			}

		} catch (e) {
			const updatedMessage = {...newMessage, sentSuccessfully: false, isLoading: false};
			updateMessage(updatedMessage);
			broadcastMessage(updatedMessage, MessagingChannelAction.UPDATE);
		}

		// Each provider should only have one provider type assigned
		const providerType = ProviderTypes[provider.types?.[0]];

		// SEND NOTIFICATION OF PROVIDER MESSAGE TO MEMBER'S DEVICES
		try {
			
			const chatNotification = {
				title: "New message",
				body: `You have a message from ${providerType ? `your ${providerType} ` : ""}${provider.first_name}`,
				category: "chat",
				id: member.id
			};

			await http("post", "/notifications-fcm/send-fcm-notification", chatNotification);	
			
		} catch (error) {
			console.error(error);
		}
	};

	const markRead = (memberId: string) => {
		if (messageStore.unreadMessages(memberId) > 0) {
			// Mark read locally
			const readMessages = messages.map((message: IMessage) => {
				if (message.senderId === memberId) {
					return {...message, readAt: dayjs().toString()};
				}
				return message;
			});
				
			setMessages(readMessages);
	
			// Mark read in DB
			http("post", "/messages/mark-read", {senderId: memberId})
				.catch(reason => {
					console.error(`Failed to mark messages from membereId: ${memberId} as read.`, reason);
					// On failure, restore messages to current value
					setMessages(messages);
				});
		}

	};

	return {messageStore, sendMessage, markRead};
};

export default useMessaging;