import dayjs from "dayjs";
import { INote } from "../../../types/interfaces";

export const sortByNewestCreatedDate = (noteA: INote, noteB: INote): number => {
    return dayjs(noteB.date_created).diff(dayjs(noteA.date_created));
};

export const sortByOldestCreatedDate = (noteA: INote, noteB: INote): number => {
    return dayjs(noteA.date_created).diff(dayjs(noteB.date_created));
};
