import React, {useEffect, useState} from "react";
import {Redirect, Route, RouteComponentProps, Switch, useRouteMatch, withRouter} from "react-router-dom";
import { http } from "../../utils/axios-config";
import "./style.css";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { IAppointment, IMemberInfo, INote, IProvider } from "../../../types/interfaces";
import { defaultMember, defaultProvider } from "../../utils/constants";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "./style.css";
import Nav from "./Nav";
import Overview from "./Overview";
import PCOSPlan from "./PCOSPlan";
import Visits from "./Visits";
import Notes from "./Notes";
import Symptoms from "./Symptoms";
import Documents from "./Documents";
import { sortByNewestCreatedDate } from "./Utils";
import Loading from "../Loading";
import { displayMembersName } from "../Members/Utils";

dayjs.extend(timezone);
dayjs.extend(utc);

interface IMemberProfileProps extends RouteComponentProps {
    providerId: string
}

const MemberProfile = ({ providerId }: IMemberProfileProps) => {
    const [provider, setProvider] = useState<IProvider>(defaultProvider);
    const [member, setMember] = useState<IMemberInfo>(defaultMember);
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [notes, setNotes] = useState<INote[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const { memberId } = useParams<{ memberId: string }>();
    const { path, url } = useRouteMatch();
    const [connectedProviders, setConnectedProviders] = useState<IProvider[]>([]);

    const [currentNav, setCurrentNav] = useState("");

    useEffect(() => {
        const getMember = async () => {
            setIsLoading(true);
            try {
                const providerInfo: IProvider = await http("get", `/providers/${providerId}/personal-info`, "");
                setProvider({ ...providerInfo });

                const tempMember: IMemberInfo = await http("get", `/providers/${providerId}/members/${memberId}`, "");
                setMember(tempMember);

                // Get the provider's upcoming appointments
                let tempAppointments: IAppointment[] = await http("get", `/providers/${providerId}/appointments`, "");
                tempAppointments = tempAppointments.filter((appointment) => {
                    const currentTime = dayjs().tz(providerInfo.time_zone);
                    const endTime = dayjs(appointment.end_time).tz(providerInfo.time_zone);
                    return currentTime.isBefore(endTime, "minutes") && _.isEqual(appointment.member_id, tempMember.id) && !appointment.canceled;
                });
                setAppointments(tempAppointments);

                const tempNotes: INote[] = await http("get", `/providers/${providerId}/members/${tempMember.id}/notes`, "");
                tempNotes.sort(sortByNewestCreatedDate);
                setNotes(tempNotes);

                const tempConnectedProviders: IProvider[] = await http("get", `/providers/${providerId}/members/${memberId}/connected-providers`, "");
                setConnectedProviders(tempConnectedProviders);

            } catch (error) {
                setError("Sorry! We had a problem loading this member. Please email hello@pollie.co");
                setIsLoading(false);
            }
            setIsLoading(false);
        };
        void getMember();
    }, []);

    if (isLoading) {
        return <Loading style={{height: 300}}/>;
    }

    // Filter out the old note if it exists, then add in the new one regardless
    const updateNotes = (updatedNote: INote): void => {
        let noteExists = false;
        const updatedNotes = notes.map((note) => {
            if (!_.isEqual(note.id, updatedNote.id)) {
                return note;
            } else {
                noteExists = true;
                return updatedNote;
            }
        });
        if (!noteExists) {
            updatedNotes.unshift(updatedNote);
        }
        setNotes(updatedNotes);
    };
    
    return (
        <div className="container mb-5">
            <div className="row justify-content-md-start">
                <div className="col-md-4">
                    <div className="row mb-3 ml-1">
                        <h3>
                            {displayMembersName(member)}
                        </h3>
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-start">
                <div className="col-md-4">
                    <Nav member={member} currentNav={currentNav} />
                </div>
                <Switch>
                    <Route exact path={`${path}`}>
                        <Redirect to={`${url}/overview`} />
                    </Route>
                    <Route path={`${path}/overview`}>
                        <Overview provider={provider} member={member} appointments={appointments} setCurrentNav={setCurrentNav} />
                    </Route>
                    <Route path={`${path}/pcos-plan`}>
                        <PCOSPlan setCurrentNav={setCurrentNav} member={member} providerId={providerId} 
                            connectedProviders={connectedProviders} />
                    </Route>
                    <Route path={`${path}/visits`}>
                        <Visits setCurrentNav={setCurrentNav} providerId={providerId} memberId={memberId} 
                            notes={notes} updateNotes={updateNotes} connectedProviders={connectedProviders}/>
                    </Route>
                    <Route path={`${path}/notes`}>
                        <Notes setCurrentNav={setCurrentNav} notes={notes} updateNotes={updateNotes} providerId={providerId} 
                            memberId={memberId} setNotes={setNotes} connectedProviders={connectedProviders}/>
                    </Route>
                    <Route path={`${path}/symptoms`}>
                        <Symptoms setCurrentNav={setCurrentNav} />
                    </Route>
                    <Route path={`${path}/documents`}>
                        <Documents setCurrentNav={setCurrentNav} />
                    </Route>
                    <Route>
                        <Redirect to={`${url}/overview`} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(MemberProfile);
