import _ from "lodash";
import React, { Dispatch, Fragment, SetStateAction } from "react";
import {
    Link,
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import { subNavMap } from "../../../utils/maps";
import { providerAuth0Client } from "../../Auth0/Auth";

interface IMobileNavProps extends RouteComponentProps {
    currentSubNav: string,
    setCurrentSubNav: Dispatch<SetStateAction<string>>,
    setMobileNavActive: Dispatch<SetStateAction<boolean>>,
    setCurrentNavTitle: Dispatch<SetStateAction<string>>
}

const ProfileNav = ({currentSubNav, setCurrentSubNav, setMobileNavActive, setCurrentNavTitle}: IMobileNavProps) => {

    return (
        <div className="profile-links mt-5">
            { !_.isEmpty(currentSubNav) &&
                <Fragment>
                    <p className={"profile-nav-link bold"}>
                        {currentSubNav}
                    </p>
                    <hr />
                </Fragment>
            }
            { _.isEmpty(currentSubNav) &&
                <Fragment>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/providers/account/personalInfo"} 
                            className={"profile-nav-link"} 
                            onClick={() => {
                                setMobileNavActive(false);
                                setCurrentNavTitle("Personal info");
                            }}>
                            Personal info
                        </Link>
                        <hr />
                    </div>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/providers/account/availability"} 
                            className={"profile-nav-link"} 
                            onClick={() => {
                                setMobileNavActive(false);
                                setCurrentNavTitle("Availability");
                            }}>
                            Availability
                        </Link>
                        <hr />
                    </div>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/providers/account/calendar"} 
                            className={"profile-nav-link"} 
                            onClick={() => {
                                setMobileNavActive(false);
                                setCurrentNavTitle("Calendar");
                            }}>
                            Calendar
                        </Link>
                        <hr />
                    </div>
                    {/* <div>
                        <span onClick={() => {
                            setCurrentSubNav(subNavMap.appointments);
                        }}>
                            Appointments
                        </span>
                        <hr />
                    </div> */}
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/providers/account/email"} 
                            className={"profile-nav-link"} 
                            onClick={() => {
                                setMobileNavActive(false);
                                setCurrentNavTitle("Email & password");
                            }}>
                            Email &amp; password
                        </Link>
                        <hr />
                    </div>
                    <div>
                        <a href="!#" className="profile-nav-link" 
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => { 
                                e.preventDefault(); 
                                providerAuth0Client.signOut();
                            }}>
                            Log out
                        </a>
                        <hr />
                    </div>
                </Fragment>
            }
            { _.isEqual(currentSubNav, subNavMap.appointments) &&
                <Fragment>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/providers/account/bookings"} 
                            className={"profile-nav-link"}
                            onClick={() => {
                                setMobileNavActive(false);
                                setCurrentNavTitle("Confirmed Bookings");
                            }}>
                            Confirmed Bookings
                        </Link>
                        <hr />
                    </div>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/providers/account/availability"} 
                            className={"profile-nav-link"}
                            onClick={() => {
                                setMobileNavActive(false);
                                setCurrentNavTitle("Availability");
                            }}>
                            Availability
                        </Link>
                        <hr />
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default withRouter(ProfileNav);

