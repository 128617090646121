import React from "react";
import { withRouter } from "react-router-dom";
import {RouteComponentProps} from "react-router";

// Your component own properties
type PropsType = RouteComponentProps & {
  error: string
}

const ErrorState = (props:PropsType) => {
    const { error } = props;

    return (
        <div className="container not-found">
            <div className="row justify-content-lg-center">
                <div className="col-lg-4">
                    <h1>Error</h1>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ErrorState);