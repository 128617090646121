import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./appointments.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { fullDateFormat, shownTimeFormat } from "./constants";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { IMemberAppointment, IMemberInfo, INote, IProvider } from "../../../types/interfaces";
import _ from "lodash";
import NoteModal from "../MemberProfiles/Notes/NoteModal";
import { defaultNote } from "../../utils/constants";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface IPastAppointment {
    appointment: IMemberAppointment;
    notes: INote[];
    isProvidersAppointment: boolean;
    member: IMemberInfo;
    updateNotes: any;
    providers: IProvider[];
}

const Main = ({appointment, notes, isProvidersAppointment, member, updateNotes, providers}: IPastAppointment) => {
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [note, setNote] = useState(defaultNote);
    
    const appointmentStartDateTime = dayjs.utc(appointment.start_time).local();
    // const appointmentEndDateTime = dayjs.utc(appointment.end_time).local();

    const providerName = `${appointment.first_name} ${appointment.last_name}`;

    const appointmentDate = appointmentStartDateTime.format(fullDateFormat);
    const appointmentStartTime = appointmentStartDateTime.format(shownTimeFormat);
    // const appointmentEndTime = appointmentEndDateTime.format(shownTimeFormat + " z");

    useEffect(() => {
        let tempNote = notes.find((note) => {
            return _.isEqual(appointment.id, note.appointment_id);
        });
        if (_.isNil(tempNote)) {
            tempNote = {
                ...defaultNote, appointment_id: appointment.id
            };
        }
        setNote(tempNote);
    }, []);


    return (
        <div className="appointment-card">
            <div className="appointment-text bold"> 
                {appointment.type || "Visit"}
            </div>
            <div className="appointment-text">
                {`${appointmentDate} at ${appointmentStartTime}`}
            </div>
            <div className="appointment-text"> 
                {`Provider: ${providerName}`}
            </div>
            { (isProvidersAppointment && _.isEmpty(note.id)) &&
                <div className="btn btn-pollie-dark" onClick={() => {
                    setNoteModalShow(true);
                }}>
                    Add notes
                </div>
            }
            { !_.isEmpty(note.id) &&
                <div className="note-link pollie-link" onClick={() => {
                    setNoteModalShow(true);
                }}>
                    View notes
                </div>
            }
            <NoteModal show={noteModalShow} 
                setShow={setNoteModalShow}
                note={note}
                setNote={setNote}
                appointment={appointment}
                member={member}
                updateNotes={updateNotes}
                isProvidersAppointment={isProvidersAppointment}
                providers={providers}/>
        </div>
    );
};

export default Main;