import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import _ from "lodash";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import Checkmark from "../../Utils/Checkmark";

interface ITagDropdownProps extends RouteComponentProps {
    updateTags: (tagKey: number) => void;
    tagMap: Record<number, string>;
}

const TagDropdown = ({updateTags, tagMap}: ITagDropdownProps) => {

    return (
        <Dropdown as={ButtonGroup} className="tag-dropdown-container ml-2">
            <Dropdown.Toggle className="lab-dropdown-button pl-0 pr-0 mr-0">
                <div className="">
                    {"Select one"}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true}>
                { Object.entries(tagMap).map(([key, value]) => {
                    const keyInt = parseInt(key);
                    return (
                        <Dropdown.Item key={keyInt} eventKey={keyInt}
                            onClick={() => {
                                // setSelectedValue(keyInt);
                                updateTags(keyInt);
                            }}>
                            {/* { _.isEqual(keyInt, selectedValue) &&
                                <Checkmark />
                            } */}
                            {value}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(TagDropdown);
