import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { Fragment } from "react";
import {
    RouteComponentProps, useParams, withRouter
} from "react-router-dom";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Meeting from "../VideoCall/Meeting";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface IAppointmentCallProps extends RouteComponentProps {
    providerId: string
}
const AppointmentCall = ({ providerId }: IAppointmentCallProps) => {
    const { appointmentId } = useParams<{ appointmentId: string }>();

    return (
        <Fragment>
            <Meeting appointmentId={appointmentId} providerId={providerId}/>
        </Fragment>
    );
};

export default withRouter(AppointmentCall);