import React from "react";

export const AccountSettingsText = (props: any) => {
  
    return (
        <div>
            <div className="profile-section-header">
                {props.label}
            </div>
            <div>
                {!props.isComplete ? <div className="profile-incomplete">Incomplete</div> : props.dataValue}
            </div>
        </div>
    );
};