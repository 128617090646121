import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "./styles.css";
import { IMessage } from "./Message";
import { IMemberInfo, IProvider } from "../../../types/interfaces";
import { displayMembersInitials, displayMembersName } from "../Members/Utils";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface IMemberCardProps extends RouteComponentProps {
    member: IMemberInfo;
    unreadMessagesCount: number;
    mostRecentMessage: IMessage | null;
    provider: IProvider;
    memberSelected: IMemberInfo;
    onMemberClicked: any;
}

const MemberCard = ({ member, unreadMessagesCount, mostRecentMessage, provider, memberSelected, onMemberClicked}: IMemberCardProps) => {
	const memberInitials = displayMembersInitials(member);
	const memberName = displayMembersName(member);
	const [displayName, setDisplayName] = useState(memberName);
	const [isUnread, setIsUnread] = useState(unreadMessagesCount > 0);
	const [timeDisplay, setTimeDisplay] = useState("");
	useEffect(() => {
		if (!_.isNil(unreadMessagesCount)) {
			setIsUnread(unreadMessagesCount > 0);
		} else {
			setIsUnread(false);
		}
	}, [unreadMessagesCount]);

	useEffect(() => {
		let tempDisplayName = displayName;
		if (isUnread && memberName.length > 12) {
			tempDisplayName = `${memberName.slice(0, 12)}...`;
			// If character before ellipses is space, remove it
			if (_.isEqual(displayName[11], " ")) {
				tempDisplayName = displayName.replace(" ", "");
			}
		} else if (memberName.length > 18) {
			tempDisplayName = `${memberName.slice(0, 18)}...`;
			// If character before ellipses is space, remove it
			if (_.isEqual(displayName[17], " ")) {
				tempDisplayName = displayName.replace(" ", "");
			}
		}
		setDisplayName(tempDisplayName);

		if (mostRecentMessage && mostRecentMessage?.readAt) {
			setIsUnread(false);
		}
		const today = dayjs().tz(provider.time_zone);
		const mostRecentMessageTime = dayjs(mostRecentMessage?.createdAt).tz(provider.time_zone);
		let mostRecentMessageTimeDisplay = mostRecentMessageTime.format("M/D/YY");
		if (mostRecentMessageTime.isSame(today, "day")) {
			mostRecentMessageTimeDisplay = mostRecentMessageTime.format("h:mm A");
		} else if (mostRecentMessageTime.isSame(today.subtract(1, "day"), "day")) {
			mostRecentMessageTimeDisplay = "Yesterday";
		} else if (today.diff(mostRecentMessageTime, "day") < 7) {
			mostRecentMessageTimeDisplay = mostRecentMessageTime.format("dddd");
		} 
		setTimeDisplay(mostRecentMessageTimeDisplay);
	}, [isUnread, mostRecentMessage]);

	return (
		<div key={member.id}
			className={_.isEqual(member.id, memberSelected.id) ? 
				"member-messaging-card selected row pr-2 mr-0 ml-0" : "member-messaging-card row pr-2 mr-0 ml-0"}
			onClick={() => onMemberClicked(member)}>
			<div className="col-2 pl-2 pr-0">
				<div className="profile-initials">
					{memberInitials}
				</div>
			</div>
			<div className={isUnread ? "col-4 pl-2 pr-0 display-name" : "col-7 pl-2 pr-0 display-name"}>
				{displayName}
			</div>
			{ (isUnread) &&
                <div className="col-3 p-1">
                	<div className="new-message btn-pollie-dark">
                        New
                	</div>
                </div>
			}
			<div className="col-3 most-recent-message-time pr-0">
				{!_.isNil(mostRecentMessage) &&
                    timeDisplay
				}
			</div>
		</div>
	);
};

export default withRouter(MemberCard);