import React from "react";
import "./style.css";
import instagram from "../../img/instagram.svg";
import { withRouter } from "react-router-dom";

function Footer() {
    return (
        <footer className="page-footer font-small blue pt-4">

            <div className="container text-center text-md-left">

                <div className="row justify-content-lg-center py-md-5">

                    <div className="col-lg-10 footer-column">

                        <div className="row">

                            <div className="col-md-4 mt-md-0 mt-3">

                                <h5 className="category-headers footer-header">Connect with us</h5>
                                <p><a className="footer-email" href="mailto:hello@pollie.co">hello@pollie.co</a></p>
                                <p>
                                    <a className="footer-instagram" href="https://www.instagram.com/pollie.co/?hl=en" target="_blank" rel="noopener noreferrer">
                                        <img alt="Instagram" src={ instagram } />
                                    </a>
                                </p>

                            </div>

                            <div className="col-md-4 mb-md-0 mb-3">

                                <h5 className="category-headers footer-header">Join the flock</h5>

                                <ul className="list-unstyled">
                                    <li>
                                        <a className="footer-link" href="https://www.pollie.co/brand-ambassadors" target="_blank" rel="noopener noreferrer">Become a Pollie Ambassador</a>
                                    </li>
                                </ul>

                            </div>

                            <hr className="clearfix w-100 d-md-none pb-3 footer-hr" />

                            <div className="col-md-4 mb-md-0 mb-3">

                                <ul className="list-unstyled">
                                    <li>
                                        <a className="footer-link" href="https://www.pollie.co/provider-pledge" target="_blank" rel="noopener noreferrer">Provider Pledge</a>
                                    </li>
                                    <li>
                                        <a className="footer-link" href="https://www.pollie.co/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                                    </li>
                                    <li>
                                        <a className="footer-link" href="https://www.pollie.co/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                    </li>
                                </ul>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </footer>
    );
}

export default withRouter(Footer);