import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import { providerAuth0Client } from "./Auth";

const PublicRoute = ({...routeProps}: RouteProps) => {
    if(providerAuth0Client.isAuthenticated()) {
        return <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/providers`}} />;
    } else {
        return <Route {...routeProps} />;
    }
};

export default PublicRoute;