import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IMemberInfo, IPlan } from "../../../../types/interfaces";
import EditLabResults from "./EditLabResults";
import PlanModalHeader from "./PlanModalHeader";

interface IEditDraftModalProps extends RouteComponentProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    draftPlan: IPlan;
    deleteDraft: any;
    saveDraft: any;
    publishDraft: any;
    member: IMemberInfo;
    lifeStageTags: any; 
    symptomsTags: any;
    goalTags: any;
    setLifeStageTags: any;
    setSymptomsTags: any;
    setGoalTags: any;
}

const EditDraftModal = ({ show, setShow, draftPlan, deleteDraft, saveDraft, 
    publishDraft, member, lifeStageTags, symptomsTags, goalTags, setLifeStageTags,
    setSymptomsTags, setGoalTags }: IEditDraftModalProps) => {


    const onHide = () => {
        setShow(false);
    };
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="note-modal-width">
            <Modal.Header closeButton className="modal-header"/>
            <Modal.Body className="modal-body">
                <PlanModalHeader member={member} plan={draftPlan} isEdit={true}
                    lifeStageTags={lifeStageTags} 
                    symptomsTags={symptomsTags} 
                    goalTags={goalTags}
                    setLifeStageTags={setLifeStageTags} 
                    setSymptomsTags={setSymptomsTags} 
                    setGoalTags={setGoalTags}/>
                <EditLabResults publishDraft={publishDraft} 
                    plan={draftPlan} 
                    deleteDraft={deleteDraft} 
                    saveDraft={saveDraft}
                    lifeStageTags={lifeStageTags} 
                    symptomsTags={symptomsTags} 
                    goalTags={goalTags}/>
            </Modal.Body>
        </Modal>
    );
};

export default withRouter(EditDraftModal);