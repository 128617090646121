import _ from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IMemberInfo, IPlan } from "../../../../types/interfaces";
import { lifeStageMap, goalsMap } from "../../../utils/constants";
import DisplayTags from "./DisplayTags";
import { symptomTagsMap } from "../../../utils/maps";
import plusIcon from "../../../img/plus-icon.svg";
import TagDropdown from "./TagDropdown";
import { displayMembersName, onDeleteTagClick } from "../../Members/Utils";

interface IPlanModalHeaderProps extends RouteComponentProps {
    member: IMemberInfo;
    plan: IPlan;
    createNewPlan?: any;
    allowNewDraft?: boolean;
    isEdit?: boolean;
    lifeStageTags?: any; 
    symptomsTags?: any;
    goalTags?: any;
    setLifeStageTags?: any;
    setSymptomsTags?: any;
    setGoalTags?: any;
}

const PlanModalHeader = ({ member, plan, createNewPlan, allowNewDraft=false, isEdit=false,
    lifeStageTags, setLifeStageTags, symptomsTags, setSymptomsTags, goalTags, setGoalTags }: IPlanModalHeaderProps) => {
    const [isAddLifeStage, setIsAddLifeStage] = useState(false);
    const [isAddSymptoms, setIsAddSymptoms] = useState(false);
    const [isAddGoals, setIsAddGoals] = useState(false);

    useEffect(() => {
        if (isEdit) {
            setLifeStageTags(plan.life_stage_tags);
            setSymptomsTags(plan.symptom_tags);
            setGoalTags(plan.goal_tags);
        }
    }, [plan, isEdit]);

    const onUpdateSymptoms = (tagKey: number) => {
        setSymptomsTags([...symptomsTags, tagKey]);
        setIsAddSymptoms(false);
    };

    const onUpdateLifeStage = (tagKey: number) => {
        setLifeStageTags([...lifeStageTags, tagKey]);
        setIsAddSymptoms(false);
    };

    const onUpdateGoals = (tagKey: number) => {
        setGoalTags([...goalTags, tagKey]);
        setIsAddGoals(false);
    };

    return (
        <Fragment>
            <div className="row justify-content-between bold">
                <h1 className="col">
                    {displayMembersName(member)}
                </h1>
                <div className="col text-right">
                    {`Care Plan v${plan.version + 1}`}
                </div>
            </div>
            { allowNewDraft &&
                <div className="row justify-content-end mr-0">
                    <div className="btn btn-pollie-dark p-1" onClick={createNewPlan}>
                        Create new version
                    </div>
                </div>
            }
            <div className="sub-header-text mt-2">
                Life stage
            </div>
            <DisplayTags tags={isEdit ? lifeStageTags : plan.life_stage_tags} tagMap={lifeStageMap} isEdit={isEdit}
                onDeleteClick={(tag: number) => onDeleteTagClick(tag, lifeStageTags, setLifeStageTags)}/>
            { isEdit &&
                <div className="row ml-0 mr-0 add-lab-container">
                    <div className="row ml-0 mr-0 add-lab-container" onClick={() => setIsAddLifeStage(true)}>
                        <img alt="add" src={plusIcon} />
                        <div className="pollie-link add-lab">
                            Life stage
                        </div>
                    </div>
                    { isAddLifeStage &&
                        <TagDropdown updateTags={onUpdateLifeStage} tagMap={lifeStageMap}/>
                    }
                </div>
            }
            <div className="sub-header-text mt-3">
                Symptoms
            </div>
            <DisplayTags tags={isEdit ? symptomsTags : plan.symptom_tags} tagMap={symptomTagsMap} isEdit={isEdit}
                onDeleteClick={(tag: number) => onDeleteTagClick(tag, symptomsTags, setSymptomsTags)}/>
            { isEdit &&
                <div className="row ml-0 mr-0 add-lab-container">
                    <div className="row ml-0 mr-0 add-lab-container" onClick={() => setIsAddSymptoms(true)}>
                        <img alt="add" src={plusIcon} />
                        <div className="pollie-link add-lab">
                            Symptoms
                        </div>
                    </div>
                    { isAddSymptoms &&
                        <TagDropdown updateTags={onUpdateSymptoms} tagMap={symptomTagsMap}/>
                    }
                </div>
            }
            <div className="sub-header-text mt-3">
                Goals
            </div>
            <DisplayTags tags={isEdit ? goalTags : plan.goal_tags} tagMap={goalsMap} isEdit={isEdit}
                onDeleteClick={(tag: number) => onDeleteTagClick(tag, goalTags, setGoalTags)}/>
            { isEdit &&
                <div className="row ml-0 mr-0 add-lab-container">
                    <div className="row ml-0 mr-0 add-lab-container" onClick={() => setIsAddGoals(true)}>
                        <img alt="add" src={plusIcon} />
                        <div className="pollie-link add-lab">
                            Add goals
                        </div>
                    </div>
                    { isAddGoals &&
                        <TagDropdown updateTags={onUpdateGoals} tagMap={goalsMap}/>
                    }
                </div>
            }
        </Fragment>
    );
};

export default withRouter(PlanModalHeader);