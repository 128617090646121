import dayjs from "dayjs";
import _ from "lodash";
import { IMemberInfo } from "../../../types/interfaces";

export const trimMemberName = (member: IMemberInfo): string => {
    let memberName = displayMembersName(member);
    if (memberName.length > 20) {
        memberName = `${memberName.slice(0, 20)}...`;
    }
    return memberName;
};

export const calculateMemberWeek = (dateSubscribed: string): number => {
    const nowTime = dayjs.utc();
    const subscriptionStarted = dayjs.utc(dateSubscribed);
    const daysAsMember = nowTime.diff(subscriptionStarted, "days") + 1;
    const currentWeek = Math.ceil(daysAsMember / 7);
    return currentWeek;
};

export const onDeleteTagClick = (deleteTag: number, tags: number[], setTag: any) => {
    const tempTags = tags.filter((tempTag) => {
        return !_.isEqual(deleteTag, tempTag);
    });
    setTag(tempTags);
};

export const displayMembersName = (member: IMemberInfo | undefined): string => {
    if (_.isNil(member)) {
        return "New Member";
    }

    if ((!_.isNil(member.first_name) && !_.isNil(member.last_name)
        && !_.isEmpty(member.first_name) && !_.isEmpty(member.last_name))) {
        return `${member.first_name} ${member.last_name}`;
    } else if (!_.isNil(member.first_name) && !_.isEmpty(member.first_name)) {
        return member.first_name;
    } else if (!_.isNil(member.last_name) && !_.isEmpty(member.last_name)) {
        return member.last_name;
    } else {
        return "New Member";
    }
};

export const displayMembersInitials = (member: IMemberInfo | undefined): string => {
    if (_.isNil(member)) {
        return "NM";
    }

    if ((!_.isNil(member.first_name) && !_.isNil(member.last_name)
        && !_.isEmpty(member.first_name) && !_.isEmpty(member.last_name))) {
        return `${member.first_name[0]}${member.last_name[0]}`;
    } else if (!_.isNil(member.first_name) && !_.isEmpty(member.first_name)) {
        return member.first_name[0];
    } else if (!_.isNil(member.last_name) && !_.isEmpty(member.last_name)) {
        return member.last_name[0];
    } else {
        return "NM";
    }
};