import React, {Dispatch, SetStateAction, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "../style.css";
import _ from "lodash";
import { INote, IProvider } from "../../../../types/interfaces";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import Checkmark from "../../Utils/Checkmark";
import { sortByNewestCreatedDate, sortByOldestCreatedDate } from "../Utils";

interface INoteFilterDropdownProps extends RouteComponentProps {
    notes: INote[];
    setNotes: Dispatch<SetStateAction<INote[]>>;
    providers: IProvider[];
}

const NoteFilterDropdown = ({ notes, setNotes, providers }: INoteFilterDropdownProps) => {
    const [selectedFilter, setSelectedFilter] = useState("1");

    const onFilterChange = (filterFunction: any) => {
        const filteredNotes = [...notes].sort(filterFunction);
        setNotes(filteredNotes);
    };

    const sortByAlphabetical = (noteA: INote, noteB: INote): number => {
        const providerInfoA = providers.find((provider) => {
            return _.isEqual(provider.id, noteA.provider_id);
        });

        const providerInfoB = providers.find((provider) => {
            return _.isEqual(provider.id, noteB.provider_id);
        });

        if (!_.isNil(providerInfoA) && !_.isNil(providerInfoB)) {
            return providerInfoA.last_name.localeCompare(providerInfoB.last_name);
        } else {
            return 0;
        }
    };

    const sortByReverseAlphabetical = (noteA: INote, noteB: INote): number => {
        const providerInfoA = providers.find((provider) => {
            return _.isEqual(provider.id, noteA.provider_id);
        });

        const providerInfoB = providers.find((provider) => {
            return _.isEqual(provider.id, noteB.provider_id);
        });
        if (!_.isNil(providerInfoA) && !_.isNil(providerInfoB)) {
            return providerInfoB.last_name.localeCompare(providerInfoA.last_name);
        } else {
            return 0;
        }
    };

    return (

        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle className="dropdown-button pl-0">
                Sort by
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true}>
                <Dropdown.Item eventKey="1" active={_.isEqual("1", selectedFilter)} onClick={() => {
                    onFilterChange(sortByNewestCreatedDate);
                    setSelectedFilter("1");
                }}>
                    { _.isEqual("1", selectedFilter) &&
                        <Checkmark />
                    }
                    Note created date, newest
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" active={_.isEqual("2", selectedFilter)} onClick={() => {
                    onFilterChange(sortByOldestCreatedDate);
                    setSelectedFilter("2");
                }}>
                    { _.isEqual("2", selectedFilter) &&
                        <Checkmark />
                    }
                    Note created date, oldest
                </Dropdown.Item>
                <Dropdown.Item eventKey="3" active={_.isEqual("3", selectedFilter)} onClick={() => {
                    onFilterChange(sortByAlphabetical);
                    setSelectedFilter("3");
                }}>
                    { _.isEqual("3", selectedFilter) &&
                        <Checkmark />
                    }
                    Alphabetical, last name A-Z
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" active={_.isEqual("4", selectedFilter)} onClick={() => {
                    onFilterChange(sortByReverseAlphabetical);
                    setSelectedFilter("4");
                }}>
                    { _.isEqual("4", selectedFilter) &&
                        <Checkmark />
                    }
                    Alphabetical, last name Z-A
                </Dropdown.Item>        
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(NoteFilterDropdown);
