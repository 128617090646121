import { ISchedule } from "../../../types/interfaces";
import { http } from "../../utils/axios-config";

const createSchedule = async (newSchedule: ISchedule): Promise<void> => {
    await http("post", `/providers/${newSchedule.provider_id}/schedules`, newSchedule);
};

// If an exception already exists, won't end up here
const updateSchedule = async (updatedSchedule: ISchedule): Promise<void> => {
    // For non-recurring, PUT to update the event
    await http("put", `/providers/${updatedSchedule.provider_id}/schedules/${updatedSchedule.id}`, updatedSchedule);
};

const deleteSchedule = async (deletedSchedule: ISchedule): Promise<void> => {
    const providerId = deletedSchedule.provider_id;
    await http("delete", `/providers/${providerId}/schedules/${deletedSchedule.id}`, "");
};

export { createSchedule, updateSchedule, deleteSchedule };