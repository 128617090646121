import React, {Dispatch, SetStateAction, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import _ from "lodash";
import { IAppointment, IMemberInfo } from "../../../types/interfaces";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import dayjs from "dayjs";
import Checkmark from "../Utils/Checkmark";

interface IMemberFilterDropdownProps extends RouteComponentProps {
    members: IMemberInfo[],
    setFilteredMembers: Dispatch<SetStateAction<IMemberInfo[]>>,
    resetPage: any,
    appointments: IAppointment[]
}

const MemberFilterDropdown = ({ members, setFilteredMembers, resetPage, appointments }: IMemberFilterDropdownProps) => {
    const [selectedFilter, setSelectedFilter] = useState("3");

    const onFilterChange = (filterFunction: any) => {
        const filteredMembers = [...members].sort(filterFunction);
        setFilteredMembers(filteredMembers);
        resetPage(filteredMembers);
    };

    const sortByNewestStartDate = (memberA: IMemberInfo, memberB: IMemberInfo): number => {
        return dayjs(memberB.date_created).diff(dayjs(memberA.date_created));
    };

    const sortByOldestStartDate = (memberA: IMemberInfo, memberB: IMemberInfo): number => {
        return dayjs(memberA.date_created).diff(dayjs(memberB.date_created));
    };

    const sortByAlphabetical = (memberA: IMemberInfo, memberB: IMemberInfo): number => {
        const memberAName = memberA.last_name || "Member";
        const memberBName = memberB.last_name || "Member";
        return memberAName.localeCompare(memberBName);
    };

    const sortByReverseAlphabetical = (memberA: IMemberInfo, memberB: IMemberInfo): number => {
        const memberAName = memberA.last_name || "Member";
        const memberBName = memberB.last_name || "Member";
        return memberBName.localeCompare(memberAName);
    };

    const filterByNextVisit = () => {
        const sortedByVisitMembers: IMemberInfo[] = [];

        const sortedAppointments = [...appointments].sort((appointmentA, appointmentB) => {
            return dayjs(appointmentA.start_time).diff(dayjs(appointmentB.start_time));
        });

        for (const appointment of sortedAppointments) {
            const appointmentMember = members.find((member) => {
                return _.isEqual(member.id, appointment.member_id);
            });
            if (!_.isNil(appointmentMember) && !sortedByVisitMembers.includes(appointmentMember)) {
                sortedByVisitMembers.push(appointmentMember);
            }
        }
        setFilteredMembers(sortedByVisitMembers);
        resetPage(sortedByVisitMembers);
    };

    return (

        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle className="dropdown-button pl-0">
                Sort by
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true}>
                <Dropdown.Item eventKey="1" active={_.isEqual("1", selectedFilter)} onClick={() => {
                    onFilterChange(sortByNewestStartDate);
                    setSelectedFilter("1");
                }}>
                    { _.isEqual("1", selectedFilter) &&
                        <Checkmark />
                    }
                    Program start date, newest
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" active={_.isEqual("2", selectedFilter)} onClick={() => {
                    onFilterChange(sortByOldestStartDate);
                    setSelectedFilter("2");
                }}>
                    { _.isEqual("2", selectedFilter) &&
                        <Checkmark />
                    }
                    Program start date, oldest
                </Dropdown.Item>
                <Dropdown.Item eventKey="3" active={_.isEqual("3", selectedFilter)} onClick={() => {
                    onFilterChange(sortByAlphabetical);
                    setSelectedFilter("3");
                }}>
                    { _.isEqual("3", selectedFilter) &&
                        <Checkmark />
                    }
                    Alphabetical, last name A-Z
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" active={_.isEqual("4", selectedFilter)} onClick={() => {
                    onFilterChange(sortByReverseAlphabetical);
                    setSelectedFilter("4");
                }}>
                    { _.isEqual("4", selectedFilter) &&
                        <Checkmark />
                    }
                    Alphabetical, last name Z-A
                </Dropdown.Item>
                <Dropdown.Item eventKey="5" active={_.isEqual("5", selectedFilter)} onClick={() => {
                    filterByNextVisit();
                    setSelectedFilter("5");
                }}>
                    { _.isEqual("5", selectedFilter) &&
                        <Checkmark />
                    }
                    Next visit, newest
                </Dropdown.Item>            
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(MemberFilterDropdown);



