import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, {createRef, Dispatch, SetStateAction, useEffect, useState} from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import ChatArea from "./ChatArea";
import MessageSender from "./MessageSender";
import "./styles.css";
import {IMessage} from "./Message";
import {http} from "../../utils/axios-config";
import {IMemberInfo, IProvider} from "../../../types/interfaces";
import {defaultMember} from "../../utils/constants";
import MemberFilter from "./MemberFilter";
import MemberCard from "./MemberCard";
import {displayMembersInitials, displayMembersName} from "../Members/Utils";
import { MessageStore } from "./MessageStore";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface IMessagingProps extends RouteComponentProps {
	provider: IProvider;
	sendMessage: (member: IMemberInfo, content: string) => Promise<void>;
	markRead: (memberId: string) => void;
	members: IMemberInfo[];
  messageStore: MessageStore;
}

const getFilteredMembers = (members: IMemberInfo[], filter: string) => members.filter((member) => {
	const startsWith = (name: string) => name.toLowerCase().startsWith(filter);
	return startsWith(member.first_name || "New") || startsWith(member.last_name || "Member");
});

const sortMembers = (members: IMemberInfo[], messageStore: MessageStore) => {
	const messageDate = (member: IMemberInfo): dayjs.Dayjs => {
		const created = messageStore.latestWithMember(member.id)?.createdAt;
		return (created !== undefined) ? dayjs(created) : dayjs("2000-01-01"); // dummy value earlier than all possible messages
	};

	return members.sort((memberA, memberB) => {
		return messageDate(memberB).diff(messageDate(memberA))				// First sort by latest message Date if any
			|| dayjs(memberB.date_created).diff(memberA.date_created);	// Then by date_created
	});
};

const Messaging = ({
	provider, sendMessage, markRead, 
	members, messageStore
}: IMessagingProps) => {
	const [searchValue, setSearchValue] = useState<string>("");
	const [memberSelected, setMemberSelected] = useState<IMemberInfo>(defaultMember);
	const [scrolled, setScrolled] = useState(false);

	const chatboxElement = createRef<HTMLDivElement>();

	const handleSendMessage = (content: string) => {
		void sendMessage(memberSelected, content);
	};

	const onMemberClicked = (member: IMemberInfo) => {
		if (memberSelected.id !== "") {
			markRead(memberSelected.id);
		}

		setMemberSelected(member);
	};

	useEffect(() => {
		const params = queryString.parse(location.search);
		if ("member" in params) {
			const selectedMember = members.find((member) => {
				return _.isEqual(member.id, params.member);
			});
			if (!_.isNil(selectedMember)) {
				void onMemberClicked(selectedMember);
			}
		}
	}, []);

	const updateScroll = () => {
		if (!scrolled) {
			const element = document.getElementById("chat-area");
			if (!_.isNil(element)) {
				element.scrollTop = element.scrollHeight;
			}
		}
	};

	const currentMessages = [...messageStore.messagesWithMember(memberSelected.id)].reverse();

	useEffect(() => {
		markRead(memberSelected.id);
	}, [memberSelected.id, currentMessages.length]);

	const membersForDisplay = sortMembers(
		getFilteredMembers(members, searchValue),
		messageStore
	);
	
	return (
		<div className="message-container container">
			<div className="row message-chat-header">
				<div className="col-md-4 message-member-title">
					<div className="bold pt-4 pb-2">
						Members
					</div>
				</div>
				{!_.isEmpty(memberSelected.id) &&
          <div className="col-md-8">
          	<div className="chat-header">
          		<Link
			          to={process.env.PUBLIC_URL + `/providers/members/${memberSelected.id}/overview`}
			          target={"_blank"}
			          className={"row"}
		          >
          			<div className="profile-initials ml-3 mt-3 mr-1">
          				<Link
          					style={{display: "inline-flex", color: "white"}}
          					to={process.env.PUBLIC_URL + `/providers/members/${memberSelected.id}/overview`}
          					target={"_blank"}
          				>
          					{displayMembersInitials(memberSelected)}
          				</Link>
          			</div>
          			<div className="mt-3" style={{color: "black"}}>
          				{displayMembersName(memberSelected)}
          			</div>
          		</Link>
          	</div>
          </div>
				}
			</div>
			<div className="row h-100">
				<div className="col-md-4 message-container-left members-chat-list ">
					<div className="mb-4 w-100">
						<MemberFilter searchValue={searchValue} setSearchValue={setSearchValue}/>
					</div>
					{membersForDisplay.length ? (membersForDisplay.map((member) => {
						return (
							<MemberCard key={member.id} member={member} unreadMessagesCount={messageStore.unreadMessages(member.id)}
								            mostRecentMessage={messageStore.latestWithMember(member.id)} provider={provider}
								            memberSelected={memberSelected} onMemberClicked={onMemberClicked}/>
						);
					}))
						: "No messages currently"}
				</div>
				{!_.isEmpty(memberSelected.id) &&
          <div className="col-md-8 message-container-right" ref={chatboxElement}>
          	<div className="row pl-5 chat-area-container">
          		<ChatArea
          			messages={currentMessages}
          			providerId={provider.id}
          			member={memberSelected}
          			updateScroll={updateScroll}/>
          	</div>
          	<div className="justify-content-center w-100">
          		<MessageSender
          			sendMessage={handleSendMessage}
          			chatboxElement={chatboxElement}
          			updateScroll={updateScroll}
          			memberSelected={memberSelected}/>
          	</div>
          </div>
				}
			</div>
		</div>
	);
};

export default withRouter(Messaging);