import _ from "lodash";
import React from "react";
import { withRouter, Link } from "react-router-dom";

const NotFound = (props: any) => {
    const { homeUrl } = props;

    return (
        <div className="container not-found">
            <div className="row justify-content-lg-center">
                <div className="col-lg-4">
                    <h1>Page Not Found</h1>
                    <p>Sorry, there is nothing to see here.</p>
                    { !_.isEqual(homeUrl, "") && 
                        <Link to={homeUrl} className="nav-link pollie-links">
                            Back to Home
                        </Link>   
                    }
                    { _.isEqual(homeUrl, "") &&
                        <a className="nav-link pollie-links" href="https://www.pollie.co" target="_self" rel="noopener noreferrer">
                            Back to Home
                        </a>
                    }
                </div>
            </div>
        </div>
    );
};

export default withRouter(NotFound);