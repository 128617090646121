import {IAppointment, IMemberInfo, INote, IPlan, IProvider} from "../../types/interfaces";
import {defaultLabResults} from "../components/MemberProfiles/PCOSPlans/Constants";

export const displayUSDateFormat = "MMMM D, YYYY";

export const defaultProvider: IProvider = {
	id: "",
	first_name: "",
	last_name: "",
	bio: "",
	logo: "",
	email: "",
	video_bio: "",
	time_zone: "",
	types: []
};

export const defaultMember: IMemberInfo = {
	id: "",
	date_created: "",
	start_time: "",
	end_time: "",
	will_renew: false,
	first_name: "",
	last_name: "",
	agora_rtc_uid: "",
	agora_rtm_uid: "",
	plan_editor: false,
	time_zone: "",
	subscription_type_label: "",
};

export const defaultAppointment: IAppointment = {
	id: "",
	date_created: "",
	provider_id: "",
	member_id: "",
	start_time: "2001-01-01 01:00:00+00",
	end_time: "2001-01-01 01:00:00+00",
	canceled: false,
	cancellation_reason: "",
	call_channel_id: "",
	type: ""
};

export const defaultNote: INote = {
	id: "",
	date_created: "",
	provider_id: "",
	member_id: "",
	appointment_id: "",
	text: ""
};

export const defaultPlan: IPlan = {
	id: "",
	date_created: "",
	provider_id: "",
	member_id: "",
	version: 0,
	is_draft: false,
	life_stage_tags: [],
	symptom_tags: [],
	goal_tags: [],
	lab_results: defaultLabResults(),
	nutrition: "",
	nutrition_tags: [],
	movement: "",
	movement_tags: [],
	stress: "",
	stress_tags: [],
	other: ""
};

export const Conditions: Record<number, string> = {
	0: "Amenorrhea",
	1: "Hashimoto's",
	2: "Hypothyroidism",
	3: "Metabolic syndrome",
	4: "PCOS",
	5: "I don’t have a diagnosis and/or I am looking for general hormonal, immune, or metabolic health support",
	6: "Other"
};

export const ethnicityMap: Record<number, string> = {
	0: "Native American Indian/Alaska Native",
	1: "Asian",
	2: "Black or African American",
	3: "Native Hawaiian/other Pacific Islander",
	4: "White",
	5: "Prefer not to answer",
	6: "Other"
};

export const lifeStageMap: Record<number, string> = {
	0: "Student",
	1: "Adulthood",
	2: "Family planning",
	3: "Parenthood"
};

export const goalsMap: Record<number, string> = {
	0: "Expand toolkit",
	1: "Improve symptoms",
	2: "Learn",
	3: "Manage metabolism",
	4: "Optimize fertility",
	5: "Support long-term health",
	6: "Find balance",
	7: "Reduce use of medication"
};
