import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./Canvas.css";
import leaveCallIcon from "../../img/end-call.svg";

interface ExitProps extends RouteComponentProps {
    leave: any;
}

const ExitButton = ({ leave }: ExitProps) => {

    return (
        <div className="col">
            <Link to={process.env.PUBLIC_URL + "/providers"} 
                onClick={leave}
                className="ag-btn videoControlBtn"
                title="End call"
            >
                <img src={leaveCallIcon}/>
            </Link>
            <div className="control-button-text">
                End call
            </div>
        </div>
    );
};

export default withRouter(ExitButton);
