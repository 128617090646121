import _ from "lodash";
import { ISchedule, IScheduleException } from "../../../types/interfaces";
import { http } from "../../utils/axios-config";

import { v4 as uuidv4 } from "uuid";

// Our input is a recurring schedule and the date that we want to create the schedule exception for
// schedule, date, and is_canceled || is_rescheduled
const createScheduleException = async (recurringSchedule: ISchedule, isDeleted = false): Promise<IScheduleException> => {
    if (_.isEqual(recurringSchedule.is_recurring, false)) {
        throw new Error("Cannot create schedule exception for non-recurring schedule.");
    }
    const providerId = recurringSchedule.provider_id;

    const newScheduleException: IScheduleException = {
        id: uuidv4(),
        schedule_id: recurringSchedule.id,
        provider_id: providerId,
        is_rescheduled: !isDeleted,
        is_canceled: isDeleted,
        start_date: recurringSchedule.start_date, // TODO, this value can be null ???
        end_date: recurringSchedule.end_date,
        start_time: recurringSchedule.start_time,
        end_time: recurringSchedule.end_time
    };
    await http("post", `/providers/${providerId}/schedule-exceptions`, newScheduleException);
    return newScheduleException;
};

const updateScheduleException = async (updatedScheduleException: IScheduleException, isDeleted = false): Promise<IScheduleException> => {
    const id = updatedScheduleException.id;
    const providerId = updatedScheduleException.provider_id;
    const newScheduleException: IScheduleException = {
        id: id,
        provider_id: providerId,
        schedule_id: updatedScheduleException.schedule_id,
        is_rescheduled: updatedScheduleException.is_rescheduled,
        is_canceled: isDeleted,
        start_date: updatedScheduleException.start_date,
        end_date: updatedScheduleException.end_date,
        start_time: updatedScheduleException.start_time,
        end_time: updatedScheduleException.end_time
    };
    await http("put", `/providers/${providerId}/schedule-exceptions/${id}`, newScheduleException);
    return newScheduleException;
};

export { createScheduleException, updateScheduleException };