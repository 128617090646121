import React from "react";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
    display: block;
    margin: 0 auto;
`;

function Loading(props: any) {
    return (
        <div className="loader">
            <BeatLoader css={override}
                color={"#2B97AB"}
            />
        </div>
    );
}

export default Loading;