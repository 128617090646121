import dayjs, { Dayjs } from "dayjs";
import { dateFormat, recurDowMap, timeFormat } from "./constants";
import { Dates, Schedule, RuleOption, DayjsDateAdapter } from "../Utils/rschedule";
import { IAppointment, IMemberInfo, ISchedule, IScheduleException } from "../../../types/interfaces";
import _ from "lodash";
import dayOfYear from "dayjs/plugin/dayOfYear";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { displayMembersName } from "../Members/Utils";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);

// Current Date Time, no schedules generated before this point in time, outside of functions so only changes when page reloads
const calendarStart = dayjs().startOf("minute"); 	

// Get a dayjs object based on date and time separate strings (as that's how they are currently stored in our DB)
// Recurring schedules have undefined dates, but have times, so returns the same time, but with today's date
const getDateTime = (dateString: string | undefined, timeString: string) : Dayjs => {
	const date = dateString ?? calendarStart.format(dateFormat); // Fall back on today's date if no date string
	return dayjs(`${date} ${timeString}`, `${dateFormat} ${timeFormat}`);
};

// maps a recurring schedule (and exceptions) to an array of events generated from it
const recurranceToEvents = (schedule: ISchedule, scheduleExceptions: IScheduleException[]): any[] => {
	// Since these are recurring schedules, the dates here are just today. We are just getting a
	// dayjs object in order to extract the time info using its methods
	const recurranceStart = getDateTime(schedule.start_date, schedule.start_time);
	// Since we only store time (and not date) in the DB, conversions between UTC / local can mean the 
	// datetime recovered in recurranceEndTemp ends up being _before_ the start time... so if that's the case
	// we add a day to bring it to within 0-24hrs after the start
	const recurranceEndTemp = getDateTime(schedule.end_date, schedule.end_time);
	const recurranceEnd = (recurranceEndTemp.isBefore(recurranceStart)) ? recurranceEndTemp.add(1, 'day') : recurranceEndTemp;
	const msDuration = recurranceEnd.diff(recurranceStart);

	// A list of dates to remove from those generated using the rSchedule schedule below. Dates
	// and times must match the generated ones exactly
	const exdates = scheduleExceptions
		.filter(ex => ex.schedule_id == schedule.id && ex.is_canceled)
		.map(ex => {
			// Note the use of the start_time from the _schedule_, not the exception. This is to make sure they match
			// and that the exception remains in place if the schedule times are adjusted
			return getDateTime(ex.start_date, schedule.start_time);
		});

	const eventGenerator = new Schedule({
		rrules: [{
			// Generate schedules within a 1 year timeframe
			start: calendarStart,
			end: calendarStart.add(1, "year"),
			// Currently only support weekly recurrences, where schedule repeats on same DOW at same hour and minute	
			frequency: "WEEKLY",
			byDayOfWeek: [recurDowMap[schedule.day_of_week] as RuleOption.ByDayOfWeek],
			byHourOfDay: [recurranceStart.hour() as RuleOption.ByHourOfDay],
			byMinuteOfHour: [recurranceStart.minute() as RuleOption.ByMinuteOfHour]
		}],
		exdates
	});

	return eventGenerator
		.occurrences()
		.toArray()
		.map((event: DayjsDateAdapter) => {
			return ({
				id: schedule.id,
				start: event.date.toDate(),
				end: event.date.add(msDuration, "ms").toDate()
			});
		});
};

// schedules and exceptions are converted to an array of events. All non-recurring schedules are included
// and recurring schedules are generated to 1 year out from today
export const convertSchedulesToEvents = (schedules: ISchedule[], scheduleExceptions: IScheduleException[]): any[] => {
	return schedules.flatMap((schedule: ISchedule) => {
		if (!schedule.is_recurring) {        
			return {
				id: schedule.id,
				start: getDateTime(schedule.start_date, schedule.start_time).toDate(),
				end: getDateTime(schedule.end_date, schedule.end_time).toDate()
			};
		} else {
			return recurranceToEvents(schedule, scheduleExceptions);
		}
	});
};

export interface IEvent {
    id: string,
    title: string,
    start: Date,
    end: Date
}

export const convertAppointmentsToEvents = (appointments: IAppointment[], connectedMembers: IMemberInfo[]): IEvent[] => {
	const events = appointments.filter((appointment: IAppointment) => {
		return !appointment.canceled;
	}).map((appointment: IAppointment): IEvent => {
		const startDateTime = dayjs.utc(appointment.start_time);
		const endDateTime = dayjs.utc(appointment.end_time);
		const member = connectedMembers.find((member) => {
			return _.isEqual(member.id, appointment.member_id);
		});
		const memberName = displayMembersName(member);
		return {
			id: appointment.id,
			title: memberName,
			start: startDateTime.toDate(),
			end: endDateTime.toDate()
		};
	});
	return events;
};
