import React from "react";
import { handleInputChange } from "../../../utils/setters";

export const AccountSettingsInput = ({ label, value, dataValue, setNewData }: any) => {

    return (
        <label>
            {label}
            <input className="form-control pollie-input"
                id={value}
                value={dataValue || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(e, setNewData);
                }} />
        </label>
    );
};