import React from "react";
import { Modal, Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./style.css";

interface IUpdateAvailabilityModalProps extends RouteComponentProps {
    show: boolean,
    setAvailabilityModalShow: any
}

function UpdateAvailabilityModal({ show, setAvailabilityModalShow, history }: IUpdateAvailabilityModalProps) {

    const onHide = () => {
        setAvailabilityModalShow(false);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="modal-header"/>
            <Modal.Body>
                <div className="container">
                    <h2 className="row mr-4 ml-4">
                        Set your availability
                    </h2>
                    <div className="row mr-4 ml-4 mb-4">
                        Complete your account by setting up your appointment availability. Members can’t book appointments until you add your availability.
                    </div>
                    <div className="row button-row">
                        <Button className="form-submit-button btn-pollie-outline col col-lg-3"
                            onClick={onHide}>
                            Skip for now
                        </Button>
                        <Button className="form-submit-button btn-pollie-dark col col-lg-3"
                            onClick={() => history.push(`${process.env.PUBLIC_URL}/providers/account/availability`)}>
                                Set availability
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(UpdateAvailabilityModal);