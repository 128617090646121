import React, { Fragment, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./Pagination.css";

const RIGHT_PAGE: any = "right";
const LEFT_PAGE: any = "left";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

interface IPaginationProps extends RouteComponentProps {
    changePage: any,
    currentPage: number,
    totalPages: number
}

const Pagination = ({ changePage, currentPage, totalPages }: IPaginationProps) => {

    const [pages, setPages] = useState<number[]>([]);
    const pageNeighbors = 1;

    useEffect(() => {
        setPages(fetchPageNumbers());
    }, [totalPages]);
    
    // if (!providerCount || totalPages === 1) {
    //     return;
    // }

    const gotoPage = (page: number) => {
        const currentPage = Math.max(0, Math.min(page, totalPages));
        changePage(currentPage);
    };

    const handleMoveLeft = () => {
        gotoPage(currentPage - (pageNeighbors * 2) - 1);
    };

    const handleMoveRight = () => {
        gotoPage(currentPage + (pageNeighbors * 2) + 1);
    };

    const fetchPageNumbers = (): number[] => {
        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (pageNeighbors * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbors);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
            // handle: (1) < {5 6} [7] {8 9} (10)
            case (hasLeftSpill && !hasRightSpill): {
                const extraPages = range(startPage - spillOffset, startPage - 1);
                pages = [LEFT_PAGE, ...extraPages, ...pages];
                break;
            }

            // handle: (1) {2 3} [4] {5 6} > (10)
            case (!hasLeftSpill && hasRightSpill): {
                const extraPages = range(endPage + 1, endPage + spillOffset);
                pages = [...pages, ...extraPages, RIGHT_PAGE];
                break;
            }

            // handle: (1) < {4 5} [6] {7 8} > (10)
            case (hasLeftSpill && hasRightSpill): default: {
                pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                break;
            }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    return (
        <Fragment>
            <nav aria-label="Pagination">
                <ul className="pagination">
                    { pages.map((page, index) => {
                        if (page === LEFT_PAGE) return (
                            <li key={index} className="page-item">
                                <a className="page-link" href="#" aria-label="Previous" onClick={() => handleMoveLeft()}>
                                    <span aria-hidden="true">
                                        &laquo;
                                    </span>
                                    <span className="sr-only">
                                        Previous
                                    </span>
                                </a>
                            </li>
                        );

                        if (page === RIGHT_PAGE) return (
                            <li key={index} className="page-item">
                                <a className="page-link" href="#" aria-label="Next" onClick={() => handleMoveRight()}>
                                    <span aria-hidden="true">
                                        &raquo;
                                    </span>
                                    <span className="sr-only">
                                        Next
                                    </span>
                                </a>
                            </li>
                        );

                        return (
                            <li key={index} className={"page-item"}>
                                <a className={`page-link${ currentPage === page ? " page-link-active" : ""}`} href="#" onClick={() => gotoPage(page)}>
                                    { page }
                                </a>
                            </li>
                        );

                    })}
                </ul>
            </nav>
        </Fragment>
    );
};

export default withRouter(Pagination);