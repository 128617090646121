import React, { useEffect, Fragment, useState, useLayoutEffect } from "react";
import AgoraRTC, { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import useAgora from "./useAgora";
import MediaPlayer from "./MediaPlayer";
import "./Call.css";
import "./Canvas.css";
import { http } from "../../utils/axios-config";
import { IAppointment } from "../../../types/interfaces";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import VideoControlButton from "./VideoControlButton";
import AudioControlButton from "./AudioControlButton";
import ExitButton from "./ExitButton";

const client = AgoraRTC.createClient({ codec: "vp8", mode: "rtc" });

interface CallProps extends RouteComponentProps {
    providerId: string;
    appointmentId: string;
}

const Call = ({ providerId, appointmentId }: CallProps) => {
    const appid = "dd1c85d8b2dd402ab255a3255a533983";
    // const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
    const {
        localVideoTrack, localAudioTrack, leave, join, remoteUsers, mute

    } = useAgora(client);

    useEffect(() => {
        const getToken = async () => {
            if (_.isNil(providerId)) {
                providerId = (await http("get", "/providers", "")).id;
            }
            // Get token for call from API // might cause race condition, might need to get appointment again
            const appointment: IAppointment = await http("get", `/providers/${providerId}/appointments/${appointmentId}`, "");
            const callToken: any = await http("get", `/appointments/${appointment.id}/callToken`, "");
            join(appid, appointment.call_channel_id, callToken.token, callToken.uid);
        };
        void getToken();
    }, []);

    const leaveCall = async () => {
        await client?.unpublish();
        if (localAudioTrack) {
            await localAudioTrack.setEnabled(false);
            localAudioTrack.stop();
            localAudioTrack.close();
        }
        if (localVideoTrack) {
            await localVideoTrack.setEnabled(false);
            localVideoTrack.stop();
            localVideoTrack.close();
        }
        leave();
    };

    useLayoutEffect(() => {
        return () => {
            void leaveCall();
        };
    }, []);

    return (
        <Fragment>
            <div id="ag-canvas" className="ag-canvas">
                { _.isEmpty(remoteUsers) &&
                    <div className="video-placeholder" />
                }
                {remoteUsers.map((user: any) => { 
                    return (
                        <MediaPlayer key={user.uid} videoTrack={user.videoTrack} audioTrack={user.audioTrack}/>
                    );
                })}
                <MediaPlayer isLocal={true} videoTrack={localVideoTrack} />
            </div>
            <div className="ag-btn-group">
                <ExitButton leave={leave}/>
                <VideoControlButton mute={mute} />
                <AudioControlButton mute={mute} />
            </div>
        </Fragment>
    );
};

export default withRouter(Call);
