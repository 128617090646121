import dayjs from "dayjs";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import { IMemberAppointment, IMemberInfo, INote, IProvider } from "../../../types/interfaces";
import { http } from "../../utils/axios-config";
import { defaultAppointment, defaultMember, defaultNote } from "../../utils/constants";
import Note from "../Appointments/Note";
import "./style.css";
import pencilIcon from "../../img/pencil.svg";
import NoteModal from "./Notes/NoteModal";
import Loading from "../Loading";
import NoteFilterDropdown from "./Notes/NoteFilterDropdown";

interface INoteProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>;
    notes: INote[];
    setNotes: Dispatch<SetStateAction<INote[]>>
    updateNotes: any;
    providerId: string;
    memberId: string;
    connectedProviders: IProvider[];
}

const Notes = ({ setCurrentNav, notes, setNotes, updateNotes, providerId, memberId, connectedProviders }: INoteProps) => {
    const [appointments, setAppointments] = useState<IMemberAppointment[]>([]);
    const [member, setMember] = useState<IMemberInfo>(defaultMember);
    const [newNoteModalShow, setNewNoteModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setCurrentNav("notes");
        const getMemberAppointments = async () => {
            setIsLoading(true);
            const tempMember: IMemberInfo = await http("get", `/providers/${providerId}/members/${memberId }`, "");

            setMember(tempMember);
            const tempMemberAppointments: IMemberAppointment[] = await http("get", `/providers/${providerId}/members/${memberId}/appointments`, "");
            setAppointments(tempMemberAppointments);

            setIsLoading(false);
        };
        void getMemberAppointments();
    }, []);
    
    if (isLoading) {
        return (
          <Loading style={{height: 300}}/>
        );
    }

    return (
        <div className="col-md-6">
            <div className="row mb-3 justify-content-between">
                <div className="pollie-link edit-note-link" onClick={() => setNewNoteModalShow(true)}>
                    <img alt="pencil icon" className="mb-1 mr-2" src={pencilIcon} />
                        Create new note
                </div>
                <div>
                    <NoteFilterDropdown notes={notes} setNotes={setNotes} providers={connectedProviders}/>
                </div>
            </div>
            { notes.map((note) => {
                const isProvidersAppointment = _.isEqual(note.provider_id, providerId);
                return (
                    <Note key={note.id}
                        note={note}
                        member={member}
                        isProvidersAppointment={isProvidersAppointment}
                        updateNotes={updateNotes}
                        appointments={appointments}
                        providers={connectedProviders}/>
                );
            })}
            <NoteModal show={newNoteModalShow} 
                setShow={setNewNoteModalShow}
                note={{...defaultNote, 
                    date_created: dayjs.utc().format(),
                    provider_id: providerId
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                setNote={() => {}}
                appointment={defaultAppointment}
                member={member}
                providers={connectedProviders}
                updateNotes={updateNotes}
                isProvidersAppointment={true}/>
        </div>
    );
};

export default withRouter(Notes);
