
import React, { useState, useEffect } from "react";
import "./Call.css";
import { http } from "../../utils/axios-config";
import { IAppointment } from "../../../types/interfaces";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import Call from "./Call";
import "./Meeting.css";
import memberProfile from "../../img/member-profile.svg";
import { defaultAppointment } from "../../utils/constants";

interface MeetingProps extends RouteComponentProps {
    providerId: string;
    appointmentId: string;
}

const Meeting = ({ providerId, appointmentId }: MeetingProps) => {
    const [appointment, setAppointment] = useState(defaultAppointment);

    useEffect(() => {
        const getToken = async () => {
            if (_.isNil(providerId)) {
                providerId = (await http("get", "/providers", "")).id;
            }
            // Get token for call from API
            const appointment: IAppointment = await http("get", `/providers/${providerId}/appointments/${appointmentId}`, "");
            setAppointment(appointment);
        };
        void getToken();
    }, []);

    return (
        <div className="wrapper meeting">
            <div className="ag-main">
                <div className="ag-container">
                    <div className="row justify-content-end">
                        <Link className="btn btn-view-member mr-4" target="_blank"
                            to={process.env.PUBLIC_URL + `/providers/members/${appointment.member_id}`}>  
                            <img alt="member-profile" className="member-profile-icon" src={memberProfile} />
                            View member profile
                        </Link>
                    </div>
                    <Call appointmentId={appointmentId} providerId={providerId}/>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Meeting);
