import { Dispatch, SetStateAction } from "react";
import dayjs from "dayjs";

export const dowMap: Record<number, string> = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday"
};

export const recurDowMap: Record<number, string> = {
    0: "SU",
    1: "MO",
    2: "TU",
    3: "WE",
    4: "TH",
    5: "FR",
    6: "SA"
};

export const validDow = [0, 1, 2, 3, 4, 5, 6];
export const dateFormat =  "YYYY-MM-DD";
export const dobFormat = "MMMM D, YYYY";
export const timeFormat = "HH:mm:ssZZ";
export const modalTimeFormat = "h:mm A";
export const inputValueCheck = /^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))$/;
export const inputPriceCheck = /^\d+(?:.\d{2})?$/;
export const DEFAULT_START_TIME = "9:00 AM";
export const DEFAULT_END_TIME = "5:00 PM";

export const handleSpanChange = (e: React.ChangeEvent<HTMLInputElement>, type: any, newEvent: any, 
    setNewEvent: Dispatch<SetStateAction<any>>, 
    setStartValidationError: Dispatch<SetStateAction<string>>, 
    setEndValidationError: Dispatch<SetStateAction<string>>, 
    setOverlapValidationError: Dispatch<SetStateAction<string>>) => {
    let newStartTime = newEvent.start;
    let newEndTime = newEvent.end;
    let startTimeValid = dayjs(newStartTime, modalTimeFormat).isValid();
    let endTimeValid = dayjs(newEndTime, modalTimeFormat).isValid();

    if (e.target.value.length > 8) {
        return;
    }

    if (type === "start") { 
        newStartTime = e.target.value;
        setNewEvent({
            ...newEvent,
            start: newStartTime,
            end: newEvent.end
        });
        startTimeValid = dayjs(newStartTime, modalTimeFormat).isValid() && inputValueCheck.test(e.target.value);
        if (startTimeValid) {
            setStartValidationError("");
        } else {
            setStartValidationError("Invalid starting time.");
        }
    }
    if (type === "end") { 
        newEndTime = e.target.value;
        setNewEvent({
            ...newEvent,
            start: newEvent.start,
            end: newEndTime
        });
        endTimeValid = dayjs(newEndTime, modalTimeFormat).isValid() && inputValueCheck.test(e.target.value);
        if (endTimeValid) {
            setEndValidationError("");
        } else {
            setEndValidationError("Invalid ending time.");
        }
    }

    // Validation Checking
    if (startTimeValid && endTimeValid) {
        if (dayjs(newStartTime, modalTimeFormat).isSameOrAfter(dayjs(newEndTime, modalTimeFormat))) {
            setOverlapValidationError("Your end time cannot be before your start time.");
        } else {
            setOverlapValidationError("");
        }
    } else {
        setOverlapValidationError("");
    }
};