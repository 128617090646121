import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IMemberInfo, IPlan } from "../../../../types/interfaces";
import ViewPlan from "./ViewPlan";

interface IViewPlanModalProps extends RouteComponentProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    plan: IPlan;
    member: IMemberInfo;
}

const ViewPlanModal = ({ show, setShow, plan, member }: IViewPlanModalProps) => {

    const onHide = () => {
        setShow(false);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="note-modal-width">
            <Modal.Header closeButton className="modal-header"/>
            <Modal.Body className="modal-body">
                <ViewPlan member={member} plan={plan}/>
            </Modal.Body>
        </Modal>
    );
};

export default withRouter(ViewPlanModal);