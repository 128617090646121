import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
    Link,
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import { IAppointment, IMemberInfo, IProvider } from "../../../types/interfaces";
import MemberProfileSummary from "../Members/MemberProfileSummary";
import messagesPic from "../../img/messages.svg";
import "./style.css";

interface IOverviewProps extends RouteComponentProps {
    member: IMemberInfo,
    provider: IProvider,
    appointments: IAppointment[],
    setCurrentNav: Dispatch<SetStateAction<string>>
}

const Overview = ({ provider, member, appointments, setCurrentNav }: IOverviewProps) => {

    useEffect(() => {
        setCurrentNav("overview");
    }, []);
    
    return (
        <div className="col-md-6">
            <div className="member-profile-overview-container container col">
                <MemberProfileSummary provider={provider} member={member} appointments={appointments}/>
                <Link className="mt-4 pollie-link messages-link" to={process.env.PUBLIC_URL + `/providers/messages?member=${member.id}`}>
                    <img alt="messages-icon" className="mr-2" src={messagesPic} />
                        Go to messages
                </Link>
            </div>
        </div>
    );
};

export default withRouter(Overview);
