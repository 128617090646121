import React, {Fragment, useEffect, useState} from "react";
import {
	Switch,
	Route,
	withRouter,
	Redirect
}                                                                  from "react-router-dom";
import "../../App.css";
import Loading                                                     from "../Loading";
import Dashboard                                                   from "../Dashboard/Dashboard";
import ProviderLogin                                               from "../Login/ProviderLogin";
import ProviderSignUp                                              from "../SignUp/ProviderSignUp";
import {providerAuth0Client}                                       from "../Auth0/Auth";
import ProvidersAccount                                            from "./ProvidersAccount";
import Callback                                                    from "../Auth0/Callback";
import {useRouteMatch}                                             from "react-router-dom";
import SecuredRoute                                                from "../Auth0/SecuredRoute";
import PublicRoute                                                 from "../Auth0/PublicRoute";
import NavBar                                                      from "../NavBar";
import NotFound                                                    from "../NotFound";
import MemberProfile                                               from "../MemberProfiles/MemberProfile";
import {http}                                                      from "../../utils/axios-config";
import ResetPassword                                               from "../Auth0/ResetPassword";
import Messaging                                                   from "../Messaging";

// Temp
import dayjs                                                    from "dayjs";
import utc                                                      from "dayjs/plugin/utc";
import timezone                                                 from "dayjs/plugin/timezone";
import AppointmentCall                                          from "../Appointments/AppointmentCall";
import _                                                        from "lodash";
import MyMembers                                                from "../Members/MyMembers";
import {IMemberInfo, IProvider}                                 from "../../../types/interfaces";
import {defaultProvider}                                        from "../../utils/constants";
import Footer                                                   from "../Footer";
import useMessaging from "../Messaging/UseMessaging";

dayjs.extend(utc);
dayjs.extend(timezone);

const Providers = () => {
	const {path} = useRouteMatch();
	const [userLogo, setUserLogo] = useState("");
	const [provider, setProvider] = useState<IProvider>(defaultProvider);
	const [providerId, setProviderId] = useState("");

	const [isLoading, setIsLoading] = useState(true);
	const [connectedMembers, setConnectedMembers] = useState<Array<IMemberInfo>>([]);

	useEffect(() => {
		const checkAuth = async () => {
			try {
				if (!providerAuth0Client.isAuthenticated()) {
					await providerAuth0Client.silentAuth();
				}
				const tempProviderId: string = (await http("get", "/providers", "")).id;
				setProviderId(tempProviderId);

				const tempProvider: IProvider = await http("get", `/providers/${tempProviderId}/personal-info`, "");
				setProvider(tempProvider);
				setUserLogo(tempProvider.logo);

				const tempConnectedMembers: any[] = await http("get", `/providers/${tempProviderId}/connected-members`, "");
				tempConnectedMembers.sort((a, b) => dayjs(b.date_created).diff(dayjs(a.date_created).valueOf(), "day"));
				setConnectedMembers(tempConnectedMembers);
			} catch (err: any) {
				if (err.error !== "login_required") {
					console.log(err);
				}
			}
			setIsLoading(false);
		};
		void checkAuth();
	}, []);

	const {messageStore, sendMessage, markRead} = useMessaging(provider, connectedMembers);

	const totalUnreadMessages = messageStore.unreadMessages();

	if (isLoading || (providerAuth0Client.isAuthenticated() && _.isEmpty(providerId))) {
		return (
			<Fragment>
				<NavBar user=" " userLogo="" unreadMessages={totalUnreadMessages}/>
				<Loading style={{height: 300}}/>
			</Fragment>
		);
	}

	return (
		<div>
			<NavBar user="" userLogo={userLogo} unreadMessages={totalUnreadMessages}/>
			<Switch>
				<Route exact path={`${path}/callback`}>
					<Callback path={`${path}`}/>
				</Route>
				<SecuredRoute exact path={`${path}`}>
					<Dashboard providerId={providerId} members={connectedMembers}/>
					<Footer/>
				</SecuredRoute>
				<SecuredRoute path={`${path}/account`}>
					<ProvidersAccount providerId={providerId} setUserLogo={setUserLogo}/>
					<Footer/>
				</SecuredRoute>
				<SecuredRoute path={`${path}/members/:memberId`}>
					<MemberProfile providerId={providerId}/>
					<Footer/>
				</SecuredRoute>
				<SecuredRoute exact path={`${path}/members`}>
					<MyMembers providerId={providerId} members={connectedMembers}/>
					<Footer/>
				</SecuredRoute>
				<SecuredRoute exact path={`${path}/appointments/:appointmentId`}>
					<AppointmentCall providerId={providerId}/>
				</SecuredRoute>
				<SecuredRoute exact path={`${path}/messages`}>
					<Messaging provider={provider} sendMessage={sendMessage} markRead={markRead}
					           members={connectedMembers}
										 messageStore={messageStore}/>
				</SecuredRoute>
				<PublicRoute exact path={`${path}/signup`}>
					<ProviderSignUp/>
					<Footer/>
				</PublicRoute>
				<PublicRoute exact path={`${path}/login`}>
					<ProviderLogin/>
					<Footer/>
				</PublicRoute>
				<PublicRoute exact path={`${path}/reset-password`}>
					<ResetPassword/>
					<Footer/>
				</PublicRoute>
				{!providerAuth0Client.isAuthenticated() &&
          <Redirect to={{pathname: process.env.PUBLIC_URL + `${path}/login`}}/>
				}
				<Route>
					<NotFound homeUrl="/providers"/>
					<Footer/>
				</Route>
			</Switch>
		</div>
	);
};

export default withRouter(Providers);

