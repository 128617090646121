import React, { FormEvent, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "./style.css";
import "../../index.css";
import { providerAuth0Client } from "../Auth0/Auth";

const LogIn = (props: any) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { location } = props;
    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError("");
        if(validateForm()) {
            try {
                await providerAuth0Client.signIn(email, password, location.state?.referrer);
            } catch(err: any) {
                setError(err.description);
            }
        } else {
            setError("Please enter an email and password.");
        }
    };

    return (
        <div className="container">
            <div className="row">
                { error !== "" && 
                    <p className="form-error"> {error} </p>
                }
                <form className="w-100" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" 
                            className="form-control placeholder-opacity" 
                            id="email" 
                            aria-describedby="emailHelp" 
                            placeholder="Enter email" 
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" 
                            className="form-control placeholder-opacity" 
                            id="password" 
                            aria-describedby="passwordHelp" 
                            placeholder="Enter password" 
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <Link className="reset-link" to={`${process.env.PUBLIC_URL}/providers/reset-password`}>
                            Reset Password
                        </Link>
                    </div>
                    <button className="form-submit-button btn-pollie-dark" type="submit">
                        Log in
                    </button>
                </form>
            </div>
        </div>
    );
};

export default withRouter(LogIn);
