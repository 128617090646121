import _, { result } from "lodash";
import { IPlan } from "../../../../types/interfaces";

export const defaultLabs = [
    "CBC Panel",
    "DHEA-S",
    "Fasting Glucose",
    "Fasting Insulin",
    "Free Testosterone",
    "Hemoglobin A1C",
    "SHBG",
    "Total Testosterone",
    "Vitamin D"
];

export const additionalLabs = [
    "C-Reactive Protein",
    "Cholesterol",
    "Estradiol",
    "Ferritin",
    "Folate",
    "Free T3",
    "Free T4",
    "FSH",
    "LH",
    "Progesterone",
    "Prolactin",
    "Tg (Thyroglobulin) antibodies",
    "Total T3",
    "Total T4",
    "TPO (thyroid peroxidase) antibodies",
    "TSH",
    "Vitamin B12"
];

export interface ILab {
    name: string;
    value: number;
}

export interface INewLab extends ILab {
    id: string;
}

export const defaultLab = {
    name: "",
    value: -1,
};

export const defaultLabResults = (): ILab[] => {
    return defaultLabs.map((lab): ILab => {
        return {
            name: lab,
            value: -1,
        };
    });
};

export const labResultsToJsonb = (labResults: ILab[]): any => {
    const jsonbResults: any = {};
    for (let index = 0; index < labResults.length; index++) {
        const lab = labResults[index];
        jsonbResults[lab.name] = lab.value;
    }

    return JSON.stringify(jsonbResults);
};

export const jsonbToLabResults = (jsonb: any): ILab[] => {
    const labResults: ILab[] = [];
    for (const key in jsonb) {
        const lab: ILab = {
            name: key,
            value: jsonb[key]
        };
        labResults.push(lab);
    }
    return labResults;
};

export const convertToPlans = (resultPlans: any[]): IPlan[] => {
    const plans = [];
    for (const resultPlan of resultPlans) {
        const convertedPlan: IPlan = {
            id: resultPlan.id,
            date_created: resultPlan.date_created,
            member_id: resultPlan.member_id,
            provider_id: resultPlan.provider_id,
            version: resultPlan.version,
            is_draft: resultPlan.is_draft,
            life_stage_tags: resultPlan.life_stage_tags,
            symptom_tags: resultPlan.symptom_tags,
            goal_tags: resultPlan.goal_tags,
            lab_results: jsonbToLabResults(resultPlan.lab_results),
            nutrition: resultPlan.nutrition,
            nutrition_tags: resultPlan.nutrition_tags,
            movement: resultPlan.movement,
            movement_tags: resultPlan.movement_tags,
            stress: resultPlan.stress,
            stress_tags: resultPlan.stress_tags,
            other: resultPlan.other
        };
        plans.push(convertedPlan);
    }
    return plans;
};

// export enum LabValues {
//     high = "High", 
//     highNormal = "High Normal",
//     normal = "Normal",
//     lowNormal = "Low Normal",
//     low = "Low"
// };

export const LabValues: {[key: number]: string} = {
    4: "High", 
    3: "High Normal",
    2: "Normal",
    1: "Low Normal",
    0: "Low"
};

export const colorCodedLabs = (value: string): string => {
    if (_.isEqual(value, LabValues[4]) || _.isEqual(value, LabValues[0])) {
        return "high-labs";
    } else if (_.isEqual(value, LabValues[3]) || _.isEqual(value, LabValues[1])) {
        return "low-labs";
    } else {
        return "";
    }
};