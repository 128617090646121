import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { handleInputChange, handleChange } from "../../../utils/setters";
import defaultUserPic from "../../../img/default-user-pic.svg";
import videoBioPic from "../../../img/video-bio.svg";
import "react-datepicker/dist/react-datepicker.css";
import { RouteComponentProps, useRouteMatch, withRouter } from "react-router-dom";
import { http } from "../../../utils/axios-config";
import Loading from "../../Loading";
import { AccountSettingsEditButtons } from "./EditButtons";
import { AccountSettingsInput } from "./Input";
import { AccountSettingsText } from "./Text";
import _ from "lodash";
import "../style.css";
import { defaultProvider } from "../../../utils/constants";
import { IProvider } from "../../../../types/interfaces";
import { ProviderTypes } from "../../Utils/ProviderTypes";

interface IPersonalInfoProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>,
    providerId: string,
    setUserLogo: Dispatch<SetStateAction<string>>
}

const PersonalInfo = ({ setCurrentNav, providerId, setUserLogo }: IPersonalInfoProps ) => {
    const [editMode, setEditMode] = useState(false);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [data, setData] = useState<IProvider>(defaultProvider);
    const [newData, setNewData] = useState<IProvider>(defaultProvider);
    const [isLoading, setIsLoading] = useState(true);
    const [invalidImage, setInvalidImage] = useState("");
    const [invalidVideo, setInvalidVideo] = useState("");
    const [videoLoading, setVideoLoading] = useState(false);

    const { url } = useRouteMatch();
    const oneGb = 1 * Math.pow(1024, 3);

    useEffect(() => {
        setCurrentNav(url);
        const getData = async () => {
            setIsLoading(true);
            // const providerId: string = (await http("get", "/providers", "")).id;
            // setProviderId(providerId);
            if (_.isNil(providerId)) {
                providerId = (await http("get", "/providers", "")).id;
            }
            const providerInfo: IProvider = await http("get", `/providers/${providerId}/personal-info`, "");
            // const providerDetails = await http("get", `/providers/${providerInfo.id as string}/details`, "");
            setData({ ...providerInfo });
            setIsLoading(false);
        };
        void getData();
    }, []);

    useEffect(() => {
        const month = startDate.getMonth() + 1;
        const day = startDate.getDate();
        const year = startDate.getFullYear();
        const fmtDate = `${month.toString()}/${day.toString()}/${year.toString()}`;

        handleChange("birth_date", fmtDate, setNewData);
    }, [startDate]);

    const saveChanges = async () => {
        setIsLoading(true);
        try {
            await http("put", `/providers/${providerId}/personal-info`, newData);
            setUserLogo(newData.logo);
        } catch (err: any) {
            console.log(err);
        }
        setData(newData);
        setIsLoading(false);
    };

    const getSignedLogoRequest = async (file: any) => {
        try {
            const response = await http("get", `/providers/${providerId}/logos?file-type=${file.type as string}`, null);

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", response.signedRequest);
            xhr.onreadystatechange = () => {
                if(xhr.readyState === 4) {
                    if(xhr.status === 200) {
                        setInvalidImage("");
                        setNewData({...data, logo: response.url});
                    } else {
                        setInvalidImage("Could not upload file.");
                    }
                }
            };
            xhr.send(file);
        } catch (err: any) {
            console.log(err);
        }
    };

    const getSignedVideoRequest = async (file: any) => {
        try {
            const response = await http("get", `/providers/${providerId}/videos?file-type=${file.type as string}`, "");

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", response.signedRequest);
            xhr.onreadystatechange = () => {
                if(xhr.readyState === 4) {
                    if(xhr.status === 200) {
                        setInvalidVideo("");
                        setNewData({...data, video_bio: response.url});
                        setVideoLoading(false);
                    } else {
                        setInvalidVideo("Could not upload file.");
                        setVideoLoading(false);
                    }
                }
            };
            xhr.send(file);
        } catch (err: any) {
            console.log(err);
        }
    };

    const handleImageChange = (event: any) => {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            setInvalidImage("Please select an image");
            return false;
        }
        
        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setInvalidImage("Please select a valid image");
            return false;
        }

        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            const img = new Image();
            img.onload = () => {
                setInvalidImage("");
                void getSignedLogoRequest(imageFile);
            };
            img.onerror = () => {
                setInvalidImage("Invalid image content.");
                setVideoLoading(false);
                return false;
            };
            const result: string = e.target.result as string;
            img.src = result;
        };

        fileReader.readAsDataURL(imageFile);
    };

    const handleVideoChange = (event: any) => {
        const videoFile = event.target.files[0];

        if (!videoFile) {
            setInvalidVideo("Please select a video");
            return false;
        }
        
        if (!videoFile.name.match(/\.(3gp|3g2|asf|avi|divx|m2t|m2ts|m4v|mkv|mmv|mod|mov|mp4|mpg|mts|tod|wmv)$/)) {
            setInvalidVideo("Please select a valid video");
            return false;
        }

        // Determine whether the file is too large to upload
        if (videoFile.size > oneGb) {
            setInvalidVideo("Video size is too large, must be under 1 GB");
            return false;
        }

        setVideoLoading(true);
        const fileReader = new FileReader();

        fileReader.onload = (ev: any) => {
            // const video = new 
            const buffer = ev.target.result as ArrayBuffer;

            // We have to convert the buffer to a blob:
            const videoBlob = new Blob([new Uint8Array(buffer)], { type: "video/mp4" });

            // The blob gives us a URL to the video file:
            const url = window.URL.createObjectURL(videoBlob);
            setInvalidVideo("");
            void getSignedVideoRequest(videoFile);
        };

        fileReader.readAsArrayBuffer(videoFile);
    };

    if (isLoading) {
        return <Loading style={{height: 300}}/>;
    }

    return (
        <Fragment>
            <div className="profile-section">
                <div className="profile-section-header">
                    Photo
                </div>
                { !editMode &&
                    <div className="profile-logo">
                        <img alt="logo" className="provider-logo-image" src={data.logo ? data.logo : defaultUserPic} />
                    </div>
                }
                { editMode &&
                    <div className="file-uploader row pl-3">
                        <div className="profile-logo">
                            <img alt="logo" className="provider-logo-image" src={newData.logo ? newData.logo : defaultUserPic} />
                        </div>
                        <input type="file"
                            name="myimage"
                            id="file-upload"
                            onChange={handleImageChange} />
                        <label htmlFor="file-upload">
                            Update photo
                        </label>
                        { !_.isEqual(invalidImage, "") &&
                            <div>
                                {invalidImage}
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="profile-section">
                { !editMode && 
                    <AccountSettingsText label="Name" 
                        isComplete={data.first_name || data.last_name} 
                        dataValue={
                            <div>
                                {data.first_name &&
                                                    data.first_name
                                }
                                {data.first_name && data.last_name &&
                                                    " "
                                }
                                {data.last_name &&
                                                    data.last_name
                                }
                            </div>
                        } 
                    />}
                { editMode &&
                    <div className="row">
                        <div className="col">
                            <AccountSettingsInput label="First Name" value="first_name" dataValue={newData.first_name} setNewData={setNewData}/>
                        </div>
                        <div className="col">
                            <AccountSettingsInput label="Last Name" value="last_name" dataValue={newData.last_name} setNewData={setNewData}/>
                        </div>
                    </div>
                }
            </div>
            <div className="profile-section">
                { !editMode && 
                    <AccountSettingsText label="Bio" isComplete={data.bio} dataValue={data.bio} />
                }
                { editMode &&
                    <label>
                        Bio
                        <textarea className="form-control pollie-input" id="bio" value={newData.bio} 
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                handleInputChange(e, setNewData);
                            }} />
                    </label>
                }
            </div>
            <div className="profile-section">
                <div className="profile-section-header">
                    Video bio
                </div>
                { !editMode &&
                    <Fragment>
                        { !data.video_bio &&
                            <div className="profile-incomplete">
                                Incomplete
                            </div>
                        }
                        { data.video_bio &&
                            <Fragment>
                                <a href={data.video_bio} target="_blank" rel="noopener noreferrer" type="video">
                                    <img className="pl-1 pr-2" alt="video bio" src={videoBioPic}/>
                                    View video bio
                                </a>
                            </Fragment>
                        }
                    </Fragment>
                }
                { editMode &&
                    <Fragment>
                        <div className="file-uploader row pl-3">
                            { !videoLoading &&
                                <Fragment>
                                    <input type="file"
                                        name="myvideobio"
                                        id="video-upload"
                                        onChange={handleVideoChange} />
                                    <label htmlFor="video-upload" className="pl-0">
                                        Upload video bio
                                    </label>
                                    { !_.isEqual(invalidVideo, "") &&
                                        <div>
                                            {invalidVideo}
                                        </div>
                                    }
                                </Fragment>
                            }
                            { videoLoading &&
                              <Loading style={{height: 300}}/>
                            }
                        </div>
                        { (_.isEqual(invalidVideo, "") && !_.isEqual(data.video_bio, newData.video_bio)) &&
                            <div>
                                Video bio uploaded successfully
                            </div>
                        }
                    </Fragment>
                }
            </div>
            <AccountSettingsEditButtons editMode={editMode} 
                setEditMode={setEditMode} 
                setNewData={setNewData} 
                saveChanges={saveChanges} 
                data={data} 
                saveDisabled={invalidImage || invalidVideo || videoLoading}/>
            <div className="profile-section">
                <div className="profile-section-header">
                    Provider type
                </div>
                { (!_.isNil(data.types) && !_.isEmpty(data.types)) && data.types.map((providerType) => {
                    return (
                        <div key={providerType} className="badge badge-pill provider-type-bubble mr-2 mb-2">
                            {ProviderTypes[providerType]}
                        </div>
                    );
                })}
                { _.isNil(data.types) || _.isEmpty(data.types) &&
                    <div>
                        None specified
                    </div>
                }
                <div className="profile-section-header">
                    *Note: to update your provider type reach out to the Pollie team
                </div>
            </div>
        </Fragment >
    );
};

export default withRouter(PersonalInfo);