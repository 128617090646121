import React, { Fragment } from "react";

export const AccountSettingsEditButtons = ({ editMode, setEditMode, data, setNewData, saveChanges, saveDisabled=false }: any) => {
    return (
        <div className="profile-section">
            { !editMode &&
                <button className="btn btn-pollie-dark mt-4" 
                    onClick={() => { 
                        setEditMode(true); 
                        setNewData(data);
                    }}>
                    Edit About Information
                </button>
            }
            { editMode &&
                <Fragment>
                    <button className="btn btn-pollie-outline mt-4 mr-4" 
                        onClick={() => {
                            setEditMode(false);
                            setNewData(data);
                        }}>
                        Cancel
                    </button>
                    <button className="btn btn-pollie-dark mt-4"
                        disabled={saveDisabled}
                        onClick={() => {
                            setEditMode(false);
                            saveChanges();
                        }}>
                        Save changes
                    </button>
                </Fragment>
            }
        </div>
    );
};