import React, {Dispatch, SetStateAction} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import { IMemberInfo } from "../../../types/interfaces";
import magnifyingGlassImg from "../../img/magnifying-glass.svg";

interface ISearchMembersProps extends RouteComponentProps {
    members: IMemberInfo[],
    setSearchedMembers: Dispatch<SetStateAction<IMemberInfo[]>>,
    searchValue: string,
    setSearchValue: Dispatch<SetStateAction<string>>,
    resetPage?: any
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SearchMembers = ({ members, setSearchedMembers, searchValue, setSearchValue, resetPage = () => {} }: ISearchMembersProps) => {

    const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newSearchValue = e.currentTarget.value.toLowerCase();

        const filteredMembers = members.filter((member) => {
            const memberFirstName = member.first_name || "New";
            const memberLastName = member.last_name || "Member";
            return (memberFirstName.toLowerCase().startsWith(newSearchValue) || 
                memberLastName.toLowerCase().startsWith(newSearchValue));
        });
        setSearchedMembers(filteredMembers);
        setSearchValue(newSearchValue);
        resetPage(filteredMembers);
    };

    return (
        <div className="search-form">
            <img alt="magnifying-glass" className="mag-glass-icon" src={magnifyingGlassImg} />
            <input type="text"
                name={"memberSearch"}
                value={searchValue}
                onChange={onSearchChange}
                placeholder={"Search members"}
            />
        </div>
    );
};

export default withRouter(SearchMembers);
