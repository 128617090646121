import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";

interface IMemberInitialsBubbleProps extends RouteComponentProps {
    initials: string,
    cName: string
}

const MemberInitialsBubble = ({ initials, cName }: IMemberInitialsBubbleProps) => {

    return (
        <div className={`${cName} col-md-1`}>
            <div className="profile-initials">
                {initials}
            </div>
        </div>
    );
};

export default withRouter(MemberInitialsBubble);
