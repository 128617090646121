import React, { ReactElement } from "react";
import dayjs from "dayjs";

const timeFormat = "h:mm A";

const TimeSelector = ({ defaultValue, onChange, beginLimit, 
    endLimit, step, className, spanKey, disabled }: any): ReactElement => {

    let timeValue = beginLimit || "12:00 AM";
    let lastValue;
    const endLimitTime = endLimit || "11:59 PM";
    const stepAmount = step || 15;

    const options = [];
    options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>);

    while ( isEarlierThanEndLimit(timeValue, endLimitTime, lastValue) ) {
        lastValue = timeValue;
        timeValue = dayjs(timeValue, timeFormat).add(stepAmount, "minutes").format(timeFormat);
        options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>);
    }

    return (
        <select key={spanKey} defaultValue={defaultValue} 
            onChange={onChange} className={className}
            disabled={disabled}>
            {options}
        </select>
    );
};

const isEarlierThanEndLimit = (timeValue: string, endLimit: string, lastValue: string) => {
    const timeValueIsEarlier = dayjs(timeValue, timeFormat).diff(dayjs(endLimit, timeFormat)) < 0;
    const timeValueIsLaterThanLastValue = lastValue === undefined ? true : dayjs(lastValue, timeFormat).diff(dayjs(timeValue, timeFormat)) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
};

export default TimeSelector;