import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IInvalidLabModalProps extends RouteComponentProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    onSavePlanClick: any;
}

function InvalidLabModal({ show, setShow, onSavePlanClick }: IInvalidLabModalProps) {

    const onHide = () => {
        setShow(false);
    };

    const onSaveClick = () => {
        onSavePlanClick();
        setShow(false);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="note-modal-width"
        >
            <Modal.Header closeButton className="modal-header pb-0"/>
            <Modal.Body className="modal-body delete-modal-body">
                <div className="row ml-0">
                    You have not selected a lab range for at least one lab. Select a range or delete a lab to publish.
                </div>
                <div className="row ml-0 mr-0 mt-3 justify-content-between">
                    <div className="btn btn-pollie-dark delete-button" onClick={onHide}>
                        Go back
                    </div>
                    <div className="btn btn-pollie-outline delete-button" onClick={onSaveClick}>
                        Save draft
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default withRouter(InvalidLabModal);