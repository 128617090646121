import auth0 from "auth0-js";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { v4 } from "uuid";

class Auth {

    private auth0: any;
    private client_id: string;
    private auth0DbConnection!: string;
    private instance!: any;

    constructor(auth0ClientID: string, auth0DbConnection: string) {
        this.instance = axios.create({
            baseURL: "/api/providers"
        });

        const custom_domain: string = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN!;
        const client_id: string = auth0ClientID;
        this.client_id = client_id;
        this.auth0DbConnection = auth0DbConnection;
        this.auth0 = new auth0.WebAuth({
            domain:       custom_domain,
            audience:     process.env.REACT_APP_AUTH0_AUDIENCE,
            clientID:     client_id,
            redirectUri:  window.location.protocol + "//" + window.location.host + "/providers/callback/",
            responseType: "id_token",
            scope:        "openid profile"
        });
        // this.auth0.crossOriginVerification();

        this.getIdToken = this.getIdToken.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
        this.silentAuth = this.silentAuth.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    getIdToken() {
        const idToken = JSON.parse(localStorage.getItem("id_token") || "{}");
        return idToken;
    }

    isAuthenticated() {
        // Check that a token is present
        const idToken = localStorage.getItem("id_token");
        if (_.isNil(idToken)) {
            return false;
        }

        // Checks that this is a logged in member within the member portal and same for providers
        const identity = JSON.parse(localStorage.getItem("identity") || "{}");
        if (!_.isEqual(identity, "/providers")) {
            return false;
        }
        const expiresAt = JSON.parse(localStorage.getItem("expires_at") || "{}");
        return new Date().getTime() < expiresAt;
    }

    signIn = (email: string, password: string, referrer: string) => {
        const nonce = v4();
        if (!_.isNil(referrer)) {
            const session = {
                redirectUrl: referrer,
                expiresOn: dayjs().add(1, "hour")
            };
            localStorage.setItem(nonce, JSON.stringify(session));
        }

        return new Promise<void>((resolve, reject) => {
            this.auth0.login({
                email: email,
                password: password,
                realm: this.auth0DbConnection,
                state: nonce
            }, function(this: Auth, err: any, authResult: any) {
                if (err) {
                    return reject(err);
                }
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }
                resolve();
            });
        });
    }

    handleAuthentication() {
        return new Promise<void>((resolve, reject) => {
            this.auth0.parseHash((err: any, authResult: any) => {
                if (err) {
                    return reject(err);
                }
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }
                this.setSession(authResult);
                resolve();
            });
        });
    }

    setSession(authResult: any) {
        this.instance.defaults.headers.common["Authorization"] = `Bearer ${authResult.idToken as string}`;

        // Expiration is in seconds, and expiresAt is in milliseconds
        const expiresAt = JSON.stringify(
            authResult.idTokenPayload.exp * 1000
        );
        localStorage.setItem("id_token", JSON.stringify(authResult.idToken));
        localStorage.setItem("expires_at", JSON.stringify(expiresAt));
        localStorage.setItem("identity", JSON.stringify("/providers"));
    }

    signOut() {
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("identity");
        this.auth0.logout({
            returnTo: window.location.protocol + "//" + window.location.host + process.env.PUBLIC_URL + "/providers/login",
            clientID: this.client_id,
        });
    }

    silentAuth = async () => {
        const authResult = await this.auth0.checkSession({}, (err: any) => {
            if (err && err.error !== "login_required") {
                console.log(err);
            }
        });
        this.setSession(authResult);
    }

    resetPassword(email?: string) {
        return new Promise<string>((resolve, reject) => {
            this.auth0.changePassword({
                connection: this.auth0DbConnection,
                email: email
            }, function (err: any, resp: string) {
                if(err){
                    return reject(err);
                } else {
                    return resolve(resp);
                }
            });
        });
    }
}

const providerAuth0Client = new Auth(process.env.REACT_APP_PROVIDER_AUTH0_CLIENT_ID as string, 
    process.env.REACT_APP_PROVIDER_AUTH0_DB_CONNECTION as string);

export { providerAuth0Client };