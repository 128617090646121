import React, { Fragment, useState, useEffect } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./style.css";
import logo from "../../img/pollie-logo.png";
import mobileNavToggle from "../../img/mobile-nav-toggle.svg";
import close from "../../img/close.svg";
import defaultlogo from "../../img/defaultlogo.svg";
import _ from "lodash";
import { providerAuth0Client } from "../Auth0/Auth";

interface INavBarProps extends RouteComponentProps {
    user: any,
    userLogo: string,
    unreadMessages: number
}

const NavBar: React.FC<INavBarProps> = ({user, userLogo, location, unreadMessages}: INavBarProps) => {
	return (
		<div>
			<nav className="navbar navbar-expand-md pollie-navbar">
				<div className="d-flex w-100">
					<div className="w-100 order-1 mr-auto order-md-0 text-center text-md-left">
						{/* Mobile Left Nav - Nav menu toggle*/}
						<span className="d-inline d-md-none navbar-toggle-signed-in">
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMobilePollie" aria-controls="navbarMobilePollie" aria-expanded="false" aria-label="Toggle navigation">
								<img className="mobile-nav-toggle-image" alt="Toggle" src={mobileNavToggle} />
							</button>
						</span>
						{/* All Left Nav */}
						<span className="navbar-brand m-0 ml-md-5">
							{((!providerAuth0Client.isAuthenticated())) &&
                                <a href="https://www.pollie.co" target="_self" rel="noopener noreferrer">
                                	<img alt="Pollie" src={logo} className="pollie-logo" />
                                </a>
							}
							{(providerAuth0Client.isAuthenticated()) &&
                                <Link to={`${process.env.PUBLIC_URL}/providers`}>
                                	<img alt="Pollie" src={logo} className="pollie-logo" />
                                </Link>
							}
						</span>
						{/* Mobile Right Nav */}
						{(providerAuth0Client.isAuthenticated()) &&
                            <span className="d-inline d-md-none navbar-mobile-signed-in">
                            	<div className="profile-image-mobile">
                            		<Link className="" to={process.env.PUBLIC_URL + "/providers/account/personalInfo"}>
                            			{user &&
                                            <div className="profile-initials">
                                            	{user}
                                            </div>
                            			}
                            			{userLogo && 
                                            <img alt="profile" className="navbar-logo-image" src={userLogo}/>
                            			}
                            			{!userLogo && user==="" && 
                                            <img alt="profile" className="navbar-logo-image" src={defaultlogo}/>
                            			}
                            		</Link>
                            	</div>
                            	{/* <div className="notifications-mobile">
                                    <Link className="" to={process.env.PUBLIC_URL + "/providers/notifications"}>
                                        <img alt="profile" src={bell} />
                                    </Link>
                                </div> */}
                            </span>
						}
					</div>

					{/* Desktop nav */}
					<div className="collapse navbar-collapse mr-md-5" id="navbarSupportedContent">
						{(!providerAuth0Client.isAuthenticated()) && 
                            <Fragment>
                            	<Link to={process.env.PUBLIC_URL + "/providers/login"}>
                            		<button type="button" className="btn btn-pollie-outline mr-4" >Log In</button>
                            	</Link>
                            	<Link to={process.env.PUBLIC_URL + "/providers/signup"}>
                            		<button type="button" className="btn btn-pollie-dark" >Sign Up</button>
                            	</Link>
                            </Fragment>
						}
						{(providerAuth0Client.isAuthenticated()) &&
                            <Fragment>
                            	<ul className="navbar-nav mr-4">
                            		<li className="nav-item loggedin mr-2 mt-1">
                            			<Link className={location.pathname === process.env.PUBLIC_URL || location.pathname === `${process.env.PUBLIC_URL}/providers` ? "nav-link bold pollie-underline" : "nav-link"} to={`${process.env.PUBLIC_URL}/providers`}>
                            				{"Home"}
                            			</Link>
                            		</li>
                            		<li className="nav-item loggedin mr-2 ml-4 mt-1">
                            			<Link className={location.pathname === process.env.PUBLIC_URL || location.pathname.includes(`${process.env.PUBLIC_URL}/providers/members`) ? "nav-link bold pollie-underline" : "nav-link"} to={`${process.env.PUBLIC_URL}/providers/members`}>
                            				{"Members"}
                            			</Link>
                            		</li>
                            		<li className="nav-item loggedin mr-2 ml-4 mt-1">
                            			<Link className={location.pathname === process.env.PUBLIC_URL || location.pathname.includes(`${process.env.PUBLIC_URL}/providers/messages`) ? "nav-link bold pollie-underline" : "nav-link"} to={`${process.env.PUBLIC_URL}/providers/messages`}>
                                            Messages 
                            			</Link>
                            		</li>
                            		<li>
                            			{unreadMessages != 0 &&
                                            <Link className="nav-new-messages mt-1" to={`${process.env.PUBLIC_URL}/providers/messages`}>
                                            	{(unreadMessages > 0 && unreadMessages <= 10) 
                                                    && unreadMessages}
                                            	{(unreadMessages > 10) 
                                                    && "10+"}
                                            </Link>
                            			}
                            		</li>
                            		{/* <li className="nav-item loggedin ml-3">
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + "/providers/notifications"}>
                                            <img alt="profile" src={bell} />
                                        </Link>
                                    </li> */}
                            		<li className="nav-item loggedin ml-4">
                            			<Link className="nav-link" to={process.env.PUBLIC_URL + "/providers/account/personalInfo"}>
                            				{user &&
                                                <div className="profile-initials">
                                                	{user}
                                                </div>
                            				}
                            				{userLogo && 
                                                <img alt="profile" className="navbar-logo-image" src={userLogo}/>
                            				}
                            				{!userLogo && user==="" && 
                                                <img alt="profile" className="navbar-logo-image" src={defaultlogo}/>
                            				}
                            			</Link>
                            		</li>
                            	</ul>
                            </Fragment>
						}
					</div>
				</div>
			</nav>

			{/* Mobile toggle nav */}
			<div className="collapse navbar-collapse no-transition mobile-nav d-md-none" id="navbarMobilePollie">
				<button className="navbar-toggler mobile-nav-close" type="button" data-toggle="collapse" data-target="#navbarMobilePollie" aria-controls="navbarMobilePollie" aria-expanded="false" aria-label="Toggle navigation">
					<img className="mobile-nav-close-image" alt="Toggle" src={close} />
				</button>
				<ul className="navbar-nav mr-auto mt-5">
					{(providerAuth0Client.isAuthenticated()) && 
                        <li className="nav-item mb-2" data-toggle="collapse" data-target="#navbarMobilePollie">
                        	<Link className={_.isEqual(location.pathname, `${process.env.PUBLIC_URL}/providers`) ? "nav-link-mobile nav-link bold" : "nav-link-mobile nav-link"} to={`${process.env.PUBLIC_URL}/providers`}>
                        		{"Home"}
                        	</Link>
                        </li>
					}
					{(providerAuth0Client.isAuthenticated()) && 
                        <li className="nav-item mb-2" data-toggle="collapse" data-target="#navbarMobilePollie">
                        	<Link className={_.isEqual(location.pathname, `${process.env.PUBLIC_URL}/providers/members`) ? "nav-link-mobile nav-link bold" : "nav-link-mobile nav-link"} to={`${process.env.PUBLIC_URL}/providers/members`}>
                        		{"Members"}
                        	</Link>
                        </li>
					}
					{(providerAuth0Client.isAuthenticated()) && 
                        <li className="nav-item mb-2" data-toggle="collapse" data-target="#navbarMobilePollie">
                        	<Link className={_.isEqual(location.pathname, `${process.env.PUBLIC_URL}/providers/messages`) ? "nav-link-mobile nav-link bold" : "nav-link-mobile nav-link"} to={`${process.env.PUBLIC_URL}/providers/messages`}>
                        		{"Messages"}
                        	</Link>
                        </li>
					}
					{(providerAuth0Client.isAuthenticated()) && 
                        <li className="nav-item mb-2" data-toggle="collapse" data-target="#navbarMobilePollie">
                        	<Link className={location.pathname.includes(`${process.env.PUBLIC_URL}/providers/account`) ? "nav-link-mobile nav-link bold" : "nav-link-mobile nav-link"} to={`${process.env.PUBLIC_URL}/providers/account/personalInfo`}>
                                Account
                        	</Link>
                        </li>
					}
				</ul>
			</div>
		</div>
	);
};

export default withRouter(NavBar);