import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import { providerAuth0Client } from "./Auth";

const SecuredRoute = ({...routeProps}: RouteProps) => {
    if(providerAuth0Client.isAuthenticated()) {
        return <Route {...routeProps} />;
    } else {
        const redirectLocation = window.location.pathname;
        return <Redirect to={{
            pathname: `${process.env.PUBLIC_URL}/providers/login`,
            state: { referrer: redirectLocation }
        }} />;
    }
};

export default SecuredRoute;