import React from "react";
import { Modal, Button } from "react-bootstrap";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import { fullDateFormat, shownTimeFormat } from "./constants";
import { IAppointment } from "../../../types/interfaces";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

function AppointmentModal({ show, onHide, appointment, cancelAppointment }: any) {

    const appointmentStartDateTime = dayjs.utc(appointment.start_time).local();
    // const appointmentEndDateTime = dayjs.utc(appointment.end_time).local();

    const appointmentDate = appointmentStartDateTime.format(fullDateFormat);
    const appointmentStartTime = appointmentStartDateTime.format(shownTimeFormat);

    const canceledAppointment: IAppointment = {
        ...appointment,
        canceled: true
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="modal-width"
        >
            <Modal.Header closeButton className="modal-header"/>
            <Modal.Body className="modal-body">
                <div className="container">
                    <p>Are you sure you want to cancel your appointment with {appointment.member_name} on {appointmentDate} at {appointmentStartTime}?</p>
                    <div className="row justify-content-lg-center">
                        <Button className="btn btn-pollie-outline mt-4 mr-4" onClick={onHide}>Go back</Button>
                        <Button className="btn btn-pollie-dark mt-4" 
                            onClick={async () => {
                                await cancelAppointment(canceledAppointment);
                                onHide();
                            }} >Cancel visit</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AppointmentModal;