import { ILocalVideoTrack, IRemoteVideoTrack, 
    ILocalAudioTrack, IRemoteAudioTrack } from "agora-rtc-sdk-ng";
import React, { useRef, useEffect } from "react";

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack?: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  isLocal?: boolean;
}

const MediaPlayer = ({ isLocal = false, videoTrack, audioTrack }: VideoPlayerProps) => {
    const container = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!container.current) {
            return;
        }
        videoTrack?.play(container.current);
        return () => {
            videoTrack?.stop();
            if (isLocal) {
                (videoTrack as ILocalVideoTrack)?.close();
            }
        };
    }, [container, videoTrack]);

    useEffect(() => {
        audioTrack?.play();
        return () => {
            audioTrack?.stop();
            if (isLocal) {
                (audioTrack as ILocalAudioTrack)?.close();
            }
        };
    }, [audioTrack]);

    return (
        <div ref={container} className="video-player ag-item" style={{ width: "48%", height: "30rem"}}></div>
    );
};

export default MediaPlayer;