// External
import * as React                        from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {CSSProperties, Fragment}         from "react";
import _                                 from "lodash";
import dayjs                             from "dayjs";

// Internal
import "./Message.css";
import {IMemberInfo}                     from "../../../types/interfaces";
import WarningIcon                       from "../../img/warning.svg";
import {displayMembersName}              from "../Members/Utils";
import Loading                           from "../Loading";

export interface IMessage {
	id: string;
	senderId: string;
	receiverId: string;
	content: string;
	createdAt: string;
	updatedAt: string;
	readAt: string | null;
	isLoading: boolean,
	sentSuccessfully: boolean
}

interface IMessageProps extends RouteComponentProps {
	providerId: string;
	message: IMessage;
	member: IMemberInfo;
	displayMessageHeader: boolean;
}

const todayDateTimeFormat = "[Today] h:mm A";
const yesterdayDateTimeFormat = "[Yesterday] h:mm A";
const beforeTodayDateTimeFormat = "MMM D, h:mm A";
const beforeCurrentYearDateTimeFormat = "MMM D, YYYY h:mm A";

/**
 * A single instant message bubble
 * @param providerId
 * @param message
 * @param member
 * @param displayMessageHeader
 * @constructor
 */
const Message = ({providerId, message, member, displayMessageHeader}: IMessageProps) => {
	const [showMessageTimestamp, setShowMessageTimestamp] = React.useState(false);

	const messageContainerStyle: CSSProperties = {
		float: _.isEqual(providerId, message.senderId) ? "right" : "left",
	};

	const messageStyle: CSSProperties = {
		float: _.isEqual(providerId, message.senderId) ? "right" : "left",
		fontWeight: 300,
		fontSize: 18,
		letterSpacing: "0.04em"
	};

	const dateCreated = dayjs(message.createdAt);

	let shownDate = dateCreated.format(beforeCurrentYearDateTimeFormat);
	if (dateCreated.isSame(dayjs(), "day")) {
		shownDate = dateCreated.format(todayDateTimeFormat);
	} else if (dateCreated.isSame(dayjs().subtract(1, "day"), "day")) {
		shownDate = dateCreated.format(yesterdayDateTimeFormat);
	} else if (dateCreated.isSame(dayjs(), "year")) {
		shownDate = dateCreated.format(beforeTodayDateTimeFormat);
	}


	function isURL(str: any) {
		const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
		return !!pattern.test(str);
	}

	function detectURLs(mess: any) {
		const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
		return mess.match(urlRegex);
	}

	const checkUrl = (content: any) => {
		const check = isURL(content);
		if (check) {
			const url = detectURLs(content);
			window.open(url[0], "_blank");
		} else {
			setShowMessageTimestamp(!showMessageTimestamp);
		}
	};

	
	return (
		<div className="message-group container" style={messageContainerStyle}>
			{/* Message sent from provider/self */}
			{_.isEqual(providerId, message.senderId) &&
        <Fragment>
        	{displayMessageHeader &&
            <div className="row timestamp mt-3">
            	{shownDate}
            </div>
        	}
        	<div className="row justify-content-end">
        		<button
        			id={message.id}
        			className="message provider-message"
	            style={messageStyle}
        			onClick={() => checkUrl(message.content)}
        			onFocus={() => setShowMessageTimestamp(true)}
        			onBlur={() => setShowMessageTimestamp(false)}>
        			{message.content}
        		</button>

	          {/* Error icon for failed messages */}
	          {(!message.sentSuccessfully && !message.isLoading) &&
              <img alt="warning icon" className="mb-1 mr-2" src={WarningIcon} />
        		}
	          {message.isLoading &&
		          <div className={""} style={{width: 60}}>
		          	<Loading style={{height: 300}}/>
		          </div>
        		}
        	</div>

	        {/* Error icon for failed messages*/}
	        {(!message.sentSuccessfully && !message.isLoading) &&
            <p
            	className={"row justify-content-end fw-light mr-1"}
            	style={{color: "red", fontSize: 16, fontWeight: "300", letterSpacing: "0.04em"}}>
              Not delivered
            </p>
	        }


        	{!showMessageTimestamp &&
            <div className="row focus-message-placeholder"/>
        	}
        	{showMessageTimestamp &&
            <div className="row justify-content-end">
            	<div className="focus-message-timestamp" style={messageStyle}>
            		{shownDate}
            	</div>
            </div>
        	}
        </Fragment>
			}
			{/* Message received from member */}
			{_.isEqual(providerId, message.receiverId) &&
        <div>
        	{displayMessageHeader &&
            <Fragment>
            	<div className="row timestamp mt-3">
            		{shownDate}
            	</div>
            	<div className="row">
            		<div className="bold">
            			{displayMembersName(member)}
            		</div>
            	</div>
            </Fragment>
        	}
        	<div className="row justify-content-start">
        		<button onClick={() => checkUrl(message.content)} id={message.id} className="message member-message" style={messageStyle}
        			onFocus={() => setShowMessageTimestamp(true)}
        			onBlur={() => setShowMessageTimestamp(false)}>
        			{message.content}
        		</button>
        	</div>
        	{!showMessageTimestamp &&
            <div className="row focus-message-placeholder"/>
        	}
        	{showMessageTimestamp &&
            <div className="row justify-content-start">
            	<div className="focus-message-timestamp" style={messageStyle}>
            		{shownDate}
            	</div>
            </div>
        	}
        </div>
			}
		</div>
	);
};

export default withRouter(Message);