export const titleCase = (str: string) => {
    const splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
};

export const validateArrLength = (length: number, sign: string, choices?: string[]) => {
    if (sign === "<=") {
        if (choices !== undefined && choices.length > length) {
            return false;
        }
    }
    if (sign === "===") {
        if (choices !== undefined && choices.length !== length) {
            return false;
        }
    }
    return true;
};

export const parseHash = (inputString: string) => {
    return inputString.substr(1)
        .split("&")
        .map(v => v.split("="))
        .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );
};
