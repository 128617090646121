import React, { Fragment, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./Canvas.css";
import audioOffIcon from "../../img/audio-disabled.svg";
import audioOnIcon from "../../img/audio-enabled.svg";

interface AudioControlProps extends RouteComponentProps {
    mute: any;
}

const AudioControlButton = ({ mute }: AudioControlProps) => {
    const [muted, setMuted] = useState(true);

    return (
        <div className="col">
            { !muted &&
                <Fragment>
                    <div onClick={() => {
                        mute("audio");
                        setMuted(!muted);
                    }}
                    className="ag-btn"
                    title="Disable Audio">
                        <img src={audioOnIcon}/>
                    </div>
                    <div className="control-button-text">
                        Mute
                    </div>
                </Fragment>
            }
            { muted &&
                <Fragment>
                    <div onClick={() => {
                        mute("audio");
                        setMuted(!muted);
                    }}
                    className="ag-btn"
                    title="Enable Audio"
                    >
                        <img src={audioOffIcon}/>
                    </div>
                    <div className="control-button-text">
                        Unmute
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default withRouter(AudioControlButton);
