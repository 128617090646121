import axios from "axios";
import _ from "lodash";
import { providerAuth0Client } from "../components/Auth0/Auth";

const instance = async () => {
    let token;
    try {
        if (!providerAuth0Client.isAuthenticated()) {
            if (process.env.REACT_APP_POLLIE_ENV === "dev") {
                console.log("User no longer is authenticated.");
            }
            await providerAuth0Client.silentAuth();
        }
        token = await providerAuth0Client.getIdToken();

        if (process.env.REACT_APP_POLLIE_ENV === "dev") {
            console.log(token);
        }

    } catch (err: any) {
        if (err.error !== "login_required") {
            console.log(err);
        }
    }
    return axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            "Authorization": `Bearer ${token as string}`,
            "Content-Type": "application/json"
        }
    });
};

export const http = async (method: string, route: string, body?: any): Promise<any> => {
    const http = await instance();
    if (method === "get") {
        const response = await http.get(route);
        return response.data;
    }
    if (method === "put") {
        const response = await http.put(route, body);
        return response.data;
    }
    if (method === "post") {
        const response = !_.isNil(body) ? await http.post(route, body) : await http.post(route);
        return response.data;
    }
    if (method === "delete") {
        const response = await http.delete(route);
        return response.data;
    }
};

export const axiosPut = async (route: string, body: string): Promise<any> => {
    const http = await instance();
    const response = await http.put(route, body);
    return response.data;
};
