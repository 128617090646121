import React from "react";
import "./Checkmark.css";

const Checkmark = () => {
    return (
        <span className="checkmark">
            <div className="checkmark_stem" />
            <div className="checkmark_kick" />
        </span>
    );
};

export default Checkmark;



