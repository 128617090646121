import _ from "lodash";
import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IMemberInfo, IPlan } from "../../../../types/interfaces";
import { colorCodedLabs, ILab, LabValues } from "./Constants";
import PlanModalHeader from "./PlanModalHeader";
import "./style.css";
import { nutritionTagMap, exerciseTagMap, stressTagMap } from "../../../utils/maps";
import DisplayTags from "./DisplayTags";

interface IViewPlanProps extends RouteComponentProps {
    member: IMemberInfo;
    plan: IPlan;
    createNewPlan?: any;
    allowNewDraft?: boolean;
}

const ViewPlan = ({member, plan, createNewPlan, allowNewDraft=false}: IViewPlanProps) => {
    const labResults: ILab[] = plan.lab_results;
    return (
        <Fragment>
            <PlanModalHeader member={member} plan={plan} createNewPlan={createNewPlan} allowNewDraft={allowNewDraft}/>
            <div className="bold mt-3">
                Lab Results
            </div>
            <div className="row ml-1 mr-1">
                { labResults.map((labResult) => {
                    return (
                        <Fragment key={labResult.name}>
                            <div className="col-3 mt-3 mb-3">
                                <div className="row justify-content-between mr-0 ml-0">
                                    <div className="lab-name">
                                        {labResult.name}
                                    </div>
                                </div>
                                <div className={colorCodedLabs(LabValues[labResult.value])}>
                                    {LabValues[labResult.value]}
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <div className="pcos-plan-category-container">
                <div className="bold ml-1 mr-1 mt-2">
                    Nutrition
                </div>
                <div className="pcos-plan-text">
                    {plan.nutrition}
                </div>
                <DisplayTags tags={plan.nutrition_tags} tagMap={nutritionTagMap}/>
            </div>
            <div className="pcos-plan-category-container">
                <div className="bold ml-1">
                    Movement
                </div>
                <div className="pcos-plan-text">
                    {plan.movement}
                </div>
                <DisplayTags tags={plan.movement_tags} tagMap={exerciseTagMap}/>
            </div>
            <div className="pcos-plan-category-container">
                <div className="bold ml-1">
                Stress Management
                </div>
                <div className="pcos-plan-text">
                    {plan.stress}
                </div>
                <DisplayTags tags={plan.stress_tags} tagMap={stressTagMap}/>
            </div>
            { !_.isEmpty(plan.other) &&
                <div className="pcos-plan-category-container">
                    <div className="bold ml-1">
                            Other Recommendations
                    </div>
                    <div className="pcos-plan-text">
                        {plan.other}
                    </div>
                </div>
            }
        </Fragment>
    );
};

export default withRouter(ViewPlan);
