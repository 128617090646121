import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { v4 } from "uuid";
import { IPlan } from "../../../../types/interfaces";
import deleteIcon from "../../../img/delete-icon.svg";
import plusIcon from "../../../img/plus-icon.svg";
import { exerciseTagMap, nutritionTagMap, stressTagMap } from "../../../utils/maps";
import { onDeleteTagClick } from "../../Members/Utils";
import { defaultLab, defaultLabResults, ILab, INewLab } from "./Constants";
import DeleteLabModal from "./DeleteLabModal";
import DisplayTags from "./DisplayTags";
import InvalidLabModal from "./InvalidLabModal";
import InvalidTextAreaModal from "./InvalidTextAreaModal";
import LabDropdown from "./LabDropdown";
import LabNameDropdown from "./LabNameDropdown";
import "./style.css";
import TagDropdown from "./TagDropdown";

interface IEditLabResultsProps extends RouteComponentProps {
    publishDraft: any;
    plan: IPlan;
    deleteDraft: any;
    saveDraft: any;
    lifeStageTags: any; 
    symptomsTags: any;
    goalTags: any;
}

const EditLabResults = ({publishDraft, saveDraft, deleteDraft, plan, lifeStageTags, symptomsTags, goalTags}: IEditLabResultsProps) => {
    const [labResults, setLabResults] = useState<ILab[]>(defaultLabResults);
    const [newLabResults, setNewLabResults] = useState<INewLab[]>([]);
    const [nutritionText, setNutritionText] = useState("");
    const [movementText, setMovementText] = useState("");
    const [stressText, setStressText] = useState("");
    const [otherText, setOtherText] = useState("");
    const [nutritionTags, setNutritionTags] = useState<number[]>([]);
    const [movementTags, setMovementTags] = useState<number[]>([]);
    const [stressTags, setStressTags] = useState<number[]>([]);
    const [isAddNutrition, setIsAddNutrition] = useState(false);
    const [isAddMovement, setIsAddMovement] = useState(false);
    const [isAddStress, setIsAddStress] = useState(false);

    const [labErrorShow, setLabErrorShow] = useState(false);
    const [textAreaErrorShow , setTextAreaErrorShow] = useState(false);

    useEffect(() => {
        console.log(plan);
        setLabResults(plan.lab_results);
        setNutritionText(plan.nutrition);
        setNutritionTags(plan.nutrition_tags || []);
        setMovementText(plan.movement);
        setMovementTags(plan.movement_tags || []);
        setStressText(plan.stress);
        setStressTags(plan.stress_tags || []);
        setOtherText(plan.other);
    }, [plan, plan.id]);

    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteNewShow, setDeleteNewShow] = useState(false);
    const [deleteLabName, setDeleteLabName] = useState("");

    const onAddLabClick = () => {
        setNewLabResults([...newLabResults, {
            ...defaultLab,
            id: v4()
        }]);
    };

    const updateLabResults = (name: string, value: number) => {
        const tempLabResults = labResults.map((lab) => {
            if (!_.isEqual(name, lab.name)) {
                return lab;
            } else {
                const newLab = {
                    ...lab,
                    value: value
                };
                return newLab;
            }
        });

        setLabResults(tempLabResults);
    };

    const updateNewLabResults = (id: string, value: number) => {
        const tempLabResults = newLabResults.map((lab) => {
            if (!_.isEqual(id, lab.id)) {
                return lab;
            } else {
                return {
                    ...lab,
                    value: value
                };
            }
        });
        setNewLabResults(tempLabResults);
    };

    const updateNewLabResultName = (id: string, name: string) => {
        const tempLabResults = newLabResults.map((lab) => {
            if (!_.isEqual(id, lab.id)) {
                return lab;
            } else {
                return {
                    ...lab,
                    name: name
                };
            }
        });
        setNewLabResults(tempLabResults);
    };

    const onDeleteLab = () => {
        const tempLabResults = labResults.filter((lab) => {
            return !_.isEqual(deleteLabName, lab.name);
        });
        setLabResults(tempLabResults);
    };

    const onDeleteNewLab = () => {
        const tempLabResults = newLabResults.filter((lab) => {
            return !_.isEqual(deleteLabName, lab.id);
        });
        setNewLabResults(tempLabResults);
    };

    const onDeleteClick = (labName: string) => {
        setDeleteLabName(labName);
        setDeleteShow(true);
    };

    const onSavePlanClick = () => {
        const finalizedDraft: IPlan = {
            ...plan,
            life_stage_tags: lifeStageTags,
            symptom_tags: symptomsTags,
            goal_tags: goalTags,
            lab_results: [...labResults, ...newLabResults],
            nutrition: nutritionText,
            nutrition_tags: nutritionTags,
            movement: movementText,
            movement_tags: movementTags,
            stress: stressText,
            stress_tags: stressTags,
            other: otherText
        };
        saveDraft(finalizedDraft);
    };

    const onDeletePlanClick = () => {
        deleteDraft();
    };

    const onPublishClick = () => {
        if (!labValidation()) {
            setLabErrorShow(true);
        } else if (!textAreaValidation()) {
            setTextAreaErrorShow(true);
        } else {
            const finalizedDraft: IPlan = {
                ...plan,
                life_stage_tags: lifeStageTags,
                symptom_tags: symptomsTags,
                goal_tags: goalTags,
                lab_results: [...labResults, ...newLabResults],
                nutrition: nutritionText,
                nutrition_tags: nutritionTags,
                movement: movementText,
                movement_tags: movementTags,
                stress: stressText,
                stress_tags: stressTags,
                other: otherText
            };
            publishDraft(finalizedDraft);
        }
    };

    const labValidation = () => {
        for (const lab of labResults) {
            if (_.isEqual(lab.value, -1)) {
                return false;
            }
        }
        for (const lab of newLabResults) {
            if (_.isEqual(lab.value, -1)) {
                return false;
            }
        }
        return true;
    };

    const textAreaValidation = () => {
        if (_.isEmpty(nutritionText) || _.isEmpty(movementText) || _.isEmpty(stressText)) {
            return false;
        }
        return true;
    };

    const onUpdateNutrition = (tagKey: number) => {
        setNutritionTags([...nutritionTags, tagKey]);
        setIsAddNutrition(false);
    };

    const onUpdateMovement = (tagKey: number) => {
        setMovementTags([...movementTags, tagKey]);
        setIsAddMovement(false);
    };

    const onUpdateStress = (tagKey: number) => {
        setStressTags([...stressTags, tagKey]);
        setIsAddStress(false);
    };

    return (
        <Fragment>
            <div className="bold mt-3">
                Lab Results
            </div>
            <div className="row ml-1 mr-1">
                { labResults.map((labResult) => {
                    return (
                        <Fragment key={labResult.name}>
                            <div className="col-3 mt-3 mb-3">
                                <div className="row justify-content-between mr-0 ml-0">
                                    <div className="lab-name">
                                        {labResult.name}
                                    </div>
                                    <img alt="delete" src={deleteIcon} className="delete-icon" onClick={() => onDeleteClick(labResult.name)}/>
                                </div>
                                <div>
                                    <LabDropdown updateLabResults={updateLabResults} labResult={labResult} />
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
                { newLabResults.map((labResult) => {
                    return (
                        <div className="col-3 mt-3 mb-3" key={labResult.id}>
                            <div className="row m-0 justify-content-between">
                                <LabNameDropdown updateLabName={updateNewLabResultName} labResult={labResult}/>
                                <img alt="delete" src={deleteIcon} className="delete-icon" onClick={() => onDeleteClick(labResult.id)}/>
                            </div>
                            <div>
                                <LabDropdown updateLabResults={updateNewLabResults} labResult={labResult} isNew={true}/>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="row ml-0 add-lab-container" onClick={onAddLabClick}>
                <img alt="add" src={plusIcon} />
                <div className="pollie-link add-lab">
                    Add lab result
                </div>
            </div>
            <div className="bold ml-1 mr-1 mt-2">
                Nutrition
            </div>
            <textarea className="pcos-plan-text" value={nutritionText} onChange={(e) => setNutritionText(e.target.value)}/>
            <DisplayTags tags={nutritionTags} tagMap={nutritionTagMap} isEdit={true}
                onDeleteClick={(tag: number) => onDeleteTagClick(tag, nutritionTags, setNutritionTags)}/>
            <div className="row ml-0 mr-0 add-lab-container">
                <div className="row ml-0 mr-0 add-lab-container" onClick={() => setIsAddNutrition(true)}>
                    <img alt="add" src={plusIcon} />
                    <div className="pollie-link add-lab">
                        Nutrition tag
                    </div>
                </div>
                { isAddNutrition &&
                    <TagDropdown updateTags={onUpdateNutrition} tagMap={nutritionTagMap}/>
                }
            </div>
            <div className="bold ml-1">
                Movement
            </div>
            <textarea className="pcos-plan-text" value={movementText} onChange={(e) => setMovementText(e.target.value)}/>
            <DisplayTags tags={movementTags} tagMap={exerciseTagMap} isEdit={true}
                onDeleteClick={(tag: number) => onDeleteTagClick(tag, movementTags, setMovementTags)}/>
            <div className="row ml-0 mr-0 add-lab-container">
                <div className="row ml-0 mr-0 add-lab-container" onClick={() => setIsAddMovement(true)}>
                    <img alt="add" src={plusIcon} />
                    <div className="pollie-link add-lab">
                        Movement tag
                    </div>
                </div>
                { isAddMovement &&
                    <TagDropdown updateTags={onUpdateMovement} tagMap={exerciseTagMap}/>
                }
            </div>
            <div className="bold ml-1">
                Stress Management
            </div>
            <textarea className="pcos-plan-text" value={stressText} onChange={(e) => setStressText(e.target.value)}/>
            <DisplayTags tags={stressTags} tagMap={stressTagMap} isEdit={true}
                onDeleteClick={(tag: number) => onDeleteTagClick(tag, stressTags, setStressTags)}/>
            <div className="row ml-0 mr-0 add-lab-container">
                <div className="row ml-0 mr-0 add-lab-container" onClick={() => setIsAddStress(true)}>
                    <img alt="add" src={plusIcon} />
                    <div className="pollie-link add-lab">
                        Stress tag
                    </div>
                </div>
                { isAddStress &&
                    <TagDropdown updateTags={onUpdateStress} tagMap={stressTagMap}/>
                }
            </div>
            <div className="bold ml-1">
                Other Recommendations
            </div>
            <textarea className="pcos-plan-text" value={otherText} onChange={(e) => setOtherText(e.target.value)}/>
            <div className="row justify-content-between mt-3">
                <div className="col">
                    <div className="btn btn-pollie-outline delete-text" onClick={onDeletePlanClick}>
                        <img alt="delete" src={deleteIcon} className="delete-icon mr-2 mb-1"/>
                        Delete
                    </div>
                </div>
                <div className="col">
                    <div className="row justify-content-end mr-0">
                        <div className="btn btn-pollie-light-outline mr-3" onClick={onSavePlanClick}>
                            Save draft
                        </div>
                        <div className="btn btn-pollie-dark" onClick={onPublishClick}>
                            Publish plan
                        </div>
                    </div>
                </div>
            </div>
            <InvalidTextAreaModal show={textAreaErrorShow} setShow={setTextAreaErrorShow} onSavePlanClick={onSavePlanClick} />
            <InvalidLabModal show={labErrorShow} setShow={setLabErrorShow} onSavePlanClick={onSavePlanClick} />
            <DeleteLabModal show={deleteShow} setShow={setDeleteShow} onDeleteLab={onDeleteLab}/>
            <DeleteLabModal show={deleteNewShow} setShow={setDeleteNewShow} onDeleteLab={onDeleteNewLab}/>
        </Fragment>
    );
};

export default withRouter(EditLabResults);