import dayjs from "dayjs";
import _ from "lodash";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom";
import { IMemberInfo, IPlan, IProvider } from "../../../types/interfaces";
import { http } from "../../utils/axios-config";
import { defaultPlan, defaultProvider } from "../../utils/constants";
import { displayUSDateFormat } from "../../utils/constants";
import Loading from "../Loading";
import { convertToPlans, labResultsToJsonb } from "./PCOSPlans/Constants";
import EditDraftModal from "./PCOSPlans/EditDraftModal";
import ViewPlan from "./PCOSPlans/ViewPlan";
import ViewPlanModal from "./PCOSPlans/ViewPlanModal";
import "./style.css";

interface IPCOSPlanProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>;
    providerId: string;
    member: IMemberInfo;
    connectedProviders: IProvider[];
}

const PCOSPlan = ({ setCurrentNav, providerId, member, connectedProviders }: IPCOSPlanProps) => {
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [draftPlan, setDraftPlan] = useState<IPlan>(defaultPlan);
    const [currentPlan, setCurrentPlan] = useState<IPlan>(defaultPlan);
    const [isPlanEditor, setIsPlanEditor] = useState(false);
    const [planModalShow, setPlanModalShow] = useState(false);
    const [viewPlanModalShow, setViewPlanModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [previousPlan, setPreviousPlan] = useState<IPlan>(defaultPlan);
    const [lifeStageTags, setLifeStageTags] = useState([]);
    const [symptomsTags, setSymptomsTags] = useState([]);
    const [goalTags, setGoalTags] = useState([]);

    // const isPlanEditor = member.plan_editor;
    useEffect(() => {
        const getPcosPlans = async () => {
            setIsLoading(true);
            setCurrentNav("pcos-plan");
            setIsPlanEditor(member.plan_editor);
            const tempPcosPlansResults: any = await http("get", `/providers/${providerId}/members/${member.id}/plans`, "");

            const tempPcosPlans: IPlan[] = convertToPlans(tempPcosPlansResults);
            setPlans(tempPcosPlans);

            const tempDraftPlan = tempPcosPlans.find((plan) => {
                return plan.is_draft;
            });
            if (!_.isNil(tempDraftPlan)){
                setDraftPlan(tempDraftPlan);
                if (tempDraftPlan.version > 0) {
                    const tempCurrentPlan = tempPcosPlans.find((plan) => {
                        return _.isEqual(plan.version, tempDraftPlan.version - 1);
                    });
                    setCurrentPlan(tempCurrentPlan || defaultPlan);
                }
            } else if (tempPcosPlans.length > 0) {
                const tempCurrentPlan = tempPcosPlans.find((plan) => {
                    return _.isEqual(plan.version, tempPcosPlans.length - 1);
                });
                setCurrentPlan(tempCurrentPlan || defaultPlan);
            }
            setIsLoading(false);
        };
        void getPcosPlans();
    }, []);

    const createNewPlan = async () => {
        setPlanModalShow(true);
        const postDraft = {
            ...currentPlan,
            lab_results: labResultsToJsonb(currentPlan.lab_results)
        };
        const newDraftResult = await http("post", `/providers/${providerId}/members/${member.id}/plans`, postDraft);
        const newDraft = {
            ...newDraftResult,
            lab_results: currentPlan.lab_results
        };
        setDraftPlan(newDraft);
        const newPlans = [...plans];
        newPlans.push(newDraft);
        setPlans(newPlans);
    };

    const editDraft = () => {
        setPlanModalShow(true);
    };

    const deleteDraft = async () => {
        await http("delete", `/providers/${providerId}/members/${member.id}/plans/${draftPlan.id}`, "");
        removePlan(draftPlan.id);
        setDraftPlan(defaultPlan);
        setPlanModalShow(false);
    };

    const saveDraft = async (savedDraft: IPlan) => {
        const putDraft = {
            ...savedDraft,
            lab_results: labResultsToJsonb(savedDraft.lab_results)
        };
        await http("put", `/providers/${providerId}/members/${member.id}/plans/${draftPlan.id}`, putDraft);
        updatePlans(savedDraft);
        setDraftPlan(savedDraft);
        setPlanModalShow(false);
    };

    const updatePlans = (updatedPlan: IPlan) => {
        const newPlans = plans.map((plan) => {
            if (_.isEqual(plan.id, updatedPlan.id)) {
                return updatedPlan;
            } else {
                return plan;
            }
        });
        setPlans(newPlans);
    };

    const removePlan = (planId: string) => {
        const newPlans = plans.filter((plan) => {
            return !_.isEqual(plan.id, planId);
        });
        setPlans(newPlans);
    };

    const publishDraft = async (finalizedDraft: IPlan) => {
        const tempDraftPlan = {
            ...finalizedDraft,
            is_draft: false
        };
        const putDraft = {
            ...tempDraftPlan,
            lab_results: labResultsToJsonb(tempDraftPlan.lab_results)
        };
        await http("put", `/providers/${providerId}/members/${member.id}/plans/${draftPlan.id}`, putDraft);
        setDraftPlan(defaultPlan);
        setCurrentPlan(tempDraftPlan);
        updatePlans(tempDraftPlan);
        setPlanModalShow(false);
    };

    const previousPlanClick = (previousPlanClicked: IPlan): void => {
        setPreviousPlan(previousPlanClicked);
        setViewPlanModalShow(true);
    };

    if (isLoading) {
        return <Loading style={{height: 300}}/>;
    }
    
    return (
        <div className="col-md-8">
            <div className="container col">
                {/* No plans currently exist, including draft */}
                { (_.isEmpty(plans) && _.isEmpty(draftPlan.id)) &&
                    <Fragment>
                        <div className="bold">
                            There are no plans to view.
                        </div>
                        { isPlanEditor &&
                            <div className="btn btn-pollie-dark mt-3" onClick={createNewPlan}>
                                Create plan
                            </div>
                        }
                    </Fragment>
                }
                {/* Show draft plan if exists */}
                { !_.isEmpty(draftPlan.id) &&
                    <Fragment>
                        <div className="bold">
                            Unpublished Draft Care Plan
                        </div>
                        <div className="draft-container row mt-3 ml-0 mr-0 mb-4">
                            <div className="col p-0">
                                <div>
                                    {`Care Plan v${draftPlan.version + 1} DRAFT`}
                                </div>
                                <div className="plan-date">
                                    {dayjs(draftPlan.date_created).format(displayUSDateFormat)}
                                </div>
                            </div>
                            <div className="col-2 btn btn-pollie-light-outline edit-plan-button p-1"
                                onClick={editDraft}>
                                Edit plan
                            </div>
                        </div>
                    </Fragment>
                }
                {/* Show current plan */}
                {  !_.isEmpty(currentPlan.id) &&
                    <Fragment>
                        <div className="bold">
                            Current Plan - {dayjs(currentPlan.date_created).format(displayUSDateFormat)}

                        </div>
                        <div className="current-plan-container mb-4">
                            <ViewPlan plan={currentPlan} member={member} createNewPlan={createNewPlan} 
                                allowNewDraft={isPlanEditor && _.isEmpty(draftPlan.id)}/>
                        </div>
                    </Fragment>
                }
                {/* Show the rest of plans, not drafts */}
                { ((!_.isEmpty(draftPlan.id) && plans.length > 2) || (_.isEmpty(draftPlan.id) && plans.length > 1)) &&
                    <Fragment>
                        <div className="bold">
                            Previous Plans
                        </div>
                        { plans.filter((plan) => {
                            return ((!_.isEmpty(draftPlan.id) && !_.isEqual(plan.version, plans.length - 1) && !_.isEqual(plan.version, plans.length - 2)) ||
                                (_.isEmpty(draftPlan.id) && !_.isEqual(plan.version, plans.length - 1)));
                        }).map((plan) => {
                            const provider = connectedProviders.find((provider) => {
                                return _.isEqual(provider.id, plan.provider_id);
                            }) || defaultProvider;
                            const providerName = `${provider.first_name} ${provider.last_name}`;
                            return (
                                <div key={plan.version}>
                                    <div className="draft-container row mt-3 ml-0 mr-0 mb-4">
                                        <div className="col p-0">
                                            <div>
                                                {`Care Plan v${plan.version + 1}`}
                                            </div>
                                            <div className="plan-date">
                                                {dayjs(plan.date_created).format(displayUSDateFormat)}
                                            </div>
                                            <div>
                                                {providerName}
                                            </div>
                                        </div>
                                        <div className="col-2 btn btn-pollie-light-outline edit-plan-button p-1"
                                            onClick={() => previousPlanClick(plan)}>
                                                View plan
                                        </div>
                                    </div>
                                </div>
                            );
                        })

                        }                    
                    </Fragment>
                }
                <ViewPlanModal show={viewPlanModalShow} setShow={setViewPlanModalShow} plan={previousPlan} member={member}/>
                <EditDraftModal show={planModalShow} setShow={setPlanModalShow} draftPlan={draftPlan}
                    deleteDraft={deleteDraft} saveDraft={saveDraft} publishDraft={publishDraft} member={member}
                    lifeStageTags={lifeStageTags} symptomsTags={symptomsTags} goalTags={goalTags}
                    setLifeStageTags={setLifeStageTags} setSymptomsTags={setSymptomsTags} setGoalTags={setGoalTags}/>
            </div>
        </div>
    );
};

export default withRouter(PCOSPlan);
