import _ from "lodash";
import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {parseHash} from "../../utils/misc";
import ErrorState from "../Utils/ErrorState";
import {providerAuth0Client} from "./Auth";

const Callback = (props: any) => {
    const {history, path, location} = props;
    const [error, setError] = useState("");

    useEffect(() => {
        const handleCallback = async () => {
            try {
                await providerAuth0Client.handleAuthentication();
                const parsedLocationHash: any = parseHash(location.hash);
                const referrerState: any = JSON.parse(localStorage.getItem(parsedLocationHash.state) as string);
                if (!_.isNil(referrerState?.redirectUrl)) {
                    history.replace(referrerState.redirectUrl);
                    history.go();
                } else {
                    history.replace(path);
                    history.go();
                }
            } catch (err: any) {
                console.error(JSON.stringify(err));
                setError("Sorry! We had a problem logging you in. Please email hello@pollie.co");
            }
        };
        void handleCallback();
    }, []);

    return (
        <div>
            {error !== "" &&
              <ErrorState error={error}/>
            }
        </div>
    );
};

export default withRouter(Callback);