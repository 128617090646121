import React, {useEffect, useState} from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {http} from "../../utils/axios-config";
import "./style.css";
import _ from "lodash";
import {IAppointment, IMemberInfo, IProvider} from "../../../types/interfaces";
import MemberSubscriptionLabel from "./MemberSubscriptionLabel";
import MemberInitialsBubble from "./MemberInitialsBubble";
import {calculateMemberWeek, displayMembersInitials, trimMemberName} from "./Utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import SearchMembers from "./SearchMembers";
import Pagination from "../Utils/Pagination";
import MemberFilterDropdown from "./MemberFilterDropdown";
import MemberProfileSummary from "./MemberProfileSummary";
import {defaultMember, defaultProvider} from "../../utils/constants";

dayjs.extend(timezone);
dayjs.extend(utc);

interface IMyMembersProps extends RouteComponentProps {
	members: IMemberInfo[],
	providerId: string
}

const MyMembers = ({providerId, members}: IMyMembersProps) => {
	const [selectedMember, setSelectedMember] = useState<IMemberInfo>(defaultMember);

	const [provider, setProvider] = useState<IProvider>(defaultProvider);
	const [appointments, setAppointments] = useState<IAppointment[]>([]);
	const [selectedAppointments, setSelectedAppointments] = useState<IAppointment[]>([]);
	const [searchValue, setSearchValue] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [memberCount, setMemberCount] = useState(0);

	// Order of priority is:
	// Members: All members
	// Filtered members: subset of all members
	// Searched members: subset of filtered members
	// Current members: page of subset of searched members
	const [filteredMembers, setFilteredMembers] = useState<IMemberInfo[]>([]);
	const [searchedMembers, setSearchedMembers] = useState<IMemberInfo[]>([]);
	const [currentMembers, setCurrentMembers] = useState<IMemberInfo[]>([]);


	useEffect(() => {
		const getData = async (): Promise<void> => {
			try {
				const providerInfo: IProvider = await http("get", `/providers/${providerId}/personal-info`, "");
				setProvider({...providerInfo});

				// Get the provider's upcoming appointments
				let tempAppointments: IAppointment[] = await http("get", `/providers/${providerId}/appointments`, "");
				tempAppointments = tempAppointments.filter((appointment) => {
					const currentTime = dayjs().tz(providerInfo.time_zone);
					const endTime = dayjs(appointment.end_time).tz(providerInfo.time_zone);
					return currentTime.isBefore(endTime, "minutes") && !appointment.canceled;
				});
				setAppointments(tempAppointments);

				setFilteredMembers(members);
				setSearchedMembers(members);
				setCurrentMembers(members.slice(0, 10));
				setMemberCount(members.length);
				setTotalPages(Math.ceil(members.length / 10));
			} catch (err: any) {
				console.log(err);
			}
		};
		void getData();
	}, []);

	const memberSelectedClicked = (member: IMemberInfo) => {
		setSelectedMember(member);
		const currentMembersAppointments = appointments.filter((appointment) => {
			return _.isEqual(appointment.member_id, member.id);
		});
		setSelectedAppointments(currentMembersAppointments);
	};

	const changePage = (newPage = 1) => {
		const from = (newPage - 1) * 10;
		const to = newPage * 10;

		setCurrentMembers(searchedMembers.slice(from, to));
		setCurrentPage(newPage);
	};

	const resetPageWithFilter = (newFilteredMembers: IMemberInfo[]) => {

		const newSearchedMembers = newFilteredMembers.filter((member) => {
			const memberFirstName = member.first_name || "New";
			const memberLastName = member.last_name || "Member";
			return (memberFirstName.startsWith(searchValue) || memberLastName.startsWith(searchValue));
		});
		setSearchedMembers(newSearchedMembers);

		setMemberCount(newSearchedMembers.length);
		setTotalPages(Math.ceil(newSearchedMembers.length / 10));
		setCurrentMembers(newSearchedMembers.slice(0, 10));
		setCurrentPage(1);
	};

	const resetPageWithSearch = (newSearchedMembers: IMemberInfo[]) => {
		setMemberCount(newSearchedMembers.length);
		setTotalPages(Math.ceil(newSearchedMembers.length / 10));
		setCurrentMembers(newSearchedMembers.slice(0, 10));
		setCurrentPage(1);
	};

	return (
		<div className="container mb-5">
			<div className="row justify-content-md-end">
				<div className="col-md-4">
					<div className="row mb-3 ml-1">
						<h1>
							My Members
						</h1>
					</div>
					<div className="row">
						<div className="col-md-9">
							<SearchMembers members={filteredMembers} setSearchedMembers={setSearchedMembers}
							               searchValue={searchValue} setSearchValue={setSearchValue} resetPage={resetPageWithSearch}/>
						</div>
						<div className="col-md-3">
							<MemberFilterDropdown members={members} setFilteredMembers={setFilteredMembers}
							                      resetPage={resetPageWithFilter} appointments={appointments}/>
						</div>
					</div>
				</div>
				<div className="col-md-7"/>
			</div>
			<div className="row justify-content-md-end">
				<div className="col-md-4 pt-2 pr-4 pl-4 pb-3">
					{currentMembers.map((member) => {
						const memberInitials = displayMembersInitials(member);
						const memberName = trimMemberName(member);

						return (
							<div
								className={_.isEqual(member.id, selectedMember.id) ? "row my-members-row-selected p-2 mb-2" : "row my-members-row p-2 mb-2"}
								key={member.id} onClick={() => memberSelectedClicked(member)}>
								<MemberInitialsBubble cName={"pl-0"} initials={memberInitials}/>
								<div className="col-md-8 pr-0">
									{memberName}
								</div>
								<MemberSubscriptionLabel className="col-md-3" member={member}/>
							</div>
						);
					})}
					<Pagination
						changePage={changePage}
						currentPage={currentPage}
						totalPages={totalPages}/>
				</div>
				<div className="col-md-7">
					{!_.isEmpty(selectedMember.id) &&
            <div className="member-profile-container container col">
              <MemberProfileSummary provider={provider} member={selectedMember} appointments={selectedAppointments}/>
              <div className="mt-4">
                <Link className="col-md-3 dashboard-btn-dark p-2 mt-2"
                      to={process.env.PUBLIC_URL + `/providers/members/${selectedMember.id}`}>
                  View Profile
                </Link>
              </div>
            </div>
					}
				</div>
			</div>
		</div>
	);
};

export default withRouter(MyMembers);
