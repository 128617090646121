import Message, {IMessage}               from "./Message";
import {RouteComponentProps, withRouter} from "react-router-dom";
import React, {Fragment, useEffect}      from "react";
import "./ChatArea.css";
import _                                 from "lodash";
import dayjs, {Dayjs}                    from "dayjs";
import {IMemberInfo}                     from "../../../types/interfaces";

interface IChatAreaProps extends RouteComponentProps {
	messages: Array<IMessage>;
	providerId: string;
	member: IMemberInfo;
	updateScroll: any;
}

const ChatArea = ({messages, providerId, member, updateScroll}: IChatAreaProps) => {
	let displayMessageHeader = true;
	let isProviderPrevMessage: boolean;
	let referenceTimestamp: Dayjs;

	useEffect(() => {
		updateScroll();
	}, []);

	useEffect(() => {
		updateScroll();
	}, [messages.length]);

	return (
		<div id="chat-area" className="chat-area w-100">
			{messages.map((message: IMessage, index: number) => {
				const isProviderCurrentMessage = _.isEqual(message.senderId, providerId);
				const currentMessageTimestamp = dayjs(message.createdAt);
				if (_.isEqual(index, 0)) {
					referenceTimestamp = currentMessageTimestamp;
					isProviderPrevMessage = isProviderCurrentMessage;
				} else {
					if (_.isEqual(isProviderPrevMessage, isProviderCurrentMessage)) {
						if (currentMessageTimestamp.diff(referenceTimestamp, "minutes") >= 5) {
							displayMessageHeader = true;
							referenceTimestamp = currentMessageTimestamp;
						} else {
							displayMessageHeader = false;
						}
					} else {
						isProviderPrevMessage = isProviderCurrentMessage;
						displayMessageHeader = true;
						referenceTimestamp = currentMessageTimestamp;
					}
				}

				return (
					<Fragment key={index}>
						<Message
							message={message}
							providerId={providerId}
							member={member}
							displayMessageHeader={displayMessageHeader}/>
					</Fragment>
				);
			})}
		</div>
	);
};

export default withRouter(ChatArea);
