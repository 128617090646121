// MemberFilter
// Component essentially has the same purpose as SearchMembers, but to limit the scope when 
// refactoring (so as to limit the impact of refactors to just the messaging subsystem) this 
// duplicative (and simplified) component was created. 
//
// Ideally the other use(s) of SearchMembers should be replaced with MemberFilter at an appropriate opportunity

import React, {Dispatch, SetStateAction} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./MemberFilter.css";
import magnifyingGlassImg from "../../img/magnifying-glass.svg";

interface ISearchMembersProps extends RouteComponentProps {
    searchValue: string,
    setSearchValue: Dispatch<SetStateAction<string>>,
}

const MemberFilter = ({searchValue, setSearchValue}: ISearchMembersProps) => {

	const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
		const newSearchValue = e.currentTarget.value.toLowerCase();
		setSearchValue(newSearchValue);
	};

	return (
		<div className="search-form">
			<img alt="magnifying-glass" className="mag-glass-icon" src={magnifyingGlassImg} />
			<input type="text"
				name={"memberSearch"}
				value={searchValue}
				onChange={onSearchChange}
				placeholder={"Search members"}
			/>
		</div>
	);
};

export default withRouter(MemberFilter);
