import dayjs from "dayjs";
import { fullDateFormat, shownTimeFormat } from "../../../Appointments/constants";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const longAppointmentScheduleFormat = (startDateTime: string, endDateTime: string) => {
    const startDateTimeDayjs = dayjs(startDateTime);
    const endDateTimeDayjs = dayjs(endDateTime);
    return `${startDateTimeDayjs.format(fullDateFormat)} ${startDateTimeDayjs.format(shownTimeFormat)} - ${endDateTimeDayjs.format(shownTimeFormat + " z")}`;
};