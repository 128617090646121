import { useState, useEffect } from "react";
import AgoraRTC, {
    IAgoraRTCClient, IAgoraRTCRemoteUser, MicrophoneAudioTrackInitConfig, ILocalVideoTrack, ILocalAudioTrack } from "agora-rtc-sdk-ng";

const defaultAudioConfig: MicrophoneAudioTrackInitConfig = {
    AGC: true,
    AEC: true
};

export default function useAgora(client: IAgoraRTCClient | undefined)
  :
   {
      localAudioTrack: ILocalAudioTrack | undefined,
      localVideoTrack: ILocalVideoTrack | undefined,
      joinState: boolean,
      leave: any,
      join: any,
      remoteUsers: IAgoraRTCRemoteUser[],
      mute: any,
    }
{
    const [localVideoTrack, setLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
    const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);

    const [joinState, setJoinState] = useState(false);

    const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

    const [audioPublished, setAudioPublished] = useState(false);
    const [videoPublished, setVideoPublished] = useState(false);
    const [audioEnabled, setAudioEnabled] = useState(false);
    const [videoEnabled, setVideoEnabled] = useState(false);


    async function join(appid: string, channel: string, token?: string, uid?: string | number | null) {
        if (!client) return;

        await client.join(appid, channel, token || null, 0);

        (window as any).client = client;
        setJoinState(true);
    }

    async function leave() {
        if (localAudioTrack) {
            await localAudioTrack.setEnabled(false);
            await client?.unpublish([localAudioTrack]);
            setAudioPublished(false);
            localAudioTrack.stop();
            localAudioTrack.close();
        }
        if (localVideoTrack) {
            await localVideoTrack.setEnabled(false);
            await client?.unpublish([localVideoTrack]);
            setVideoPublished(false);
            localVideoTrack.stop();
            localVideoTrack.close();
        }
        setRemoteUsers([]);
        setJoinState(false);
        await client?.leave();
    }

    const mute = async (type: "audio" | "video") => {
        if (type === "audio") {
            if (!audioPublished) {
                const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(defaultAudioConfig);
                setLocalAudioTrack(microphoneTrack);
                await client?.publish([microphoneTrack]);
                setAudioPublished(true);
                setAudioEnabled(true);
            } else {
                if (!localAudioTrack) {
                    return;
                }
                const newAudioEnabled = !audioEnabled;
                await localAudioTrack.setEnabled(newAudioEnabled);
                setAudioPublished(newAudioEnabled);
                setAudioEnabled(newAudioEnabled);
                if (!newAudioEnabled) {
                    await client?.unpublish([localAudioTrack]);
                    localAudioTrack.stop();
                    localAudioTrack.close();
                }
            }
        } else if (type === "video") {
            if (!videoPublished) {
                const cameraTrack = await AgoraRTC.createCameraVideoTrack();
                setLocalVideoTrack(cameraTrack);
                await client?.publish([cameraTrack]);
                setVideoPublished(true);
                setVideoEnabled(true);
            } else {
                if (!localVideoTrack) {
                    return;
                }
                const newVideoEnabled = !videoEnabled;
                await localVideoTrack.setEnabled(newVideoEnabled);
                setVideoPublished(newVideoEnabled);
                setVideoEnabled(newVideoEnabled);
                if (!newVideoEnabled) {
                    await client?.unpublish([localVideoTrack]);
                    localVideoTrack.stop();
                    localVideoTrack.close();
                }
            }
        }
    };

    useEffect(() => {
        if (!client) return;
        setRemoteUsers(client.remoteUsers);

        const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: "audio" | "video") => {
            await client.subscribe(user, mediaType);
            // toggle rerender while state of remoteUsers changed.
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);
        client.on("user-joined", handleUserJoined);
        client.on("user-left", handleUserLeft);

        return () => {
            client.off("user-published", handleUserPublished);
            client.off("user-unpublished", handleUserUnpublished);
            client.off("user-joined", handleUserJoined);
            client.off("user-left", handleUserLeft);
        };
    }, [client]);

    return {
        localAudioTrack,
        localVideoTrack,
        joinState,
        leave,
        join,
        remoteUsers,
        mute,
    };
}