import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import {
    RouteComponentProps,
    useRouteMatch,
    withRouter
} from "react-router-dom";
import Loading from "../../Loading";
import _ from "lodash";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import { IAppointment, IMemberInfo } from "../../../../types/interfaces";
import { http } from "../../../utils/axios-config";
import { convertAppointmentsToEvents, IEvent } from "../../Availability/Calendar";
import "./Calendar/calendar.css";
import { defaultAppointment, defaultMember } from "../../../utils/constants";
import AppointmentViewModal from "./Calendar/AppointmentViewModal";
import AppointmentModal from "../../Appointments/AppointmentModal";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

interface ICalendarProps extends RouteComponentProps {
    setCurrentNav: Dispatch<SetStateAction<string>>;
    providerId: string;
}

const Calendar = ({ setCurrentNav, providerId }: ICalendarProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [appointmentEvents, setAppointmentEvents] = useState<IEvent[]>([]);
    const [selectedAppointment, setSelectedAppointment] = useState(defaultAppointment);
    const [appointmentModalShow, setAppointmentModalShow] = useState(false);
    const [appointmentViewModalShow, setAppointmentViewModalShow] = useState(false);
    const [connectedMembers, setConnectedMembers] = useState<IMemberInfo[]>([]);
    const [selectedMember, setSelectedMember] = useState(defaultMember);
    const [appointmentCancellation, setAppointmentCancellation] = useState(defaultAppointment);
    const [isVisitCancelable, setIsVisitCancelable] = useState(false);
    
    const { url } = useRouteMatch();

    useEffect(() => {
        setCurrentNav(url);
        const getData = async () => {
            setIsLoading(true);
            const tempAppointments: IAppointment[] = await http("get", `/providers/${providerId}/appointments`, "");
            setAppointments(tempAppointments);
            const tempConnectedMembers: any[] = await http("get", `/providers/${providerId}/connected-members`, "");
            setConnectedMembers(tempConnectedMembers);
            setAppointmentEvents(convertAppointmentsToEvents(tempAppointments, tempConnectedMembers));
            setIsLoading(false);
        };
        void getData();
    }, []);


    const renderEventContent = (eventInfo: { timeText: string, event: {title: string} }) => {
        return (
            <div className="pollie-appointment-item">
                {eventInfo.timeText.replaceAll(" ", "")}, {eventInfo.event.title}
            </div>
        );
    };

    // Date Header
    const renderDayCellContent = (dayCellInfo: { isToday: boolean, dayNumberText: string }) => {
        return (
            <Fragment>
                { dayCellInfo.isToday &&
                    <div className="calendar-date">
                        Today
                    </div>
                }
                { !dayCellInfo.isToday &&
                    <div className="calendar-date">
                        {dayCellInfo.dayNumberText}
                    </div>
                }
            </Fragment>
        );
    };

    const cancelAppointment = async (canceledAppointment: IAppointment) => {
        try {
            setIsLoading(true);
            await http("put", `/appointments/${canceledAppointment.id}`, canceledAppointment);
            const tempAppointments = appointments.filter((appointment: IAppointment) => {
                return canceledAppointment.id !== appointment.id;
            });
            setAppointments(tempAppointments);
            setAppointmentEvents(convertAppointmentsToEvents(tempAppointments, connectedMembers));
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return (
          <Loading style={{height: 300}}/>
        );
    }

    return (
        <div>
            <div className="profile-section appointment-calendar">
                <FullCalendar
                    eventColor="#1e6a76"
                    displayEventEnd={true}
                    eventDisplay="list-item"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    dayCellContent={renderDayCellContent}
                    eventContent={renderEventContent}
                    height="auto"
                    showNonCurrentDates={true}
                    fixedWeekCount={false}
                    monthMode={true}
                    initialView="dayGridMonth"
                    selectable={false}
                    unselectAuto={true}
                    dayPopoverFormat={{ weekday: "short", day: "numeric"}}
                    events={appointmentEvents}
                    dayMaxEvents={3}
                    eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        omitZeroMinute: false,
                        meridiem: false
                    }}
                    moreLinkClassNames="pollie-link"
                    eventClassNames="appointment-event"
                    eventClick={(info) => {
                        const eventId = info.event.id;
                        const tempSelectedAppointment: IAppointment = appointments.find((appointment) => {
                            return _.isEqual(appointment.id, eventId);
                        }) || defaultAppointment;
                        const member = connectedMembers.find((member) => {
                            return _.isEqual(member.id, tempSelectedAppointment.member_id);
                        }) || defaultMember;
                        setSelectedMember(member);
                        setSelectedAppointment(tempSelectedAppointment);
                        const isAppointmentFuture = dayjs.utc(tempSelectedAppointment.start_time)
                            .isSameOrAfter(dayjs.utc());
                        setIsVisitCancelable(isAppointmentFuture);
                        setAppointmentViewModalShow(true);
                    }}
                />
            </div>
            <AppointmentViewModal show={appointmentViewModalShow} 
                setShow={setAppointmentViewModalShow} 
                member={selectedMember} 
                appointment={selectedAppointment}
                setAppointmentModalShow={setAppointmentModalShow}
                setAppointmentCancellation={setAppointmentCancellation}
                isVisitCancelable={isVisitCancelable}/>
            <AppointmentModal show={appointmentModalShow} 
                setAppointmentModalShow={setAppointmentModalShow}
                appointment={appointmentCancellation} 
                cancelAppointment={cancelAppointment}
                onHide={() => {
                    setAppointmentModalShow(false);
                }} />
        </div>
    );
};

export default withRouter(Calendar);