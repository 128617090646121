
export const specialtiesMap: Record<string, string> = {
    "ACNE": "Acne",
    "BLOOD_SUGAR_REGULATION": "Blood sugar regulation",
    "BRAIN_FOG": "Brain fog",
    "CHRONIC_PAIN": "Chronic pain",
    "ENDOMETRIOSIS": "Endometriosis",
    "FATIGUE": "Fatigue",
    "FIBRIOIDS": "Fibroids/cysts",
    "HORMONAL_HEALTH": "General hormonal health",
    "WOMENS_HEALTH": "General women's health",
    "GUT_HEALTH": "Gut health",
    "HAIR_LOSS": "Hair loss",
    "HASHIMOTOS": "Hashimoto's",
    "HIRSUTISM": "Hirsutism",
    "HYPOTHALAMIC_AMENORRHEA": "Hypothalamic amenorrhea",
    "CONVENTIONAL_FERTILITY": "Infertility (conventional fertility)",
    "FUNCTIONAL_FERTILITY": "Infertility (functional fertility)",
    "INSOMNIA": "Insomnia",
    "MOOD_DISORDERS": "Mood disorders",
    "THYROID": "Other thyroid conditions",
    "PCOS": "PCOS",
    "PELVIC_PAIN": "Pelvic pain",
    "PMS": "PMS",
    "POSTPARTUM": "Post-partum",
    "PREGNANCY": "Pregnancy",
    "WEIGHT_LOSS": "Weight loss"
};

export const shortSpecialtiesMap: Record<string, string> = {
    "ACNE": specialtiesMap["ACNE"],
    "FATIGUE": specialtiesMap["FATIGUE"],
    "HORMONAL_HEALTH": specialtiesMap["HORMONAL_HEALTH"],
    "WOMENS_HEALTH": specialtiesMap["WOMENS_HEALTH"],
    "GUT_HEALTH": specialtiesMap["GUT_HEALTH"],
    "PCOS": specialtiesMap["PCOS"]
};

export const treatmentsMap: Record<string, string> = {
    "ACUPUNCTURE": "Acupuncture",
    "ANTI_INFLAMMATORY_DIET": "Anti-inflammatory diet (e.g., gluten-/dairy-free)",
    "HOME_LABS": "At-home labs",
    "DUTCH_TEST": "DUTCH Test",
    "ENERGY_WORK": "Energy work",
    "ENVIRONMENT_DETOXES": "Environment detoxes",
    "FERTILITY_AWARENESS_METHOD": "Fertility awareness methods",
    "FOOD_SENSITIVITY_TESTING": "Food sensitivity testing",
    "GENETIC_TESTING": "Genetic testing",
    "GENTLE_EXERCISE": "Gentle exercise (e.g., yoga, walking)",
    "HAES": "HAES (\"Health At Every Size\")",
    "HERBAL_SUPPLEMENTS": "Herbal supplements",
    "HIGH_INTENSITY_EXERCISE": "High intensity exercise",
    "INTUITIVE_EATING": "Intuitive eating",
    "KETO": "Keto / low-carb lifestyle",
    "MEDICATION": "Medication (e.g., Metformin, hormonal birth control)",
    "MICROBIOME_TESTING": "Microbiome testing",
    "MEDITATION": "Mindfulness and meditation techniques",
    "NATURAL_SKINCARE": "Natural skincare",
    "NUTRITION": "Personalized nutrition / food-as-medicine approach",
    "PLANT_BASED": "Plant-based diet",
    "SIBO_TESTING": "SIBO testing"
};

export const commstylesMap: Record<string, string> = {
    "DIRECT": "Direct",
    "ANALYTICAL": "Analytical",
    "SUPPORTIVE": "Supportive",
    "INITIATING": "Initiating"
};

export const altmedMap: Record<string, string> = {
    "YES": "Prefers alternative medicine",
    "SOMEWHAT": "Open to alternative medicine",
    "NO": "No alternative medicine"
};

export const genderMap: Record<string, string> = {
    "FEMALE": "Female",
    "MALE": "Male",
    "BOTH": "Both male and female"
};

export const altmedProviderMap: Record<string, string> = {
    "YES": "Very open",
    "SOMEWHAT": "Somewhat open",
    "NO": "Not for me"
};

export const stateProvinceMap: Record<string, string> = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming",
    "DC": "District of Columbia",
    "AS": "American Samoa",
    "GU": "Guam",
    "MP": "Northern Mariana Islands",
    "PR": "Puerto Rico",
    "UM": "United States Minor Outlying Islands",
    "VI": "U.S. Virgin Islands",
    "AB": "Alberta",
    "BC": "British Columbia",
    "MB": "Manitoba Manitoba",
    "NB": "New Brunswick",
    "NL": "Newfoundland and Labrador",
    "NS": "Nova Scotia",
    "ON": "Ontario",
    "PE": "Prince Edward Island",
    "QC": "Quebec",
    "SK": "Saskatchewan",
    "NT": "Northwest Territories",
    "NU": "Nunavut",
    "YT": "Yukon"
};

export const locationsAllowedMap: Record<string, string> = {
    "ALL_STATES_COUNTRIES": "Yes! I can practice in any state or country",
    "SOME_STATES": "I can practice in multiple states within my country, but not across country lines",
    "ONLY_STATE": "No, I can only practice within my state",
    "ALL_STATES": "I can practice in all states within my country, but not across country lines"
};

export const symptomsMap: Record<string, string> = {
    "ACNE": "Acne",
    "ANXIETY": "Anxiety",
    "BRAIN_FOG": "Brain fog",
    "DEPRESSION": "Depression",
    "DIGESTIVE_ISSUES": "Digestive issues",
    "FATIGUE": "Fatigue",
    "HAIR_LOSS": "Hair loss",
    "INCREASED_HAIR_GROWTH": "Increased hair growth",
    "INSOMNIA": "Insomnia",
    "IRREGULAR_PERIODS": "Irregular periods",
    "NIGHT_SWEATS": "Night sweats",
    "SKIN_DISCOLORATION": "Skin discoloration",
    "WEIGHT_GAIN": "Weight gain"
};

export const moodMap: Record<string, string> = {
    1: "Not well",
    2: "Fair",
    3: "Good"
};

export const subNavMap: Record<string, string> = {
    appointments: "Appointments",
    practiceInfo: "Practice info", 
};

export const navHeadingMap: Record<string, string> = {
    "/providers/personalInfo": ""
};

export const symptomTagMap: Record<number, string> = {
    0: "Acne",
    1: "Anxiety",
    2: "Depression",
    3: "Fatigue",
    4: "Infertility",
    5: "Irregular periods",
    6: "Heavy or painful periods",
    7: "Hirsutism",
    8: "Weight gain",
    9: "Absent or irregular ovulation",
    10: "Brain fog",
    11: "Digestive issues",
    12: "Hair loss",
    13: "Insomnia",
    14: "Night sweats",
    15: "Skin discoloration"
};

export const symptomTagsMap: Record<number, string> = {
    0: "Acne",
    1: "Anxiety",
    2: "Depression",
    3: "Fatigue",
    4: "Infertility",
    5: "Irregular periods",
    6: "Heavy or painful periods",
    7: "Hirsutism (increased hair growth)",
    8: "Weight gain",
    9: "Absent or irregular ovulation",
    10: "Brain fog",
    11: "Digestive issues",
    12: "Hair loss",
    13: "Insomnia",
    14: "Night sweats",
    15: "Skin discoloration",
    16: "Other"
};

export const nutritionTagMap: Record<number, string> = {
    0: "Blood sugar-balancing diet",
    1: "Gluten free",
    2: "Dairy free",
    3: "More healthy fats",
    4: "High-protein breakfast",
    5: "Less processed foods",
    6: "Regularly-timed meals and snacks",
};
export const exerciseTagMap: Record<number, string> = {
    0: "30 minutes of movement per day",
    1: "Gentle exercise",
    2: "HIIT",
    3: "Focus on recovery"
};
export const stressTagMap: Record<number, string> = {
    0: "Meditation",
    1: "Deep breathing",
    2: "8 hours sleep",
    3: "Sunlight upon waking",
    4: "Limit screen time before bed",
    5: "Blue light glasses",
};