import React from "react";
import {Link, withRouter} from "react-router-dom";
import Login from "./Login";
import "../../App.css";

const ProviderLogin = () => {

    return (
        <div className="container auth">
            <div className="row justify-content-lg-center">
                <div className="col-lg-8">
                    <h1>Provider Log In</h1>
                    <p className="auth-subtitle">
                        Log in to your account.
                    </p>
                    <Login/>
                    <p className="center-text auth-link-spacing">
                        <Link to={process.env.PUBLIC_URL + "/providers/signup"} className="auth-link">
                            Don’t have an account? Join Pollie now
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ProviderLogin);