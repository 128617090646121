import React, {Fragment} from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import { IAppointment, IMemberInfo, IProvider } from "../../../types/interfaces";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import _ from "lodash";
import { displayMembersName } from "../Members/Utils";

dayjs.extend(timezone);
dayjs.extend(utc);

interface IAppointmentSummaryProps extends RouteComponentProps {
    appointment: IAppointment,
    provider: IProvider,
    member: IMemberInfo
}

const AppointmentSummary = ({ appointment, provider, member}: IAppointmentSummaryProps) => {
    const currentTime = dayjs().tz(provider.time_zone);
    const startTime = dayjs(appointment.start_time).tz(provider.time_zone);
    const endTime = dayjs(appointment.end_time).tz(provider.time_zone);
    const timeTillAppointment = startTime.diff(currentTime, "minutes") + 1;
    const isAppointmentStarting = timeTillAppointment <= 15 && timeTillAppointment > 0;
    const isAppointmentStarted = timeTillAppointment <= 0;
    const isAppointmentToday = startTime.isSame(currentTime, "day");

    // if (!startTime.isSame(currentTime, "day") || endTime.isBefore(currentTime, "minutes")) {
    //     return;
    // }
    // const member = members.find((member) => {
    //     return _.isEqual(member.id, appointment.member_id);
    // });
    // if (_.isNil(member)) {
    //     return;
    // }
    let memberName = displayMembersName(member);
    if (memberName.length > 20) {
        memberName = `${memberName.slice(0, 20)}...`;
    }
    return (
        <div className="dashboard-module-row row p-2 mb-2">
            <div className="col-md-9">
                <div className="row">
                    {`${memberName} / ${appointment.type}`}
                </div>
                <div className="row appointment-approaching">
                    <div className="pr-1">
                        {!isAppointmentToday && dayjs(appointment.start_time).tz(provider.time_zone).format("MMMM D, YYYY [at] h:mm A z")}
                        {isAppointmentToday && dayjs(appointment.start_time).tz(provider.time_zone).format("h:mm A z")}
                    </div>
                    { isAppointmentToday && 
                        <Fragment>
                            { (isAppointmentStarting && !isAppointmentStarted) &&
                                <div className="appointment-soon">
                                    {`Starts in ${timeTillAppointment} minutes`}
                                </div>
                            }
                            { isAppointmentStarted &&
                                <div className="appointment-soon">
                                    {"Happening now"}
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            </div>
            { isAppointmentToday &&
                <Fragment>
                    { (isAppointmentStarting || isAppointmentStarted) &&
                        <Link className="col-md-3 dashboard-btn-dark p-1" target="_blank"
                            to={process.env.PUBLIC_URL + `/providers/appointments/${appointment.id}`}>
                            Join now
                        </Link>
                    }
                    { !(isAppointmentStarting || isAppointmentStarted) &&
                        <div className="col-md-3 dashboard-btn-light p-1">
                            Join now
                        </div>
                    }
                </Fragment>
            }
        </div>
    );
};

export default withRouter(AppointmentSummary);
