import React from "react";
import {Link, withRouter} from "react-router-dom";

import SignUp from "./SignUp";

const ProviderSignUp = () => {
    return (
        <div className="container auth">
            <div className="row justify-content-lg-center">
                <div className="col-lg-8">
                    <h1>Welcome to Pollie!</h1>
                    <p className="auth-subtitle">Create a password to complete your account.</p>
                    <SignUp/>
                    <p className="center-text auth-link-spacing">
                        <Link to={process.env.PUBLIC_URL + "/providers/login"} className="auth-link" href="">
                            Already have an account? Log in
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ProviderSignUp);