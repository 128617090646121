import React from "react";
import _ from "lodash";
import {FormEvent, useState} from "react";
import {withRouter} from "react-router-dom";
import { providerAuth0Client } from "./Auth";

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [response, setResponse] = useState("");

    const validateForm = () => {
        return email.length > 0;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError("");
        setResponse("");

        if(!validateForm()) {
            setError("Please enter an email.");
            return;
        }
        try {
            const response = await providerAuth0Client.resetPassword(email);
            setResponse(response);
        } catch(err: any) {
            setError(err.description);    
        }
    };

    return (
        <div className="container auth">
            <div className="row justify-content-lg-center">
                <div className="col-lg-8">
                    <h1>
                        Reset your password
                    </h1>
                    <p className="auth-subtitle">
                        Please enter the email of your account and you will receive an email to reset your password.
                    </p>
                    <div className="container">
                        <div className="row">
                            { !_.isNil(error) && 
                                <p className="form-error">{error}</p>
                            }
                            { !_.isNil(response) && 
                                <p>{response}</p>
                            }
                            <form className="w-100" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>
                                        Email address
                                    </label>
                                    <input type="email" 
                                        className="form-control" 
                                        id="email" 
                                        aria-describedby="emailHelp" 
                                        placeholder="Enter email" 
                                        value={email}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                                <button className="form-submit-button btn-pollie-dark" type="submit">
                                    Reset password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ResetPassword);