import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./style.css";
import _ from "lodash";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import Checkmark from "../../Utils/Checkmark";
import { colorCodedLabs, ILab, INewLab, LabValues } from "./Constants";

interface ILabDropdownProps extends RouteComponentProps {
    updateLabResults: any;
    labResult: ILab | INewLab;
    isNew?: boolean;
}

const LabDropdown = ({updateLabResults, labResult, isNew = false}: ILabDropdownProps) => {
    const [selectedValue, setSelectedValue] = useState(labResult.value);

    useEffect(() => {
        setSelectedValue(labResult.value);
    }, [labResult]);

    return (

        <Dropdown as={ButtonGroup} className="w-100">
            <Dropdown.Toggle className="lab-dropdown-button pl-0 pr-0 mr-0">
                <div className={colorCodedLabs(LabValues[selectedValue])}>
                    { !_.isEqual(selectedValue, -1) ? LabValues[selectedValue] : "Select one"}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true}>
                { Object.entries(LabValues).map(([key, value]) => {
                    const keyInt = parseInt(key);
                    return (
                        <Dropdown.Item key={keyInt} eventKey={keyInt}
                            active={_.isEqual(keyInt, selectedValue)}
                            onClick={() => {
                                setSelectedValue(keyInt);
                                updateLabResults(isNew ? (labResult as INewLab).id: labResult.name, keyInt, );
                            }}>
                            { _.isEqual(keyInt, selectedValue) &&
                                <Checkmark />
                            }
                            {value}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(LabDropdown);
