import React, { Dispatch, Fragment, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import { RouteComponentProps } from "react-router";
import { IMemberInfo, IAppointment } from "../../../../../types/interfaces";
import { Link, withRouter } from "react-router-dom";
import { longAppointmentScheduleFormat } from "./constants";
import _ from "lodash";
import { displayMembersName } from "../../../Members/Utils";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

interface IAppointmentViewModalProps extends RouteComponentProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    appointment: IAppointment;
    member: IMemberInfo;
    setAppointmentCancellation: Dispatch<SetStateAction<IAppointment>>;
    setAppointmentModalShow: Dispatch<SetStateAction<boolean>>;
    isVisitCancelable: boolean;
}

function AppointmentViewModal({ show, setShow, appointment, member, 
    setAppointmentCancellation, setAppointmentModalShow, isVisitCancelable }: IAppointmentViewModalProps) {

    const onHide = () => {
        setShow(false);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="appointment-view-modal"
            centered>
            <Modal.Header closeButton className="modal-header"/>
            <Modal.Body>
                <div className="bold">
                    <Link to={process.env.PUBLIC_URL + `/providers/members/${member.id}`}
                        className="pollie-link">
                        {displayMembersName(member)}
                    </Link>
                    <Fragment>
                        {` - ${appointment.type}`}
                    </Fragment>
                </div>
                <div className="calendar-date">
                    {longAppointmentScheduleFormat(appointment.start_time, appointment.end_time)}
                </div>
                { isVisitCancelable &&
                    <p className="pollie-link cursor-pointer mt-3" 
                        onClick={() => {
                            setAppointmentCancellation(appointment);
                            onHide();
                            setAppointmentModalShow(true);
                        }}>
                        Cancel visit
                    </p>
                }
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(AppointmentViewModal);