import "./appointments.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { fullDateFormat, shownTimeFormat } from "./constants";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { IAppointment, IMemberAppointment, IMemberInfo, INote, IProvider } from "../../../types/interfaces";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { defaultAppointment, defaultNote } from "../../utils/constants";
import NoteModal from "../MemberProfiles/Notes/NoteModal";
import { http } from "../../utils/axios-config";

dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface INoteProps extends RouteComponentProps {
    note: INote;
    isProvidersAppointment: boolean;
    member: IMemberInfo;
    updateNotes: any;
    appointments: IAppointment[];
    providers: IProvider[];
}

const Note = ({ note, isProvidersAppointment, member, updateNotes, appointments, providers }: INoteProps) => {
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [appointment, setAppointment] = useState<IAppointment>(defaultAppointment);
    const [providerName, setProviderName] = useState<string>("");
    const [currentNote, setCurrentNote] = useState<INote>(note);

    const appointmentStartDateTime = dayjs.utc(appointment.start_time).local();

    const appointmentDate = appointmentStartDateTime.format(fullDateFormat);
    const appointmentStartTime = appointmentStartDateTime.format(shownTimeFormat);

    const noteCreatedDateTime = dayjs.utc(note.date_created).local();
    const noteDate = noteCreatedDateTime.format(fullDateFormat);
    const noteCreatedTime = noteCreatedDateTime.format(shownTimeFormat);

    useEffect(() => {
        const getNoteInfo = async () => {
            const tempAppointment = appointments.find((appointment) => {
                return _.isEqual(appointment.id, currentNote.appointment_id);
            });
            if (!_.isNil(tempAppointment)) {
                setAppointment(tempAppointment);
            }
            const providerInfo: IProvider = await http("get", `/providers/${note.provider_id}/personal-info`, "");
            setProviderName(`${providerInfo.first_name} ${providerInfo.last_name}`);
        };
        void getNoteInfo();
    }, []);

    return (
        <div className="note-card row">
            <div className="col-9">
                <div className="note-text bold"> 
                    { !_.isEmpty(note.appointment_id) &&
                        (`${appointment.type} note` || "Visit")
                    }
                    { _.isEmpty(note.appointment_id) &&
                        "Progress notes"
                    }
                </div>
                <div className="note-text">
                    { !_.isEmpty(note.appointment_id) &&
                        `${appointmentDate} at ${appointmentStartTime}`
                    }
                    { _.isEmpty(note.appointment_id) &&
                        `${noteDate} at ${noteCreatedTime}`
                    }
                </div>
                <div className="note-text"> 
                    {`Provider: ${providerName}`}
                </div>
            </div>
            <div className="col-3 pr-0">
                <div className="view-note-button" onClick={() => {
                    setNoteModalShow(true);
                }}>
                    View notes
                </div>
            </div>

            <NoteModal show={noteModalShow} 
                setShow={setNoteModalShow}
                note={currentNote}
                setNote={setCurrentNote}
                appointment={appointment}
                member={member}
                updateNotes={updateNotes}
                isProvidersAppointment={isProvidersAppointment}
                providers={providers}/>
        </div>
    );
};

export default withRouter(Note);