import "./appointments.css";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { fullDateFormat, shownTimeFormat } from "./constants";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { IAppointment, IMemberAppointment, IMemberInfo, INote, IProvider } from "../../../types/interfaces";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { defaultNote } from "../../utils/constants";
import NoteModal from "../MemberProfiles/Notes/NoteModal";

dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

interface IAppointmentProps extends RouteComponentProps {
    appointment: IMemberAppointment;
    setAppointmentModalShow: Dispatch<SetStateAction<boolean>>;
    setAppointmentCancellation: Dispatch<SetStateAction<IAppointment>>;
    notes: INote[];
    isProvidersAppointment: boolean;
    member: IMemberInfo;
    updateNotes: any;
    providers: IProvider[];
}

const Main = ({ appointment, setAppointmentModalShow, setAppointmentCancellation, 
    notes, isProvidersAppointment, member, updateNotes, providers}: IAppointmentProps) => {
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [note, setNote] = useState(defaultNote);

    const isAppointmentNow = dayjs.utc(appointment.start_time).isSameOrBefore(dayjs.utc());
    const providerName = `${appointment.first_name} ${appointment.last_name}`;

    const appointmentStartDateTime = dayjs.utc(appointment.start_time).local();
    // const appointmentEndDateTime = dayjs.utc(appointment.end_time).local();

    const appointmentDate = appointmentStartDateTime.format(fullDateFormat);
    const appointmentStartTime = appointmentStartDateTime.format(shownTimeFormat);

    // const appointmentStartCalendar: DateArray = [appointmentStartDateTime.year(), appointmentStartDateTime.month()+1,
    //     appointmentStartDateTime.date(), appointmentStartDateTime.hour(),
    //     appointmentStartDateTime.minute()];
    // const appointmentEndCalendar: DateArray = [appointmentEndDateTime.year(), appointmentEndDateTime.month()+1,
    //     appointmentEndDateTime.date(), appointmentEndDateTime.hour(),
    //     appointmentEndDateTime.minute()];

    // const downloadCalendar = () => {
    //     // const start: DateArray = [appointmentStartDateTime, 4, 7, 12, 30];
    //     const event = {
    //         start: appointmentStartCalendar,
    //         end: appointmentEndCalendar,
    //         // title: `Pollie ${appointment.service }`,
    //         // description: `Pollie ${appointment.service } with ${appointment.member_name }`,
    //         productId: "pollie",
    //         location: ""
    //     };

    //     const icsFile = createEvent(event);
    //     if (_.isNil(icsFile.error)) {
    //         const blob = new Blob([icsFile.value as BlobPart], {type: "text/calendar;charset=utf-8"});

    //         // IE
    //         if (isIE()) {
    //             window.navigator.msSaveOrOpenBlob(blob, "appointmentEvent.ics");
    //             return;
    //         }

    //         // Safari
    //         if (isIOSSafari()) {
    //             const url: string = buildUrl(event, appointmentStartDateTime.format(), appointmentEndDateTime.format());

    //             window.open(url, "_blank");
    //             return;
    //         }

    //         // Desktop
    //         saveBlob(blob, "appointmentEvent.ics");
    //     } else {
    //         console.log(`Error downloading ics file: ${icsFile.error.message}`);
    //     }
    // };

    useEffect(() => {
        let tempNote = notes.find((note) => {
            return _.isEqual(appointment.id, note.appointment_id);
        });
        if (_.isNil(tempNote)) {
            tempNote = {
                ...defaultNote, appointment_id: appointment.id
            };
        }
        setNote(tempNote);
    }, []);


    return (
        <div className="appointment-card">
            <div className="appointment-text bold"> 
                {appointment.type || "Visit"}
            </div>
            <div className="appointment-text">
                {`${appointmentDate} at ${appointmentStartTime}`}
            </div>
            <div className="appointment-text"> 
                {`Provider: ${providerName}`}
            </div>
            { (!isAppointmentNow && isProvidersAppointment) &&
                <div className="cancel-appointment-link" 
                    onClick={() => {
                        setAppointmentModalShow(true);
                        setAppointmentCancellation(appointment);
                    }}>
                    Cancel visit
                </div>
            }
            { isAppointmentNow &&
                <Fragment>
                    { (isProvidersAppointment && _.isEmpty(note.id)) &&
                        <div className="btn btn-pollie-dark" onClick={() => {
                            setNoteModalShow(true);
                        }}>
                            Add notes
                        </div>
                    }
                    { !_.isEmpty(note.id) &&
                        <div className="note-link" onClick={() => {
                            setNoteModalShow(true);
                        }}>
                            View notes
                        </div>
                    }
                </Fragment>
            }
            <NoteModal show={noteModalShow} 
                setShow={setNoteModalShow}
                note={note}
                setNote={setNote}
                appointment={appointment}
                member={member}
                updateNotes={updateNotes}
                isProvidersAppointment={isProvidersAppointment}
                providers={providers}/>
        </div>
    );
};

export default withRouter(Main);