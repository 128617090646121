import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import dayjs, {Dayjs} from "dayjs";
import {dateFormat, timeFormat, modalTimeFormat, handleSpanChange} from "./constants";
import _ from "lodash";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import {ISchedule} from "../../../types/interfaces";
import {v4 as uuidv4} from "uuid";
import {convertSchedulesToEvents} from "./Calendar";
import TimeSelector from "../Utils/TimeSelector";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

function AddScheduleModal(props: any) {
	const {
		show, setAddScheduleModalShow, newEvent, setNewEvent, setIsLoading, setCalendarEvents,
		createSchedule, schedules, setSchedules, scheduleExceptions
	} = props;
	const [startValidationError, setStartValidationError] = useState("");
	const [endValidationError, setEndValidationError] = useState("");
	const [overlapValidationError, setOverlapValidationError] = useState("");

	const onHide = () => {
		setAddScheduleModalShow(false);
		setStartValidationError("");
		setEndValidationError("");
		setOverlapValidationError("");
	};

	const addToCalendar = async (addedEvent: any) => {
		// TODO: Need users location to get timezone, then we can use that to convert to utc
		const startDateTime: Dayjs = dayjs(addedEvent.startDateTimeLocal).utc();
		const endDateTime: Dayjs = dayjs(addedEvent.endDateTimeLocal).utc();
		const newSchedule: ISchedule = {
			id: uuidv4(),
			provider_id: addedEvent.providerId,
			start_date: startDateTime.format(dateFormat),
			end_date: endDateTime.format(dateFormat),
			start_time: startDateTime.format(timeFormat),
			end_time: endDateTime.format(timeFormat),
			is_recurring: false,
			day_of_week: -1
		};
		try {
			await createSchedule(newSchedule);
			setSchedules([...schedules, newSchedule]);
			setCalendarEvents(convertSchedulesToEvents([...schedules, newSchedule], scheduleExceptions));
		} catch (error) {
			console.log(error);
		}

	};

	const addRecurringToCalendar = async (addedEvent: any) => {
		// TODO: Need users location to get timezone, then we can use that to convert to utc
		const startDateTime: Dayjs = dayjs(addedEvent.startDateTimeLocal).utc();
		const endDateTime: Dayjs = dayjs(addedEvent.endDateTimeLocal).utc();
		const newSchedule: ISchedule = {
			id: uuidv4(),
			provider_id: addedEvent.providerId,
			start_date: undefined,
			end_date: undefined,
			start_time: startDateTime.format(timeFormat),
			end_time: endDateTime.format(timeFormat),
			is_recurring: true,
			day_of_week: parseInt(startDateTime.format("d"))
		};
		try {
			await createSchedule(newSchedule);
			setSchedules([...schedules, newSchedule]);
			setCalendarEvents(convertSchedulesToEvents([...schedules, newSchedule], scheduleExceptions));
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<h2>Add Availability</h2>
				<div className="row mb-4">
					<div className="col">
						<label>From</label>
						<TimeSelector
							className="col form-control pollie-input placeholder-opacity"
							spanKey={"start"}
							defaultValue={newEvent.start}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSpanChange(e, "start",
									newEvent,
									setNewEvent,
									setStartValidationError,
									setEndValidationError,
									setOverlapValidationError);
							}}/>
					</div>
					<div className="col">
						<label>To</label>
						<TimeSelector className="col form-control pollie-input placeholder-opacity"
						              spanKey={"end"}
						              defaultValue={newEvent.end}
						              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							              handleSpanChange(e, "end",
								              newEvent,
								              setNewEvent,
								              setStartValidationError,
								              setEndValidationError,
								              setOverlapValidationError);
						              }}/>
					</div>
				</div>
				{startValidationError !== "" &&
          <p className="form-error">{startValidationError}</p>
				}
				{endValidationError !== "" &&
          <p className="form-error">{endValidationError}</p>
				}
				{overlapValidationError !== "" &&
          <p className="form-error">{overlapValidationError}</p>
				}
				<Button
					disabled={!_.isEqual(startValidationError, "") ||
						!_.isEqual(endValidationError, "") ||
						!_.isEqual(overlapValidationError, "")}
					onClick={async () => {
						onHide();
						setIsLoading(true);

						const startTimeLocal = dayjs(newEvent.start, modalTimeFormat);
						const startDateTimeLocal = newEvent.startDateLocal.set("hour", startTimeLocal.hour())
							.set("minute", startTimeLocal.minute());
						const endTimeLocal = dayjs(newEvent.end, modalTimeFormat);
						const endDateTimeLocal = newEvent.endDateLocal.set("hour", endTimeLocal.hour())
							.set("minute", endTimeLocal.minute());

						await addToCalendar({
							...newEvent,
							startDateTimeLocal: startDateTimeLocal,
							endDateTimeLocal: endDateTimeLocal
						});
						setIsLoading(false);
					}} className="form-submit-button btn-pollie-dark">Add availability
					to {newEvent.startDateLocal.format("MMMM D, YYYY")}
				</Button>
				<Button
					disabled={!_.isEqual(startValidationError, "") ||
						!_.isEqual(endValidationError, "") ||
						!_.isEqual(overlapValidationError, "")}
					onClick={async () => {
						onHide();
						setIsLoading(true);

						const startTimeLocal = dayjs(newEvent.start, modalTimeFormat);
						const startDateTimeLocal = newEvent.startDateLocal.set("hour", startTimeLocal.hour())
							.set("minute", startTimeLocal.minute());
						const endTimeLocal = dayjs(newEvent.end, modalTimeFormat);
						const endDateTimeLocal = newEvent.endDateLocal.set("hour", endTimeLocal.hour())
							.set("minute", endTimeLocal.minute());

						await addRecurringToCalendar({
							...newEvent,
							startDateTimeLocal: startDateTimeLocal,
							endDateTimeLocal: endDateTimeLocal
						});
						setIsLoading(false);
					}} className="form-submit-button btn-pollie-dark">Add availability to
					all {newEvent.startDateLocal.format("dddd")}s
				</Button>
				<p className="center-text auth-link-spacing pollie-link"
				   onClick={() => {
					   onHide();
					   setStartValidationError("");
					   setEndValidationError("");
					   setOverlapValidationError("");
				   }}>
					Cancel
				</p>
			</Modal.Body>
		</Modal>
	);
}

export default AddScheduleModal;