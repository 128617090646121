import React, { ReactElement } from "react";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

interface ILoadingProps {
  style?: any
}

const Loading = ({style}: ILoadingProps): ReactElement => {

    return (
        <div className="loader" style={style}>
            <BeatLoader
                css={override}
                color={"#2B97AB"}
            />
        </div>
    );
};

export default Loading;